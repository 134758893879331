import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { I18n } from 'react-redux-i18n';

/**
 * component to render message model
 */
class MessageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      message: '',
      title: '',
      showOkButton: false,
      showCancelButton: this.props.showCancelButton
        ? this.props.showCancelButton
        : false,
      handleOk: null,
      handleCancel: null,
      okText: 'OK',
      cancelText: 'Cancel',
      showNote: false,
      note: '',
      maxWidth: 'md',
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={this.state.maxWidth}
        open={this.state.showModal}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          {this.state.title}
        </DialogTitle>
        <DialogContent className='message-modal-content-container'>
          <div>{this.state.message}</div>
          {this.state.showNote && (
            <div className='message-modal-note'>
              <TextField
                variant='standard'
                fullWidth
                size='small'
                label={I18n.t('global.message_modal_note_field_label')}
                onChange={(event) =>
                  this.setState({ note: event.target.value })
                }
                multiline
                rows={2}
                maxRows={5}
                name='name'
                value={this.state.note || ''}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions className='message-modal-actions-container'>
          {this.state.showCancelButton ? (
            <Button
              onClick={() => {
                this.setState({ showModal: false }, () => {
                  if (this.state.handleCancel !== null)
                    this.state.handleCancel();
                });
              }}
              size='small'
              color='inherit'
              variant='textt'
            >
              {this.state.cancelText}
            </Button>
          ) : (
            <></>
          )}
          <Button
            onClick={() => {
              this.setState({ showModal: false }, () => {
                if (this.state.handleOk !== null)
                  this.state.handleOk(this.state.note);
              });
            }}
            size='small'
            variant='contained'
            color='secondary'
            autoFocus
          >
            {this.state.okText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default MessageModal;
