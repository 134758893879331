import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Popper,
  Paper,
  Fade,
  Slider,
  ClickAwayListener,
  Box,
  Icon,
} from '@mui/material';
import { useStyles } from './styles';

function valuetext(value, maxVal) {
  return `${value === maxVal ? 'Any' : value}`;
}
const NumberRangeFilter = React.memo(
  ({
    filterName,
    title,
    saveButtonText,
    clearButtonText,
    onChangeFilter,
    value,
    maxValue,
  }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [rangeValue, setRangeValue] = useState([2, 20]);

    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    useEffect(() => {
      if (value) {
        setRangeValue(value);
      }
    }, [value]);

    const handleChange = (event, newValue) => {
      setRangeValue(newValue);
    };

    const onApplyFilter = () => {
      onChangeFilter(rangeValue);
      setAnchorEl(null);
      setOpen(false);
    };

    const onClearFilter = (event) => {
      if (event) {
        event.stopPropagation();
      } else {
      }
      onChangeFilter(null);
      setRangeValue([2, 10]);
      setAnchorEl(null);
      setOpen(false);
    };

    const clickAwayHandler = () => {
      const rangeVal = value ? value : [2, 15];
      setRangeValue(rangeVal);
      setAnchorEl(null);
      setOpen(false);
    };

    return (
      <>
        {open && (
          <ClickAwayListener onClickAway={() => clickAwayHandler()}>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement='bottom-start'
              transition
              className={classes.popperContainer}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Paper classes={{ root: classes.paperRoot }}>
                    <Typography variant='h6'>{title}</Typography>
                    <Box mt={6}>
                      <Slider
                        color='secondary'
                        value={rangeValue}
                        onChange={handleChange}
                        valueLabelDisplay='on'
                        getAriaValueText={valuetext}
                        valueLabelFormat={(value) =>
                          valuetext(value, maxValue < 100 ? 100 : maxValue)
                        }
                        max={maxValue < 100 ? 100 : maxValue}
                      />
                    </Box>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        classes={{ item: classes.actionsGrid }}
                      >
                        <Button
                          variant='text'
                          color='inherit'
                          onClick={() => onClearFilter()}
                        >
                          {clearButtonText}
                        </Button>

                        <Button
                          classes={{ root: classes.applyButton }}
                          variant='contained'
                          color='secondary'
                          onClick={() => onApplyFilter()}
                        >
                          {saveButtonText}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}
        <Button
          size='small'
          variant={value ? 'contained' : 'outlined'}
          color={value ? 'secondary' : 'inherit'}
          onClick={handleClickMenu}
        >
          {`${filterName} ${
            value ? '(' + value[0] + '-' + value[1] + ')' : ''
          }`}
          {value && (
            <Icon onClick={(e) => onClearFilter(e)}>close_rounded</Icon>
          )}
        </Button>
      </>
    );
  }
);

export default NumberRangeFilter;
