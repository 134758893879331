import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Popper,
  Paper,
  Fade,
  ClickAwayListener,
  Box,
  Icon,
} from '@mui/material';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';
import { useStyles } from './styles';

const DateRangeFilter = React.memo(
  ({
    filterName,
    title,
    saveButtonText,
    clearButtonText,
    onChangeFilter,
    value,
  }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [rangeValue, setRangeValue] = useState({});

    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    useEffect(() => {
      if (value) {
        setRangeValue(value);
      }
    }, [value]);

    const onApplyFilter = () => {
      onChangeFilter(rangeValue);
      setAnchorEl(null);
      setOpen(false);
    };

    const onClearFilter = (event) => {
      if (event) {
        event.stopPropagation();
      } else {
      }
      onChangeFilter(null);
      setRangeValue({});
      setAnchorEl(null);
      setOpen(false);
    };

    const clickAwayHandler = () => {
      const rangeVal = value ? value : {};
      setRangeValue(rangeVal);
      setAnchorEl(null);
      setOpen(false);
    };

    return (
      <>
        {open && (
          <ClickAwayListener onClickAway={() => clickAwayHandler()}>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement='bottom-start'
              transition
              className={classes.popperContainer}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Paper classes={{ root: classes.paperRoot }}>
                    <Typography variant='h6'>{title}</Typography>
                    <Box mt={2}>
                      <DateRangePicker
                        open={open}
                        toggle={() => setOpen(!open)}
                        onChange={(range) => setRangeValue(range)}
                        initialDateRange={rangeValue}
                        wrapperClassName=''
                      />
                    </Box>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        classes={{ item: classes.actionsGrid }}
                      >
                        <Button
                          variant='text'
                          color='inherit'
                          onClick={() => onClearFilter()}
                        >
                          {clearButtonText}
                        </Button>
                        <Button
                          classes={{ root: classes.applyButton }}
                          variant='contained'
                          color='secondary'
                          onClick={() => onApplyFilter()}
                        >
                          {saveButtonText}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}
        <Button
          size='small'
          variant={value ? 'contained' : 'outlined'}
          color={value ? 'secondary' : 'inherit'}
          onClick={handleClickMenu}
        >
          {`${filterName} ${
            value
              ? '(' +
                moment(value['startDate']).format('DD MMM YYYY') +
                ' - ' +
                moment(value['endDate']).format('DD MMM YYYY') +
                ')'
              : ''
          }`}
          {value && (
            <Icon onClick={(e) => onClearFilter(e)}>close_rounded</Icon>
          )}
        </Button>
      </>
    );
  }
);

export default DateRangeFilter;
