import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Icon,
  Tooltip,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useStyles } from './styles';
import ShowMore from 'react-show-more-button';
import ModalImage from 'react-modal-image';
import { I18n } from 'react-redux-i18n';

/**
 * fnction to render spirit card
 * @param {*} spirit: spirit
 * @param {*} handleCardEditButtonClick: function to handle edit click
 * @param {*} onActivateDeactivate: function to handle activate or deactivate
 * @returns
 */
const SpiritCard = ({
  spirit,
  handleCardEditButtonClick,
  onActivateDeactivate,
}) => {
  const classes = useStyles();

  return (
    <Paper component={Box} p={2} mt={2}>
      <Box>
        <Grid container justifyContent='space-between'>
          <Grid
            item
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box display='inline-block'>
              <Typography classes={{ root: classes.titleText }}>
                {spirit?.name}
              </Typography>
            </Box>

            <Box display='inline-block'>
              <Typography>
                {I18n.t('spirit.spirit_card.cost_label')}: {spirit?.cost}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{ textAlign: 'right' }}
          >
            <Tooltip
              title={I18n.t('spirit.spirit_card.edit_tooltip')}
              arrow
              placement='top'
            >
              <Icon
                classes={{ root: classes.actionIcon }}
                onClick={() => handleCardEditButtonClick(spirit.id)}
              >
                edit
              </Icon>
            </Tooltip>
            <Tooltip
              title={
                spirit.enabled
                  ? I18n.t('spirit.spirit_card.disable_tooltip')
                  : I18n.t('spirit.spirit_card.enable_tooltip')
              }
              arrow
              placement='top'
            >
              <Icon
                classes={{ root: classes.actionIcon }}
                onClick={() =>
                  onActivateDeactivate(spirit.id, spirit.enabled, spirit.name)
                }
              >
                {spirit.enabled ? 'lock_open_rounded' : 'lock_rounded'}
              </Icon>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <ModalImage
              showRotate={false}
              hideDownload
              className={classes.modalImage}
              small={spirit?.mediaUrl}
              medium={spirit?.mediaUrl}
              large={spirit?.mediaUrl}
              alt=''
            />
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <ShowMore
              maxHeight={150}
              backgroundColor='trasparent'
              classNameButton={classes.showMoreLessButton}
              classNameButtonDiv={classes.showMoreLessButtonContainer}
            >
              <Box>
                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{ root: classes.recommendedTextLabel }}
                    >
                      {I18n.t('spirit.spirit_card.recommended_weather_label')}:
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl
                      component='fieldset'
                      variant='standard'
                      disabled
                    >
                      <FormGroup row>
                        {Object.entries(spirit?.recommendedWeather).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              classes={{
                                disabled: classes.checkboxRadioLabelDisabled,
                              }}
                              control={
                                <Checkbox
                                  color='secondary'
                                  checked={value}
                                  classes={{
                                    disabled: classes.checkboxRadioDisabled,
                                  }}
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{ root: classes.recommendedTextLabel }}
                    >
                      {I18n.t('spirit.spirit_card.recommended_timing_label')}:
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl
                      component='fieldset'
                      variant='standard'
                      disabled
                    >
                      <FormGroup row>
                        {Object.entries(spirit?.recommendedTiming).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              classes={{
                                disabled: classes.checkboxRadioLabelDisabled,
                              }}
                              control={
                                <Checkbox
                                  color='secondary'
                                  checked={value}
                                  classes={{
                                    disabled: classes.checkboxRadioDisabled,
                                  }}
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{ root: classes.recommendedTextLabel }}
                    >
                      {I18n.t('spirit.spirit_card.recommended_cuisines_label')}:
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl
                      component='fieldset'
                      variant='standard'
                      disabled
                    >
                      <FormGroup row>
                        {Object.entries(spirit?.recommendedCuisines).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              classes={{
                                disabled: classes.checkboxRadioLabelDisabled,
                              }}
                              control={
                                <Checkbox
                                  color='secondary'
                                  checked={value}
                                  classes={{
                                    disabled: classes.checkboxRadioDisabled,
                                  }}
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{ root: classes.recommendedTextLabel }}
                    >
                      {I18n.t('spirit.spirit_card.recommended_mood_label')}:
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl
                      component='fieldset'
                      variant='standard'
                      disabled
                    >
                      <FormGroup row>
                        {Object.entries(spirit?.recommendedForMood).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              classes={{
                                disabled: classes.checkboxRadioLabelDisabled,
                              }}
                              control={
                                <Checkbox
                                  color='secondary'
                                  checked={value}
                                  classes={{
                                    disabled: classes.checkboxRadioDisabled,
                                  }}
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </ShowMore>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SpiritCard;
