import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';

import showLoader from './utils/components/AppLoader/AppLoaderReducer';
import auth from './modules/Authentication/AuthenticationReducer';

export default combineReducers({
  i18n: i18nReducer,
  showLoader,
  auth,
});
