import React from 'react';
import { Tooltip, Pagination, PaginationItem, Icon } from '@mui/material';
import TextTooltip from '../FileName/FileName';
import './Thumbnails.css';

const Thumbnails = React.memo(
  ({ mediaUrls, page, handleChange, isDelete, onDeleteFile }) => {
    const thumbnailRenderBasedOnType = (media, itemPage) => {
      switch (media.type) {
        case 'jpg':
        case 'png':
        case 'jpeg':
          return (
            <div onClick={() => handleChange(itemPage)}>
              <div
                className={`thumbnail-item ${
                  itemPage === page ? 'selected-item' : ''
                }`}
              >
                {isDelete && (
                  <Tooltip title='Delete'>
                    <Icon
                      className='remove-image-icon'
                      onClick={(event) => onDeleteFile(event, media)}
                    >
                      cancel_rounded
                    </Icon>
                  </Tooltip>
                )}
                <img src={media.url} alt='thumb' className='image-thumbnail' />
              </div>
              <TextTooltip text={media.fileName} />
            </div>
          );
        // break;
        case 'mp4':
          return (
            <div onClick={() => handleChange(itemPage)}>
              <div
                className={`text-align-center thumbnail-item ${
                  itemPage === page ? 'selected-item' : ''
                }`}
              >
                {isDelete && (
                  <Tooltip title='Delete'>
                    <Icon
                      className='remove-image-icon'
                      onClick={(event) => onDeleteFile(event, media)}
                    >
                      cancel_rounded
                    </Icon>
                  </Tooltip>
                )}
                <Icon className='video-thumbnail'>video_library_rounded</Icon>
              </div>
              <TextTooltip text={media.fileName} />
            </div>
          );
        // break;
        case 'pdf':
          return (
            <div onClick={() => handleChange(itemPage)}>
              <div
                className={`text-align-center thumbnail-item ${
                  itemPage === page ? 'selected-item' : ''
                }`}
              >
                {isDelete && (
                  <Tooltip title='Delete'>
                    <Icon
                      className='remove-image-icon'
                      onClick={(event) => onDeleteFile(event, media)}
                    >
                      cancel_rounded
                    </Icon>
                  </Tooltip>
                )}
                <Icon className='pdf-thumbnail'>picture_as_pdf_rounded</Icon>
              </div>
              <TextTooltip text={media.fileName} />
            </div>
          );
        // break;
        default:
          return (
            <div onClick={() => handleChange(itemPage)}>
              <div
                className={`text-align-center thumbnail-item ${
                  itemPage === page ? 'selected-item' : ''
                }`}
              >
                {isDelete && (
                  <Tooltip title='Delete'>
                    <Icon
                      className='remove-image-icon'
                      onClick={(event) => onDeleteFile(event, media)}
                    >
                      cancel_rounded
                    </Icon>
                  </Tooltip>
                )}
                <Icon className='pdf-thumbnail'>insert_drive_file_rounded</Icon>
              </div>
              <TextTooltip text={media.fileName} />
            </div>
          );
        // break;
        // code block
      }
    };

    return (
      <Pagination
        count={mediaUrls.length}
        page={page}
        className='sa-thumbnail'
        onChange={(event, page) => handleChange(page)}
        renderItem={(item) => {
          if (item.type === 'page') {
            const mediaIndex = item.page - 1;
            const media = mediaUrls[mediaIndex];
            return (
              <PaginationItem
                component={() => {
                  return thumbnailRenderBasedOnType(media, item.page);
                }}
              />
            );
          } else if (item.type === 'next') {
            return (
              <PaginationItem
                component={() => {
                  if (mediaUrls.length + 1 === item.page) {
                    return (
                      <Icon className='next-icon thumbnail-disable'>
                        play_arrow_rounded
                      </Icon>
                    );
                  } else {
                    return (
                      <Icon
                        className='next-icon'
                        onClick={() => handleChange(item.page)}
                      >
                        play_arrow_rounded
                      </Icon>
                    );
                  }
                }}
              />
            );
          } else if (item.type === 'previous') {
            return (
              <PaginationItem
                component={() => {
                  if (item.page === 0) {
                    return (
                      <Icon className='previous-icon thumbnail-disable'>
                        play_arrow_rounded
                      </Icon>
                    );
                  } else {
                    return (
                      <Icon
                        className='previous-icon'
                        onClick={() => handleChange(item.page)}
                      >
                        play_arrow_rounded
                      </Icon>
                    );
                  }
                }}
              />
            );
          } else {
            return <PaginationItem {...item} />;
          }
        }}
      />
    );
  }
);

export default Thumbnails;
