import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

/**
 * function to render custom html tooltip
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    maxHeight: '150px',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    padding: '10px',
    borderRadius: '8px',
    border: 'solid 0.5px #283745',
    backgroundColor: '#15202a',
  },
}))(Tooltip);

export default HtmlTooltip;
