import HttpRequest from '../../utils/HttpRequest';
import { SNACKBAR_VARIAINT, API_ACTIONS } from '../../utils/Constants';
import {
  hideLoader,
  showLoader,
  showSnackbar,
} from '../../utils/components/AppLoader/AppLoaderActions';
import { I18n } from 'react-redux-i18n';

/**
 * function to make server request to get products
 * @param {*} data: data for server request
 * @returns
 */
export const getProductsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
      searchData
    )}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

export const getAllProductsRequest = (vendorId, data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }/${vendorId}/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
      searchData
    )}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const getAllProductsWithoutPaginationRequest = (vendorId, data) => {
  let offset = null;
  let limit = null;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }/${vendorId}/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
      searchData
    )}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const getProductRequest = (id) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.PRODUCT}/${id}`, 'get', {})
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          return Promise.resolve(res.data.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

export const getProductWithAssociatedUserRequest = (userId) => {
  const url = `${API_ACTIONS.PRODUCT}/associate/user/${userId}`;
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const addProductRequest = (vendorId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.PRODUCT}/${vendorId}`;
    return new HttpRequest(dispatch, url, 'post', data).send().then(
      (res) => {
        dispatch(
          showSnackbar(
            I18n.t('product.add_product.add_product_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const updateProductRequest = (productId, product) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}`,
      'patch',
      product
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('product.add_product.update_product_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const activateDeactivateProductRequest = (productId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              I18n.t('product.product_table.product_enable_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              I18n.t('product.product_table.product_disable_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const getSignedUrlRequest = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/files/signedURL`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const uploadToS3Request = (url, file) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(dispatch, url, 'put', file)
      .s3Upload()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};
