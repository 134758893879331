import React, { useState, useEffect, useRef } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Typography,
  Paper,
  TableContainer,
  Box,
  Icon,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
} from '@mui/material';
import { I18n } from 'react-redux-i18n';
import Constants from '../../../../utils/Constants';
import { CustomTable, MessageModal } from '../../../../utils/components';
import {
  InputFilter,
  MultiselectFilter,
  DateRangeFilter,
  NumberRangeFilter,
} from '../../../../utils/components/Filter';
import { surveyListData, vendorOptions } from '../../data';
import UtilHelper from '../../../../utils/UtilHelper';
import { getSurveysRequest } from '../../SurveyApiActions';
import { useStyles } from './styles';
import moment from 'moment';

/**
 * function to render survey list
 * @param {*} history: history list
 * @param {*} getProducts: function to get products
 * @param {*} routeFrom: string value of route for dynamic routing
 * @returns
 */
const SurveyList = ({ history, getSurveys, routeFrom }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const modalRef = useRef();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      searchFilter: '',
      vendors: [],
      duration: null,
      responseCount: null,
    },
  });
  const [surveyList, setSurveyList] = useState(null);

  /**
   * function to get survey list
   * @param {*} requestData: request data
   */
  const getSurveyList = async (requestData) => {
    /**
     * demo data for UI
     */
    setSurveyList(surveyListData);

    try {
      const data = await getSurveys(requestData);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    getSurveyList(paginationParams);
    //eslint-disable-next-line
  }, [paginationParams]);

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to handle menu close
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  /**
   * function to handle delete survey
   */
  const onDeleteSurvey = () => {
    const title = I18n.t('survey.confirmation_dialog.delete_survey');
    const message = I18n.t('survey.confirmation_dialog.delete_survey_message');

    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //   note: note,
        //   enabled: !enabled,
        // };
        // console.log(data);
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  const columns = [
    {
      title: I18n.t('survey.survey_table.table_column_name_label'),
      field: 'name',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ id, name }) => {
        return (
          <Box
            component={Link}
            to={`${match?.url}${routeFrom ? routeFrom : ''}/${id}`}
          >
            <Typography variant='body2'>
              {UtilHelper.ellipsisRender(name, 30)}
            </Typography>
          </Box>
        );
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_vendor_label'),
      field: 'vendor',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ vendor }) => {
        return (
          <Typography variant='body2'>
            {UtilHelper.ellipsisRender(vendor, 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_duration_label'),
      field: 'startDate',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ startDate, endDate }) => {
        return (
          <Typography variant='body2'>
            {`${moment(startDate).format('DD-MM-YYYY')} to
              ${moment(endDate).format('DD-MM-YYYY')}
            `}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_responses_label'),
      field: 'responseCount',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '10%',
      render: ({ responseCount }) => {
        return <Typography variant='body2'>{responseCount}</Typography>;
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_action_label'),
      field: '-',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '5%',
      filtering: false,
      render: (props) => {
        return (
          <>
            <IconButton
              color='inherit'
              aria-label='option'
              aria-controls={`customized-menu-${props.id}`}
              aria-haspopup='true'
              onClick={(event) => {
                menuRef.current = props.id;
                handleMenuClick(event);
              }}
              className='option-menu'
            >
              <Icon fontSize='small'>more_vert_rounded</Icon>
            </IconButton>
            <Menu
              id={`customized-menu-${props.id}`}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              keepMounted
              open={menuRef.current === props.id}
              onClose={() => {
                handleMenuClose();
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onDeleteSurvey();
                }}
              >
                <ListItemIcon>
                  <Icon fontSize='small'>delete</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={I18n.t('survey.survey_table.table_delete_tooltip')}
                />
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  /**
   * function to handle add product click
   */
  const onAddSurvey = () => {
    const url = `${match?.url}${routeFrom ? routeFrom : ''}/add`;
    history.push(url);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box>
        <Box
          component={Paper}
          p={1.5}
          pt={1}
          mb={1}
          display='flex'
          alignItems='flex-end'
          justifyContent='space-between'
          color='#fff'
        >
          <Grid
            container
            spacing={1}
            classes={{ container: classes.customGridContainer }}
          >
            <Grid item classes={{ item: classes.customGridItem }}>
              <Box width='220px' mr={1}>
                <InputFilter
                  filterName=''
                  placeholder={I18n.t(
                    'survey.survey_table.table_filter_search_by_name'
                  )}
                  onChangeFilter={(value) =>
                    handleChangeFilter('searchFilter', value)
                  }
                />
              </Box>
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <MultiselectFilter
                arrayList={vendorOptions}
                filterName={I18n.t(
                  'survey.survey_table.table_filter_vendor_label'
                )}
                title={I18n.t('survey.survey_table.table_filter_vendor_label')}
                filterOn='name'
                value={paginationParams.searchData.vendors}
                onChangeFilter={(value) => handleChangeFilter('vendors', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <DateRangeFilter
                filterName={I18n.t(
                  'survey.survey_table.table_filter_duration_label'
                )}
                title={I18n.t(
                  'survey.survey_table.table_filter_duration_label'
                )}
                value={paginationParams.searchData.duration}
                onChangeFilter={(value) =>
                  handleChangeFilter('duration', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'survey.survey_table.table_filter_response_label'
                )}
                title={I18n.t(
                  'survey.survey_table.table_filter_response_label'
                )}
                value={paginationParams.searchData.responseCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('responseCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant='contained'
              startIcon={<Icon>add_circle</Icon>}
              color='secondary'
              onClick={() => onAddSurvey()}
            >
              {I18n.t('survey.survey_table.add_survey_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <TableContainer>
        {renderTableTitle()}

        <CustomTable
          columns={columns}
          data={surveyList?.rows}
          hidePaging={false}
          hideBorderShadow={true}
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          rowsPerPage={paginationParams.rowsPerPage}
          count={surveyList?.count}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = page;
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = 0;
            pagination.rowsPerPage = rowsPerPage;
            setPaginationParams(pagination);
          }}
          // columns={columns}
          // data={fileList ? fileList.csv_list : []}
          // pages={10}
          // pages={Math.ceil(allUsers.count / paginationParams.rowsPerPage)}
          // pages={fileList ? fileList.max_page_count : 0}
          // pageSize={10}
          // pageSize={paginationParams.rowPerPage}
          // totalCount={fileList ? fileList.max_record_count : 0}
          // page={paginationParams.page}
        />
      </TableContainer>

      <MessageModal ref={modalRef} />
    </>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveys: async (data) => {
      try {
        return await dispatch(getSurveysRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);
