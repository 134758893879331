/**
 * vendor list data
 */
export const vendorListData = {
  count: 100,
  rows: [
    {
      id: '1',
      profileImgUrl:
        'https://ui-avatars.com/api/?name=Vendor+1&background=random',

      name: 'Vendor 1',
      enabled: true,
      merchandisesCount: 20,
      surveyCount: 30,
      eventsCount: 40,
      mentionsCount: 38,
    },
    {
      id: '2',
      profileImgUrl:
        'https://ui-avatars.com/api/?name=Vendor+2&background=random',

      name: 'Vendor 2',
      enabled: false,
      merchandisesCount: 430,
      surveyCount: 402,
      eventsCount: 503,
      mentionsCount: 88,
    },
  ],
};
