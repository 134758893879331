import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Icon, Paper } from '@mui/material';
import { SpiritCard, SpiritForm } from '../index';
import {
  InputFilter,
  NumberRangeFilter,
} from '../../../../utils/components/Filter';
import { MessageModal, CustomPagination } from '../../../../utils/components';
import Constants from '../../../../utils/Constants';
import { spiritListData } from '../../data';
import { getSpiritsRequest } from '../../SpiritsApiActions';
import { I18n } from 'react-redux-i18n';

/**
 * function to render whiskey tube list
 * @param {*} getSpirits: function to get whiskey tubes
 * @returns
 */
const SpiritList = ({ getSpirits }) => {
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      searchFilter: '',
      cost: null,
    },
  });
  const [spiritList, setSpiritList] = useState(null);
  const [showSpiritForm, setShowSpiritForm] = useState(false);
  const [selectedSpirit, setSelectedSpirit] = useState(null);
  const modalRef = useRef();

  /**
   * function to get whiskey tube list
   * @param {*} requestData: request data
   */
  const getSpiritList = async (requestData) => {
    /**
     * demo data for UI
     */
    setSpiritList(spiritListData);

    try {
      const data = getSpirits(requestData);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    getSpiritList(paginationParams);
    //eslint-disable-next-line
  }, [paginationParams]);

  /**
   * function to handle video form open
   */
  const handleSpiritFormOpen = () => {
    setShowSpiritForm(true);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle edit click
   * @param {*} id: id
   */
  const handleCardEditButtonClick = (id) => {
    setShowSpiritForm(true);
    const clickedVideoData = spiritListData.rows.find(
      (video) => video.id === id
    );
    console.log(clickedVideoData);
    setSelectedSpirit(clickedVideoData);
  };

  /**
   * function to handle activate / deactivate a user
   * @param {*} userId: user id
   * @param {*} enabled: active status boolean
   * @param {*} name: user name
   */
  const onActivateDeactivate = (userId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} Spirit`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //    note: note,
        //   enabled: !enabled,
        // };
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Box>
        <Box
          component={Paper}
          display='flex'
          alignItems='flex-end'
          justifyContent='space-between'
          color='#fff'
          p={1.5}
          pt={1}
        >
          <Box display='flex' alignItems='flex-end'>
            <Box width='220px' mr={2}>
              <InputFilter
                filterName=''
                placeholder={I18n.t('spirit.spirit_list.filter_name_label')}
                onChangeFilter={(value) =>
                  handleChangeFilter('searchFilter', value)
                }
              />
            </Box>

            <Box>
              <NumberRangeFilter
                filterName={I18n.t('spirit.spirit_list.filter_cost_label')}
                title={I18n.t('spirit.spirit_list.filter_cost_label')}
                value={paginationParams.searchData.cost}
                onChangeFilter={(value) => handleChangeFilter('cost', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Box>
          </Box>

          <Box>
            <Button
              variant='contained'
              startIcon={<Icon>add_circle</Icon>}
              color='secondary'
              onClick={handleSpiritFormOpen}
            >
              {I18n.t('spirit.spirit_list.add_spirit_label')}
            </Button>
          </Box>
        </Box>

        <Box>
          {spiritList?.rows.map((spirit, index) => (
            <Box key={index}>
              <SpiritCard
                spirit={spirit}
                handleCardEditButtonClick={(id) =>
                  handleCardEditButtonClick(id)
                }
                onActivateDeactivate={onActivateDeactivate}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        <CustomPagination
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          count={spiritList?.count}
          rowsPerPage={paginationParams.rowsPerPage}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = page;
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = 0;
            pagination.rowsPerPage = rowsPerPage;
            setPaginationParams(pagination);
          }}
        />
      </Box>

      {showSpiritForm && (
        <SpiritForm
          open={showSpiritForm}
          setOpen={setShowSpiritForm}
          selectedSpirit={selectedSpirit}
          setSelectedSpirit={setSelectedSpirit}
        />
      )}

      <MessageModal ref={modalRef} />
    </>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getSpirits: async (data) => {
      try {
        return await dispatch(getSpiritsRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpiritList);
