import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Card } from '@mui/material';
import { ProductForm } from '../index';
import { I18n } from 'react-redux-i18n';
import './AddProduct.css';

/**
 * function to render add product
 * @param {*} history: history object
 * @param {*} match: match object
 * @param {*} addProduct: function to add product
 * @returns
 */
const AddProduct = ({ history, match, addProduct }) => {
  const vendorId = match.params.vendorId;

  /**
   * function to handle add product
   * @param {*} product: product
   */
  const handleAddProduct = (product) => {
    addProduct(vendorId, product).then((response) => {
      history.goBack();
    });
  };

  /**
   * function to handle cancel add product
   */
  const onCancelAddProduct = () => {
    history.goBack();
  };

  return (
    <Card className='product-card-container'>
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant='h6'
            className='product-dialog-title'
            style={{ marginBottom: '1rem' }}
          >
            {I18n.t('product.add_product.form_title_add_product_label')}
          </Typography>

          <ProductForm
            onCancelAddProduct={onCancelAddProduct}
            submitFormData={(product) => handleAddProduct(product)}
            formData={null}
            isUpdate={false}
            vendorId={vendorId}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
