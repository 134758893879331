import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTable from './MuiTable';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiTypography from './MuiTypography';
import MuiDrawer from './MuiDrawer';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiAlert from './MuiAlert';
import MuiTimelineOppositeContent from './MuiTimelineOppositeContent';
import MuiTimeline from './MuiTimeline';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiListItemText from './MuiListItemText';
import MuiDialog from './MuiDialog';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDialogContent from './MuiDialogContent';
import MuiDialogActions from './MuiDialogActions';
import MuiChip from './MuiChip';
import MuiInput from './MuiInput';
import MuiInputLabel from './MuiInputLabel';
import MuiAutocomplete from './MuiAutocomplete';
import MuiAvatar from './MuiAvatar';
import MuiDivider from './MuiDivider';
import MuiInputAdornment from './MuiInputAdornment';
import MuiListItemIcon from './MuiListItemIcon';
import MuiInputBase from './MuiInputBase';
import MuiTabPanel from './MuiTabPanel';
import MuiAccordionDetails from './MuiAccordionDetails';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiTableContainer from './MuiTableContainer';
import MuiTablePagination from './MuiTablePagination';
import MuiFormControlLabel from './MuiFormControlLabel';

const index = {
  MuiTablePagination,
  MuiTableContainer,
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTable,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTypography,
  MuiDrawer,
  MuiTab,
  MuiTabs,
  MuiAlert,
  MuiTimelineOppositeContent,
  MuiTimeline,
  MuiMenu,
  MuiMenuItem,
  MuiListItemText,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiChip,
  MuiInput,
  MuiInputLabel,
  MuiAutocomplete,
  MuiAvatar,
  MuiDivider,
  MuiInputAdornment,
  MuiListItemIcon,
  MuiInputBase,
  MuiTabPanel,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiFormControlLabel,
};

export default index;
