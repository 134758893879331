import HttpRequest from '../../utils/HttpRequest';
import { SNACKBAR_VARIAINT, API_ACTIONS } from '../../utils/Constants';
import {
  hideLoader,
  showLoader,
  showSnackbar,
} from '../../utils/components/AppLoader/AppLoaderActions';
import { I18n } from 'react-redux-i18n';

/**
 * function to make server request to get whiskey tubes
 * @param {*} data: data for server request
 * @returns
 */
export const getWhiskeyTubesRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.USERS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

export const addUserRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.USERS}`, 'post', data)
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              I18n.t('user.add_user.add_user_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to update user in DB
 * @param {*} userId: user id for server request
 * @param {*} data: data for server request
 * @returns {Promise}
 */
export const updateUserRequest = (userId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.USERS}/${userId}`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('user.add_user.update_user_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to activate / deactivate user in DB
 * @param {*} userId: user id for server request
 * @param {*} data: data for server request
 * @returns {Promise}
 */
export const activateDeactivateUserRequest = (userId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.USERS}/${userId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              I18n.t('user.user_table.user_activate_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              I18n.t('user.user_table.user_deactivate_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to resend invitaion to a user in DB
 * @param {*} userId: user id for server request
 * @returns {Promise}
 */
export const resendInvitationRequest = (userId) => {
  const data = {
    id: userId,
  };

  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.RESEND_INVITATION}`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('user.user_table.user_resend_invitation_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};
