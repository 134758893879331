import { createTheme } from '@mui/material/styles';
import ThemeOne from '../theme.json';
const theme = createTheme();

const MuiDialog = {
  styleOverrides: {
    paper: {
      borderRadius: '8px',
      boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.25)',
      backgroundColor: ThemeOne.primaryColor,
      padding: '0px',
      minWidth: '700px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '200px',
      },
    },
  },
};

export default MuiDialog;
