import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import './AppLoader.css';

/**
 * class component to render app loader
 */
class AppLoader extends React.Component {
  render() {
    if (this.props.showLoader || this.props.loader) {
      return (
        <div className='loader-container'>
          <CircularProgress className='circular-progress-app-loader' />
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    showLoader: state.showLoader.showLoader,
  };
};

AppLoader = connect(mapStateToProps)(AppLoader);

export default AppLoader;
