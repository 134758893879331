import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Typography, Grid, Box } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { forgotPasswordRequest } from '../../AuthenticationApiActions';
import { MessageModal, AppLoader } from '../../../../utils/components';
import { logo } from '../../../../assets';
import { I18n } from 'react-redux-i18n';
import './ForgotPassword.css';
import { ROUTE_ACTIONS } from '../../../../utils/Constants';
import Constants from '../../../../utils/Constants';

/**
 * function to handle forgot password
 * @param {*} history: history object
 * @param {*} forgotPassword: function to handle forgot password
 * @returns
 */
const ForgotPassword = ({ history, forgotPassword }) => {
  const [email, setEmail] = useState('');
  const [isSentResetPassword, setIsSentResetPassword] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    document.title = I18n.t('global.app_name');
  }, []);

  /**
   * function to handle text input change
   * @param {*} event: event object
   */
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    try {
      const res = await forgotPassword({ email });

      setIsSentResetPassword(true);

      modalRef.current.setState({
        showModal: true,
        title: I18n.t('forgot_password.forgot_password_label'),
        // message: `${res.message} ${I18n.t(
        //   'forgot_password.redirection_to_login_page'
        // )}`,
        message: I18n.t('forgot_password.redirection_to_login_page', {
          message: res.message,
        }),
        showOkButton: true,
        handleOk: navigateToHome,
        okText: I18n.t('global.button_labels.ok'),
      });
    } catch (error) {
      modalRef.current.setState({
        showModal: true,
        message: error,
        title: I18n.t('error_messages.error'),
        showOkButton: true,
        handleOk: navigateToHome,
        okText: I18n.t('global.button_labels.ok'),
      });
    }
  };

  /**
   * function to handle navigate to home
   */
  const navigateToHome = () => {
    window.location = ROUTE_ACTIONS.HOME;
  };

  return (
    <Box>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        className='forgot-password-form-outer-div'
      >
        <Grid item className='forgot-password-form-inner-div'>
          <ValidatorForm noValidate onSubmit={handleSubmit}>
            <Box className='application-logo-img-div'>
              <img className='application-logo-img' src={logo} alt='logo' />
            </Box>
            {isSentResetPassword ? (
              <>
                <Typography
                  variant='body1'
                  className='margin-top-48 text-align-center'
                >
                  {I18n.t('forgot_password.reset_password_link_sent', {
                    email: email,
                  })}
                </Typography>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  className='back-to-login-button'
                  onClick={() => history.goBack()}
                >
                  {I18n.t('forgot_password.go_to_sign_in')}
                </Button>
              </>
            ) : (
              <>
                <Typography
                  variant='h5'
                  className='margin-top-48 forgot-password-title'
                >
                  {I18n.t('forgot_password.forgot_password_label')}
                </Typography>

                <TextValidator
                  variant='standard'
                  margin='dense'
                  required
                  autoFocus
                  name='email'
                  className='margin-top-32 forgot-passwords-field label'
                  label={I18n.t(
                    'forgot_password.forgot_password_form_username_label'
                  )}
                  type='text'
                  onChange={handleChange}
                  value={email}
                  fullWidth
                  validators={[
                    'required',
                    'isEmail',
                    'maxStringLength:' +
                      Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                  ]}
                  errorMessages={[
                    I18n.t('error_messages.field_required'),
                    I18n.t('error_messages.email_invalid_error'),
                    I18n.t(
                      'error_messages.maximum_100_allowed_characters_for_text'
                    ),
                  ]}
                />
                <Button
                  variant='contained'
                  type='submit'
                  color='secondary'
                  className='forgot-password-form-button'
                >
                  {I18n.t('forgot_password.request_temporary_password')}
                </Button>
                <Typography
                  variant='button'
                  component='div'
                  className='text-align-center cursor-pointer'
                  onClick={() => history.push(ROUTE_ACTIONS.SIGNIN)}
                >
                  {I18n.t('forgot_password.go_to_sign_in')}
                </Typography>
              </>
            )}
          </ValidatorForm>
        </Grid>
      </Grid>
      <MessageModal ref={modalRef} />
      <AppLoader />
    </Box>
  );
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: async (data) => {
      try {
        return await dispatch(forgotPasswordRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
