import React from 'react';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Box,
  IconButton,
  Icon,
} from '@mui/material';

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: 'none',
  },
  body: {
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
    fontFamily: 'Lato',
    wordBreak: 'break-all',
    padding: `20px 20px 15px 20px`,
  },
}))(TableCell);

const BlankTableCell = withStyles((theme) => ({
  body: {
    padding: `0 0 10px 0`,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderRadius: '4px',
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    tableLayout: 'fixed',
  },
});

const getAlignment = (align) => (align ? align : 'left');
const getWidth = (width) => (width ? width : 'auto');

/**
 * function to return table pagination actions
 * @param {*} props
 * @returns
 */
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        // aria-label='first page'
        style={{ color: 'inherit' }}
      >
        {theme.direction === 'rtl' ? (
          <Icon>last_page</Icon>
        ) : (
          <Icon>first_page</Icon>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        // aria-label='previous page'
        style={{ color: 'inherit' }}
      >
        {theme.direction === 'rtl' ? (
          <Icon>keyboard_arrow_right</Icon>
        ) : (
          <Icon>keyboard_arrow_left</Icon>
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        // aria-label='next page'
        style={{ color: 'inherit' }}
      >
        {theme.direction === 'rtl' ? (
          <Icon>keyboard_arrow_left</Icon>
        ) : (
          <Icon>keyboard_arrow_right</Icon>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        // aria-label='last page'
        style={{ color: 'inherit' }}
      >
        {theme.direction === 'rtl' ? (
          <Icon>first_page</Icon>
        ) : (
          <Icon>last_page</Icon>
        )}
      </IconButton>
    </Box>
  );
}

/**
 * function to render customized table
 * @param {*} columns: columns
 * @param {*} data: data
 * @param {*} rowsPerPageOptions: rows per page options
 * @param {*} rowsPerPage: rows per page
 * @param {*} count: count
 * @param {*} page: page
 * @param {*} onPageChange: on page change
 * @param {*} onRowsPerPageChange: on rows per page change
 * @param {*} hidePaging: boolean to hide pagination
 * @param {*} hideBorderShadow: boolean to hide border shadow
 * @param {*} removeFixWidth: boolean to remove fixed width
 * @returns
 */
const CustomizedTables = ({
  columns,
  data,
  rowsPerPageOptions,
  rowsPerPage,
  count,
  page,
  onPageChange,
  onRowsPerPageChange,
  hidePaging,
  hideBorderShadow,
  removeFixWidth,
}) => {
  const classes = useStyles();

  return (
    <TableContainer max>
      <Table className={removeFixWidth ? '' : classes.table}>
        <TableHead>
          <TableRow>
            {columns.length > 0 &&
              columns.map(({ width, align, title }, index) => (
                <TableCell
                  width={getWidth(width)}
                  key={`${index}header`}
                  align={getAlignment(align)}
                >
                  <Tooltip title={title}>
                    <span>{title}</span>
                  </Tooltip>
                </TableCell>
              ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data?.length > 0 &&
            data.map((item, index) => (
              <>
                <StyledTableRow key={`${index}TextContainer`}>
                  {columns.length > 0 &&
                    columns.map(
                      ({ width, align, field, render }, columnIndex) => (
                        <StyledTableCell
                          width={getWidth(width)}
                          key={`${index}${columnIndex}Text`}
                          align={getAlignment(align)}
                        >
                          {render ? render(item) : item[field]}
                        </StyledTableCell>
                      )
                    )}
                </StyledTableRow>

                {/*Following code is written to render the shadow for the component*/}
                {!hideBorderShadow && (
                  <StyledTableRow key={`${index}FooterContainer`}>
                    {columns.length > 0 &&
                      columns.map(({ width, align, field }, columnIndex) => (
                        <BlankTableCell
                          width={getWidth(width)}
                          key={`${index}${columnIndex}Footer`}
                          align={getAlignment(align)}
                        >
                          <div className='custom-table-row-footer' />
                        </BlankTableCell>
                      ))}
                  </StyledTableRow>
                )}
              </>
            ))}
        </TableBody>
      </Table>

      {!hidePaging && (
        <div
          className='float-right'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage='Rows'
            // labelDisplayedRows={(labelData) => {
            //   return `${labelData?.from} to ${data?.length} of ${labelData.count}`;
            // }}
            onPageChange={(event, page) => onPageChange(page)}
            onRowsPerPageChange={(e) =>
              onRowsPerPageChange(parseInt(e.target.value, 10))
            }
            ActionsComponent={TablePaginationActions}
          />
        </div>
      )}
    </TableContainer>
  );
};

export default CustomizedTables;
