import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Auth {
  static login(user) {
    cookies.set('user', user, { path: '/', secure: true, sameSite: 'none' });
  }

  static setToken(token) {
    localStorage.setItem('access_token', token);
  }

  static logout() {
    cookies.remove('user', { path: '/' });
    localStorage.removeItem('access_token');
  }

  static logoutAndNavigateToLogin() {
    cookies.remove('user', { path: '/' });
    cookies.remove('CloudFront-Key-Pair-Id', { path: '/' });
    cookies.remove('CloudFront-Policy', { path: '/' });
    cookies.remove('CloudFront-Signature', { path: '/' });
    localStorage.removeItem('access_token');
    window.location = '/';
  }

  static isLoggedIn() {
    const user = cookies.get('user', { path: '/' });
    return !!user;
  }

  static getToken() {
    return localStorage.getItem('access_token');
  }

  static getUserDetails() {
    return cookies.get('user', { path: '/' });
  }

  static setSystemGeneratedPassword(isSystemGeneratedPassword) {
    const user = cookies.get('user', { path: '/' });
    cookies.set(
      'user',
      { ...user, isSystemGeneratedPassword: isSystemGeneratedPassword },
      { path: '/' }
    );
  }
}

export default Auth;
