import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
} from '@mui/material';
import { connect } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Constants, {
  ONLY_BLANK_SPACES,
  USER_ROLES,
} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import MobileNumber from '../../../../utils/components/MobileNumber/MobileNumber';
import { I18n } from 'react-redux-i18n';
import { useStyles } from './styles';

/**
 * function to render super admin form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to set the open state
 * @param {*} selectedUser: selected user
 * @param {*} setSelectedUser: function to set the selected user
 * @returns
 */
const SuperAdminForm = ({ open, setOpen, selectedUser, setSelectedUser }) => {
  const classes = useStyles();

  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    role: USER_ROLES.SUPER_ADMIN,
  });
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setUser({
        name: selectedUser.name,
        email: selectedUser.email,
        phone: selectedUser.phone,
        role: USER_ROLES.SUPER_ADMIN,
      });
    }
  }, [selectedUser]);

  /**
   * function to clear the form
   */
  const clearForm = () => {
    setUser({
      name: '',
      email: '',
      phone: '',
      role: USER_ROLES.SUPER_ADMIN,
    });
  };

  /**
   * function to update mobile number
   * @param {*} phone: phone value
   */
  const updateMobileNumber = (phone) => {
    const data = Object.assign({}, user);
    data['phone'] = phone;
    setUser(data);

    if (!UtilHelper.validatePhone(phone)) {
      setPhoneError(I18n.t('error_messages.mobile_number_invalid_error'));
    } else {
      setPhoneError('');
    }
  };

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, user);
    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = () => {
    // const data = Object.assign({}, user);
    // submitFormData(UtilHelper.trimObject(data));

    if (!phoneError) {
      console.log('submited==>', user);
    }
  };

  /**
   * function to handle form close
   */
  const handleSuperAdminFormClose = () => {
    setOpen(false);
    clearForm();

    if (selectedUser) {
      setSelectedUser(null);
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    if (user.phone.length === 0) {
      setPhoneError(I18n.t('error_messages.field_required'));
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth='md' open={open}>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h6' classes={{ root: classes.headingText }}>
              {!selectedUser
                ? `${I18n.t(
                    'user.super_admin_table.form_heading_add_super_admin'
                  )}`
                : `${I18n.t(
                    'user.super_admin_table.form_heading_update_super_admin'
                  )}`}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant='standard'
                    fullWidth
                    required
                    size='small'
                    helperText={`${user.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`}
                    label={I18n.t(
                      'user.super_admin_table.form_field_name_label'
                    )}
                    onChange={handleChangeInput}
                    name='name'
                    value={user.name}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      'maxStringLength:' +
                        Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_allowed_characters_for_text_field'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant='standard'
                    fullWidth
                    required
                    size='small'
                    label={I18n.t(
                      'user.super_admin_table.form_field_email_label'
                    )}
                    onChange={handleChangeInput}
                    name='email'
                    value={user.email}
                    validators={[
                      'required',
                      'isEmail',
                      'maxStringLength:' +
                        Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.email_invalid_error'),
                      I18n.t(
                        'error_messages.maximum_100_allowed_characters_for_text'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MobileNumber
                    phone={user.phone}
                    updateMobileNumber={updateMobileNumber}
                  />

                  {phoneError && (
                    <FormHelperText
                      error
                      style={{ marginTop: '7px', marginLeft: '14px' }}
                    >
                      {phoneError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type='submit'
                    color='secondary'
                    variant='contained'
                    className='invite-add-btn text-transform-capitalize'
                  >
                    {I18n.t('global.button_labels.save')}
                  </Button>

                  <Button
                    color='inherit'
                    variant='text'
                    className='cancel-btn text-transform-capitalize'
                    onClick={handleSuperAdminFormClose}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminForm);
