import HttpRequest from '../../utils/HttpRequest';
import { SNACKBAR_VARIAINT, API_ACTIONS } from '../../utils/Constants';
import {
  hideLoader,
  showLoader,
  showSnackbar,
} from '../../utils/components/AppLoader/AppLoaderActions';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

/**
 * function to make server request to get vendors
 * @param {*} data: data for server request
 * @returns
 */
export const getVendorsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.VENDORS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

export const getAllVendorsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.VENDORS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * Server API call to get all vendors
 * - Dispatch getAllVendors action after successful response from server
 * @returns {function(*)}
 */
export const getAllVendorsWithoutPaginationRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.VENDORS}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        // dispatch(getAllVendors(res.data.data));
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * Server API call to get vendor details
 * @returns {function(*)}
 */
export const getVendorRequest = (id) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.VENDORS}/${id}`, 'get', {})
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          return Promise.resolve(res.data.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * Server API call to add a new vendor
 * - Dispatch addVendor action after successful response from server
 * @returns {function(*)}
 */
export const addVendorRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.VENDORS}`, 'post', data)
      .send()
      .then(
        (res) => {
          // let vendor = res.data;
          dispatch(
            showSnackbar(
              I18n.t('vendor.add_vendor.add_vendor_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          // dispatch(createVendor(vendor));
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * Server API call to update an vendor
 * - Dispatch updateVendor action after successful response from server
 * @returns {function(*)}
 */
export const updateVendorRequest = (vendorId, vendor) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/${vendorId}`,
      'patch',
      vendor
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('vendor.add_vendor.update_vendor_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        // dispatch(getAllVendorsRequest(vendorPaginationParams));
        // dispatch(updateVendor(res.data));
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const activateDeactivateVendorRequest = (vendorId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/${vendorId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              I18n.t('vendor.vendor_details.vendor_activate_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              I18n.t('vendor.vendor_details.vendor_deactivate_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        // dispatch(activateDeactivateVendor(res.data.data));
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const publishUnpublishVendorRequest = (vendorId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/${vendorId}/publishOrUnpublishAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.published) {
          dispatch(
            showSnackbar(
              I18n.t('vendor.vendor_details.vendor_publish_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              I18n.t('vendor.vendor_details.vendor_unpublish_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        // dispatch(activateDeactivateVendor(res.data.data));
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const getSignedUrlRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/files/signedURL`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const uploadToS3Request = (url, file) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, url, 'put', file)
      .s3Upload()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * Server API call to get all vendorActivities
 * @returns {function(*)}
 */
export const getAllActivitiesByVendorIdRequest = (data, vendorId) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  let startDate = searchData.searchDate
    ? moment(searchData.searchDate.startDate).format('YYYY-MM-DD')
    : null;
  let endDate = searchData.searchDate
    ? moment(searchData.searchDate.endDate).format('YYYY-MM-DD')
    : null;
  return (dispatch) => {
    dispatch(showLoader());
    let url;
    if (startDate && endDate) {
      url = `${API_ACTIONS.VENDORS}/activity/${vendorId}?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
    } else {
      url = `${API_ACTIONS.VENDORS}/activity/${vendorId}?offset=${offset}&limit=${limit}`;
    }

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};
