import React, { useState } from 'react';
import { TextField, InputAdornment, Icon } from '@mui/material';

const InputFilter = React.memo(
  ({ filterName, placeholder, onChangeFilter }) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (event) => {
      const inputValue = event.target.value;
      if (inputValue.length > 1) {
        onChangeFilter(inputValue);
      } else {
        onChangeFilter('');
      }

      setInputValue(inputValue);
    };

    return (
      <TextField
        fullWidth
        variant='standard'
        size='small'
        label={filterName}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => handleChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon>search_rounded</Icon>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default InputFilter;
