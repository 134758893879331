import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Constants, { ONLY_BLANK_SPACES } from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { vendorOptions } from '../../data';
import { I18n } from 'react-redux-i18n';

/**
 * function to render event form
 * @param {*} history: history object
 * @returns
 */
const SurveyForm = ({ history }) => {
  const { surveyId } = useParams();

  const [surveyInfo, setSurveyInfo] = useState({
    name: '',
    vendor: null,
    startDate: null,
    endDate: null,
  });

  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  //eslint-disable-next-line
  const [openFileViewer, setOpenFileViewer] = useState(false);
  //eslint-disable-next-line
  const [selectedFile, setSelectedFile] = useState(undefined);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  /**
   * function to handle input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, surveyInfo);
    data[event.target.name] = event.target.value;
    setSurveyInfo(data);
  };

  /**
   * function to handle date time input change
   * @param {*} name: name
   * @param {*} value: value
   */
  const handleDateTimeInputChange = (name, value) => {
    const data = Object.assign({}, surveyInfo);
    data[name] = value;
    setSurveyInfo(data);
  };

  /**
   * function to handle autocomplete input change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeAutocomplete = (elementName, selectedvalue) => {
    const data = Object.assign({}, surveyInfo);
    data[elementName] = selectedvalue;
    setSurveyInfo(data);
  };

  /**
   * function to handle validation
   */
  const handleValidation = () => {
    if (surveyInfo.startDate === null) {
      setStartDateError(I18n.t('error_messages.field_required'));
    }
    if (surveyInfo.endDate === null) {
      setEndDateError(I18n.t('error_messages.field_required'));
    }
  };

  /**
   * function to handle form submit
   */
  const onSubmit = () => {
    handleValidation();

    const data = {
      ...surveyInfo,
    };

    console.log(data);

    // submitFormData(UtilHelper.trimObject(data));
    // console.log(data);
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    handleValidation();
  };

  return (
    <Paper component={Box} p={3}>
      <Box marginBottom='1rem'>
        <Typography variant='h6'>
          {surveyId
            ? I18n.t('survey.survey_form.form_title_update_label')
            : I18n.t('survey.survey_form.form_title_add_label')}
        </Typography>
      </Box>

      <Box>
        <ValidatorForm onSubmit={onSubmit} noValidate onError={onFormError}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t('survey.survey_form.form_field_name_label')}
                onChange={handleChangeInput}
                helperText={`${surveyInfo.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`}
                name='name'
                value={surveyInfo.name}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                size='small'
                name='vendor'
                required
                getOptionLabel={(option) => (option ? option.name : '')}
                options={vendorOptions}
                onChange={(e, value) =>
                  handleChangeAutocomplete('vendor', value)
                }
                value={surveyInfo.vendor}
                renderInput={(params) => {
                  return (
                    <TextValidator
                      required
                      variant='standard'
                      name='vendor'
                      {...params}
                      fullWidth
                      label={I18n.t(
                        'survey.survey_form.form_field_vendor_label'
                      )}
                      value={surveyInfo.vendor}
                      validators={['required']}
                      errorMessages={[I18n.t('error_messages.field_required')]}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={I18n.t(
                    'survey.survey_form.form_field_start_date_label'
                  )}
                  minDate={new Date()}
                  inputFormat='dd/MM/yyyy'
                  value={surveyInfo.startDate}
                  onChange={(date) =>
                    handleDateTimeInputChange('startDate', date)
                  }
                  renderInput={(params) => (
                    <TextField
                      variant='standard'
                      fullWidth
                      required
                      {...params}
                      helperText={startDateError}
                      FormHelperTextProps={{
                        error: true,
                      }}
                    />
                  )}
                  onError={(reason, value) => {
                    if (reason === 'invalidDate') {
                      setStartDateError(I18n.t('error_messages.invalid_date'));
                    }

                    if (reason === 'minDate') {
                      setStartDateError(I18n.t('error_messages.past_date'));
                    }

                    if (reason === null) {
                      setStartDateError('');
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={I18n.t('survey.survey_form.form_field_end_date_label')}
                  minDate={new Date()}
                  inputFormat='dd/MM/yyyy'
                  value={surveyInfo.endDate}
                  onChange={(date) =>
                    handleDateTimeInputChange('endDate', date)
                  }
                  renderInput={(params) => (
                    <TextField
                      variant='standard'
                      fullWidth
                      required
                      {...params}
                      helperText={endDateError}
                      FormHelperTextProps={{
                        error: true,
                      }}
                    />
                  )}
                  onError={(reason, value) => {
                    if (reason === 'invalidDate') {
                      setEndDateError(I18n.t('error_messages.invalid_date'));
                    }

                    if (reason === 'minDate') {
                      setEndDateError(I18n.t('error_messages.past_date'));
                    }

                    if (reason === null) {
                      setEndDateError('');
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <Button
                  color='inherit'
                  variant='text'
                  onClick={() => history.goBack()}
                >
                  {I18n.t('global.button_labels.cancel')}
                </Button>
                <Box ml={1}>
                  <Button type='submit' color='secondary' variant='contained'>
                    {I18n.t('global.button_labels.save')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>
    </Paper>
  );
};

export default SurveyForm;
