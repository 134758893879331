import React from 'react';
import { Box, Avatar, Typography, Icon } from '@mui/material';
import { useStyles } from './styles';

/**
 * function to render comment item
 * @param {*} imageUrl: image url
 * @param {*} name: name
 * @param {*} text: text
 * @returns
 */
const CommentItem = ({ imageUrl, name, text }) => {
  const classes = useStyles();

  return (
    <Box mt={3.5}>
      <Box display='flex'>
        <Box mr={1.2}>
          <Avatar src={imageUrl} />
        </Box>
        <Box>
          <Box>
            <Typography>
              <span className={classes.nameText}>{name}</span>{' '}
              <span className={classes.commentText}>{text}</span>
            </Typography>
            <Box display='flex' mt={1}>
              <Icon fontSize='small'>favorite_border</Icon>
              <span className={classes.countText}>1</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommentItem;
