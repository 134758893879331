import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  FormHelperText,
} from '@mui/material';
import { connect } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Constants, { ONLY_BLANK_SPACES } from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { I18n } from 'react-redux-i18n';
import ImageUploading from 'react-images-uploading';
import { useStyles } from './styles';

/**
 * function to render spirit form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to change the open state
 * @param {*} selectedSpirit: selected spirit
 * @param {*} setSelectedSpirit: function to set the selected spirit
 * @returns
 */
const SpiritForm = ({ open, setOpen, selectedSpirit, setSelectedSpirit }) => {
  const classes = useStyles();

  const [spirit, setSpirit] = useState({
    name: '',
    cost: '',
    recommendedWeather: {
      Sunny: false,
      Cloudy: false,
      Rainy: false,
      Drizzle: false,
      Snowy: false,
    },
    recommendedTiming: {
      Morning: false,
      Afternoon: false,
      Evening: false,
      Night: false,
    },
    recommendedCuisines: {
      Chinese: false,
      French: false,
      Italian: false,
      Indian: false,
      Japanese: false,
      Moroccan: false,
      Spanish: false,
      Thai: false,
      Turkish: false,
      Indonesian: false,
      Mexican: false,
      Greek: false,
      Malaysian: false,
    },
    recommendedForMood: {
      Cheerful: false,
      Reflective: false,
      Gloomy: false,
      Humorous: false,
      Melancholy: false,
      Idyllic: false,
      Whimsical: false,
      Romantic: false,
      Calm: false,
      Lonely: false,
    },
  });
  const [images, setImages] = useState([]);

  const [recommendedWeatherError, setRecommendedWeatherError] = useState('');
  const [recommendedTimingError, setRecommendedTimingError] = useState('');
  const [recommendedCuisinesError, setRecommendedCuisinesError] = useState('');
  const [recommendedForMoodError, setRecommendedForMoodError] = useState('');
  const [imagesError, setImagesError] = useState('');

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (
      Object.values(spirit.recommendedWeather).some((value) => value === true)
    ) {
      setRecommendedWeatherError('');
    }

    if (
      Object.values(spirit.recommendedTiming).some((value) => value === true)
    ) {
      setRecommendedTimingError('');
    }

    if (
      Object.values(spirit.recommendedCuisines).some((value) => value === true)
    ) {
      setRecommendedCuisinesError('');
    }

    if (
      Object.values(spirit.recommendedForMood).some((value) => value === true)
    ) {
      setRecommendedForMoodError('');
    }
  }, [spirit]);

  useEffect(() => {
    if (images.length > 0) {
      setImagesError('');
    }
  }, [images]);

  useEffect(() => {
    if (selectedSpirit) {
      setSpirit({
        name: selectedSpirit.name,
        cost: selectedSpirit.cost,
        recommendedWeather: selectedSpirit.recommendedWeather,
        recommendedTiming: selectedSpirit.recommendedTiming,
        recommendedCuisines: selectedSpirit.recommendedCuisines,
        recommendedForMood: selectedSpirit.recommendedForMood,
      });
    }
  }, [selectedSpirit]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, spirit);
    data[event.target.name] = event.target.value;
    setSpirit(data);
  };

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleCheckboxInputChange = (keyName, event) => {
    const data = Object.assign({}, spirit);
    data[keyName][event.target.name] = event.target.checked;
    setSpirit(data);
  };

  /**
   * function to handle form validation
   */
  const handleFormValidation = () => {
    if (
      Object.values(spirit.recommendedWeather).every((value) => value === false)
    ) {
      setRecommendedWeatherError(I18n.t('error_messages.field_required'));
    }

    if (
      Object.values(spirit.recommendedTiming).every((value) => value === false)
    ) {
      setRecommendedTimingError(I18n.t('error_messages.field_required'));
    }

    if (
      Object.values(spirit.recommendedCuisines).every(
        (value) => value === false
      )
    ) {
      setRecommendedCuisinesError(I18n.t('error_messages.field_required'));
    }

    if (
      Object.values(spirit.recommendedForMood).every((value) => value === false)
    ) {
      setRecommendedForMoodError(I18n.t('error_messages.field_required'));
    }

    if (images.length === 0) {
      setImagesError(I18n.t('error_messages.field_required'));
    }
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = () => {
    handleFormValidation();

    if (
      Object.values(spirit.recommendedWeather).some(
        (value) => value === true
      ) &&
      Object.values(spirit.recommendedTiming).some((value) => value === true) &&
      Object.values(spirit.recommendedCuisines).some(
        (value) => value === true
      ) &&
      Object.values(spirit.recommendedForMood).some(
        (value) => value === true
      ) &&
      images.length > 0
    ) {
      const data = { ...spirit };
      console.log(data);
      console.log(images);
    }
  };

  /**
   * function to handle form close
   */
  const handleVideoFormClose = () => {
    setOpen(false);

    if (selectedSpirit) {
      setSelectedSpirit(null);
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    handleFormValidation();
  };

  return (
    <Dialog fullWidth maxWidth='md' open={open}>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h6' classes={{ root: classes.headingText }}>
              {!selectedSpirit
                ? `${I18n.t('spirit.spirit_form.form_title_add_label')}`
                : `${I18n.t('spirit.spirit_form.form_title_update_label')}`}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <TextValidator
                    variant='standard'
                    fullWidth
                    required
                    size='small'
                    helperText={`${spirit.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50}`}
                    label={I18n.t('spirit.spirit_form.form_field_name_label')}
                    onChange={handleChangeInput}
                    name='name'
                    value={spirit.name}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      'maxStringLength:' +
                        Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_50_allowed_characters_for_text'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <TextValidator
                    variant='standard'
                    fullWidth
                    required
                    size='small'
                    label={I18n.t('spirit.spirit_form.form_field_cost_label')}
                    onChange={handleChangeInput}
                    name='cost'
                    value={spirit.cost}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      'matchRegexp:^([0-9]*[.])?[0-9]+$',
                      'maxFloat:' + Constants.VALIDATIONS.MAXIMUM_FLOAT_VALUE,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t('error_messages.int_float_only'),
                      I18n.t('error_messages.maximum_allowed_float', {
                        value: Constants.VALIDATIONS.MAXIMUM_FLOAT_VALUE,
                      }),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel classes={{ root: classes.formLabelRoot }}>
                        {I18n.t(
                          'spirit.spirit_form.form_field_recommended_weather_label'
                        )}{' '}
                        *
                      </FormLabel>

                      <FormGroup row>
                        {Object.entries(spirit.recommendedWeather).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  color='secondary'
                                  name={key}
                                  checked={value}
                                  onChange={(event) =>
                                    handleCheckboxInputChange(
                                      'recommendedWeather',
                                      event
                                    )
                                  }
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                  {recommendedWeatherError && (
                    <FormHelperText error>
                      {recommendedWeatherError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel classes={{ root: classes.formLabelRoot }}>
                        {I18n.t(
                          'spirit.spirit_form.form_field_recommended_timing_label'
                        )}{' '}
                        *
                      </FormLabel>

                      <FormGroup row>
                        {Object.entries(spirit.recommendedTiming).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  color='secondary'
                                  name={key}
                                  checked={value}
                                  onChange={(event) =>
                                    handleCheckboxInputChange(
                                      'recommendedTiming',
                                      event
                                    )
                                  }
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                  {recommendedTimingError && (
                    <FormHelperText error>
                      {recommendedTimingError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel classes={{ root: classes.formLabelRoot }}>
                        {I18n.t(
                          'spirit.spirit_form.form_field_recommended_cuisines_label'
                        )}{' '}
                        *
                      </FormLabel>

                      <FormGroup row>
                        {Object.entries(spirit.recommendedCuisines).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  color='secondary'
                                  name={key}
                                  checked={value}
                                  onChange={(event) =>
                                    handleCheckboxInputChange(
                                      'recommendedCuisines',
                                      event
                                    )
                                  }
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                  {recommendedCuisinesError && (
                    <FormHelperText error>
                      {recommendedCuisinesError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel classes={{ root: classes.formLabelRoot }}>
                        {I18n.t(
                          'spirit.spirit_form.form_field_recommended_mood_label'
                        )}{' '}
                        *
                      </FormLabel>

                      <FormGroup row>
                        {Object.entries(spirit.recommendedForMood).map(
                          ([key, value], index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  color='secondary'
                                  name={key}
                                  checked={value}
                                  onChange={(event) =>
                                    handleCheckboxInputChange(
                                      'recommendedForMood',
                                      event
                                    )
                                  }
                                />
                              }
                              label={key}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                  {recommendedForMoodError && (
                    <FormHelperText error>
                      {recommendedForMoodError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {images.length === 0 && (
                    <Box>
                      <ImageUploading
                        value={images}
                        onChange={(imageFiles) => setImages(imageFiles)}
                        maxNumber={1}
                        dataURLKey='data_url'
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <>
                            <Button
                              color='secondary'
                              variant='outlined'
                              onClick={onImageUpload}
                            >
                              {I18n.t(
                                'spirit.spirit_form.form_add_image_label'
                              )}{' '}
                              *
                            </Button>
                          </>
                        )}
                      </ImageUploading>
                    </Box>
                  )}

                  {images.length > 0 && (
                    <Box>
                      <Chip
                        color='secondary'
                        variant='outlined'
                        label={images[0].file.name}
                        onDelete={() => setImages([])}
                      />
                    </Box>
                  )}

                  {imagesError && (
                    <FormHelperText
                      error
                      style={{ marginTop: '7px', marginLeft: '14px' }}
                    >
                      {imagesError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='flex-end'
                  >
                    <Box display='flex' alignItems='center'>
                      <Box>
                        <Button
                          color='inherit'
                          variant='text'
                          onClick={handleVideoFormClose}
                        >
                          {I18n.t('global.button_labels.cancel')}
                        </Button>
                      </Box>
                      <Box ml={1}>
                        <Button
                          type='submit'
                          color='secondary'
                          variant='contained'
                        >
                          {I18n.t('global.button_labels.save')}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SpiritForm);
