import {
  SIGN_IN,
  CHANGE_PASSWORD,
  SESSION_EXPIRED,
  SESSION_EXPIRED_HIDE_MESSAGE,
} from './AuthenticationActions';
import Auth from '../../utils/Auth';

const initialState = {
  loggedInUser: Auth.getUserDetails(),
  showSessionExpiredModal: false,
  sessionExpiredError: '',
};

/**
 * function to update redux store based on different actions
 * @param {*} state: state object
 * @param {*} action: action
 * @returns
 */
const AuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        loggedInUser: action.user,
        showSessionExpiredModal: false,
        sessionExpiredError: '',
      };

    case CHANGE_PASSWORD:
      return {
        loggedInUser: {
          ...state.loggedInUser,
          isSystemGeneratedPassword: false,
        },
        showSessionExpiredModal: false,
        sessionExpiredError: '',
      };

    case SESSION_EXPIRED:
      return {
        loggedInUser: state.loggedInUser,
        showSessionExpiredModal: true,
        sessionExpiredError: action.error,
      };

    case SESSION_EXPIRED_HIDE_MESSAGE:
      return {
        loggedInUser: state.loggedInUser,
        showSessionExpiredModal: false,
        sessionExpiredError: '',
      };

    default:
      return state;
  }
};

export default AuthenticationReducer;
