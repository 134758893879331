import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Icon, Paper, Grid } from '@mui/material';
import { WhiskeyTubeCard, VideoForm } from '../index';
import {
  DateRangeFilter,
  NumberRangeFilter,
  MultiselectFilter,
} from '../../../../utils/components/Filter';
import { MessageModal, CustomPagination } from '../../../../utils/components';
import Constants from '../../../../utils/Constants';
import { tagOptions, whiskeyTubeListData } from '../../data';
import { useStyles } from './styles';
import { getWhiskeyTubesRequest } from '../../WhiskeyTubeApiActions';
import { I18n } from 'react-redux-i18n';

/**
 * function to render whiskey tube list
 * @param {*} getWhiskeyTubes: function to get whiskey tubes
 * @returns
 */
const WhiskeyTubeList = ({ getWhiskeyTubes }) => {
  const classes = useStyles();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      creationDate: null,
      tags: [],
      views: null,
      comments: null,
      shares: null,
      likes: null,
      saves: null,
    },
  });
  const [whiskeyTubeList, setWhiskeyTubeList] = useState(null);
  const [showVideoForm, setShowVideoForm] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const modalRef = useRef();

  /**
   * function to get whiskey tube list
   * @param {*} requestData: request data
   */
  const getWhiskeyTubeList = async (requestData) => {
    /**
     * demo data for UI
     */
    setWhiskeyTubeList(whiskeyTubeListData);

    try {
      const data = getWhiskeyTubes(requestData);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    getWhiskeyTubeList(paginationParams);
    //eslint-disable-next-line
  }, [paginationParams]);

  /**
   * function to handle video form open
   */
  const handleVideoFormOpen = () => {
    setShowVideoForm(true);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle edit click
   * @param {*} id: id
   */
  const handleCardEditButtonClick = (id) => {
    setShowVideoForm(true);
    const clickedVideoData = whiskeyTubeListData.rows.find(
      (video) => video.id === id
    );
    setSelectedVideo(clickedVideoData);
  };

  /**
   * function to handle archive or unarchive
   * @param {*} videoId: vieod id
   * @param {*} value: value
   * @param {*} videoTitle: video title
   */
  const onArchiveUnarchive = (videoId, value, videoTitle) => {
    const title = `${value ? 'Unarchive' : 'Archive'} Video`;
    const message = `Are you sure you want to ${
      value ? 'unarchive' : 'archive'
    } ${videoTitle}`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //   note: note,
        //   enabled: !value,
        // };
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={2}
          display='flex'
          alignItems='flex-start'
          justifyContent='space-between'
          color='#fff'
        >
          <Grid
            container
            spacing={1}
            classes={{ container: classes.customGridContainer }}
          >
            <Grid item classes={{ item: classes.customGridItem }}>
              <DateRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_creation_date_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_creation_date_label'
                )}
                value={paginationParams.searchData.creationDate}
                onChangeFilter={(value) =>
                  handleChangeFilter('creationDate', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <MultiselectFilter
                arrayList={tagOptions}
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_tag_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_tag_label'
                )}
                filterOn='name'
                value={paginationParams.searchData.tags}
                onChangeFilter={(value) => handleChangeFilter('tags', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_view_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_view_label'
                )}
                value={paginationParams.searchData.views}
                onChangeFilter={(value) => handleChangeFilter('views', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_comment_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_comment_label'
                )}
                value={paginationParams.searchData.comments}
                onChangeFilter={(value) =>
                  handleChangeFilter('comments', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_share_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_share_label'
                )}
                value={paginationParams.searchData.shares}
                onChangeFilter={(value) => handleChangeFilter('shares', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_like_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_like_label'
                )}
                value={paginationParams.searchData.likes}
                onChangeFilter={(value) => handleChangeFilter('likes', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_save_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_save_label'
                )}
                value={paginationParams.searchData.saves}
                onChangeFilter={(value) => handleChangeFilter('saves', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant='contained'
              startIcon={<Icon>add_circle</Icon>}
              color='secondary'
              onClick={handleVideoFormOpen}
            >
              {I18n.t('whiskey_tube.whiskey_tube_list.add_video_label')}
            </Button>
          </Box>
        </Box>
        <Box>
          {whiskeyTubeList?.rows.map((video, index) => (
            <Box key={index}>
              <WhiskeyTubeCard
                video={video}
                handleCardEditButtonClick={(id) =>
                  handleCardEditButtonClick(id)
                }
                onArchiveUnarchive={onArchiveUnarchive}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        <CustomPagination
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          count={whiskeyTubeList?.count}
          rowsPerPage={paginationParams.rowsPerPage}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = page;
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = 0;
            pagination.rowsPerPage = rowsPerPage;
            setPaginationParams(pagination);
          }}
        />
      </Box>

      {showVideoForm && (
        <VideoForm
          open={showVideoForm}
          setOpen={setShowVideoForm}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
        />
      )}

      <MessageModal ref={modalRef} />
    </>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getWhiskeyTubes: async (data) => {
      try {
        return await dispatch(getWhiskeyTubesRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhiskeyTubeList);
