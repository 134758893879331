import React, { useRef, useState } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import {
  Paper,
  Box,
  Typography,
  Icon,
  Tooltip,
  Grid,
  Chip,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { MessageModal } from '../../../../utils/components';
import { useStyles } from './styles';
import { I18n } from 'react-redux-i18n';
import { QuestionForm, QuestionItem } from '../index';
import { questionListData } from '../../data';

/**
 * function to render event detailed view
 * @param {*} history: history object
 * @returns
 */
const SurveyDetails = ({ history }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { eventId } = useParams();
  const modalRef = useRef();

  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [showQuestionFormDialog, setShowQuestionFormDialog] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  /**
   * function to handle edit icon click
   */
  const onEditEvent = () => {
    const url = `${match.url}/update`;
    history.push(url);
  };

  /**
   * function to handle form close
   */
  const handleCloseQuestionForm = () => {
    setShowQuestionForm(false);
  };

  /**
   * function to handle delete question
   * @param {*} questionId: question id
   * @param {*} questionTitle: question title
   */
  const onDeleteQuestion = (questionId, questionTitle) => {
    const title = I18n.t('survey.confirmation_dialog.delete_question');
    const message = I18n.t(
      'survey.confirmation_dialog.delete_question_message',
      { title: questionTitle }
    );
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //   // note: note,
        //   enabled: !enabled,
        // };
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle question form dialog open
   */
  const handleQuestionFormDialogOpen = () => {
    setShowQuestionFormDialog(true);
  };

  /**
   * function to handle form dialog close
   */
  const handleCloseQuestionFormDialog = () => {
    setShowQuestionFormDialog(false);
  };

  /**
   * function to handle edit question icon click
   * @param {*} questionInfo: question info
   */
  const handleEditIconClick = (questionInfo) => {
    setSelectedQuestion(questionInfo);
  };

  return (
    <>
      <Paper component={Box} p={3}>
        <Box mb={4}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center'>
                <Box mt={0.4} mr={1} ml={-0.5}>
                  <Tooltip title='Back'>
                    <Icon onClick={() => history.goBack()}>
                      arrow_back_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box>
                  <Typography variant='h6'>
                    {I18n.t('survey.survey_details.page_header_label')}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Box display='flex' alignItems='center' mr={4}>
                  <Icon>poll</Icon> <Typography variant='body2'>16</Typography>
                </Box>

                <Box display='flex' alignItems='center'>
                  <Icon>question_answer</Icon>{' '}
                  <Typography variant='body2'>178</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <Box>
                  <Tooltip
                    placement='top'
                    title={I18n.t(
                      'survey.survey_details.survey_edit_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{ root: classes.actionIcon }}
                      onClick={() => onEditEvent(eventId)}
                    >
                      edit_rounded
                    </Icon>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('survey.survey_details.field_name_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>Event 1</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('survey.survey_details.field_vendor_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Chip
                    variant='outlined'
                    avatar={
                      <Avatar
                        alt='vendor'
                        src={`https://ui-avatars.com/api/?name=Super+Admin&background=random`}
                      ></Avatar>
                    }
                    label={`Vendor 1`}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('survey.survey_details.field_duration_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>
                    31-12-2021 to 31-12-2021
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box mt={2} display='flex' alignItems='center'>
        <Box>
          <Typography>Questions</Typography>
        </Box>
        {!showQuestionForm && (
          <Box>
            <IconButton onClick={() => setShowQuestionForm(true)}>
              <Icon>add_circle</Icon>
            </IconButton>
          </Box>
        )}
      </Box>

      {showQuestionForm && (
        <Box mt={2}>
          <QuestionForm handleCloseQuestionForm={handleCloseQuestionForm} />
        </Box>
      )}

      {questionListData.map((question) => (
        <Box key={question.id} mt={2}>
          <QuestionItem
            question={question}
            onDeleteQuestion={onDeleteQuestion}
            handleQuestionFormDialogOpen={handleQuestionFormDialogOpen}
            handleEditIconClick={handleEditIconClick}
          />
        </Box>
      ))}

      {showQuestionFormDialog && (
        <Dialog fullWidth maxWidth='md' open={showQuestionFormDialog}>
          <DialogTitle>Update Question</DialogTitle>
          <QuestionForm
            handleCloseQuestionForm={handleCloseQuestionFormDialog}
            selectedQuestion={selectedQuestion}
          />
        </Dialog>
      )}

      <MessageModal ref={modalRef} />
    </>
  );
};

export default SurveyDetails;
