/**
 * super admin list
 */
export const superAdminData = {
  count: 100,
  rows: [
    {
      id: '1',
      imageURL:
        'https://ui-avatars.com/api/?name=Super+Admin&background=random',

      name: 'Super Admin',
      email: 'sa@mailinator.com',
      phone: '+19845364536',
      invitationStatus: false,
      createdAt: '2021-10-13T06:07:54.144Z',
      enabled: true,
      registrationComplete: false,
    },
    {
      id: '2',
      imageURL:
        'https://ui-avatars.com/api/?name=Muper+Admin&background=random',
      name: 'Mark Andri',
      email: 'mark@mailinator.com',
      phone: '+19845264568',
      invitationStatus: true,
      createdAt: '2018-05-12T06:07:54.144Z',
      enabled: false,
      registrationComplete: true,
    },
  ],
};

/**
 * vendor admin list
 */
export const vendorAdminData = {
  count: 100,
  rows: [
    {
      id: '1',
      imageURL:
        'https://ui-avatars.com/api/?name=Vendor+Admin&background=random',
      name: 'Vendor Admin',
      email: 'va@mailinator.com',
      phone: '+19493134638',
      invitationStatus: false,
      createdAt: '2021-10-13T06:07:54.144Z',
      enabled: true,
      registrationComplete: false,
      pageName: 'Vendor 1',
      pageLogo: 'https://ui-avatars.com/api/?name=Vendor1&background=random',
    },
    {
      id: '2',
      imageURL:
        'https://ui-avatars.com/api/?name=Muper+Admin&background=random',
      name: 'Brad Tred',
      email: 'brad@mailinator.com',
      phone: '+19194309765',
      invitationStatus: true,
      createdAt: '2018-05-12T06:07:54.144Z',
      enabled: false,
      registrationComplete: true,
      pageName: 'Vendor 2',
      pageLogo: 'https://ui-avatars.com/api/?name=Vendor2&background=random',
    },
  ],
};

/**
 * end user list
 */
export const endUserData = {
  count: 100,
  rows: [
    {
      id: '1',
      imageURL:
        'https://ui-avatars.com/api/?name=Francisco+Kane&background=random',
      name: 'Francisco Kane',
      email: 'fk@mailinator.com',
      phone: '+19493134677',
      createdAt: '2021-10-13T06:07:54.144Z',
      lastActiveAt: '2021-10-13T06:07:54.144Z',
      enabled: true,
      postsCount: '15',
      commentsCount: '6',
      productsPurchasedCount: '2',
      eventsAttendedCount: '16',
      surveyUndertakenCount: '5',
      partiesPlannedCount: '2',
    },
    {
      id: '2',
      imageURL:
        'https://ui-avatars.com/api/?name=Kayson+Goff&background=random',
      name: 'Kayson Goff',
      email: 'kg@mailinator.com',
      phone: '+19194309733',
      createdAt: '2018-05-12T06:07:54.144Z',
      lastActiveAt: '2021-10-13T06:07:54.144Z',
      enabled: false,
      postsCount: '106',
      commentsCount: '367',
      productsPurchasedCount: '16',
      eventsAttendedCount: '22',
      surveyUndertakenCount: '167',
      partiesPlannedCount: '19',
    },
  ],
};
