import { createTheme } from '@mui/material/styles';
import ThemeOne from '../theme.json';
const theme = createTheme();

const MuiAutocomplete = {
  styleOverrides: {
    paper: {
      maxHeight: '300px',
      fontFamily: ThemeOne.fontFamily,
      overflowY: 'auto',
      padding: '24px 8px 0 16px',
      borderRadius: '8px',
      boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.25)',
      backgroundColor: ThemeOne.primaryColor,
      [theme.breakpoints.down('md')]: {
        maxHeight: '200px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#4d9ce6',
        borderRadius: '20px',
      },
      '&::-webkit-scrollbar': {
        width: '4px',
        backgroundColor: '#15202a',
      },
    },
    listbox: {
      padding: '0',
      overflow: 'unset',
    },
    option: {
      padding: '0 0 16px 0 !important',
    },
    tag: {
      color: '#4d9ce6',
      border: 'solid 1px #4d9ce6',
      fontSize: '11px',
      fontWeight: 'bold',
    },
    input: {
      marginBottom: '6.5px',
    },
  },
};

export default MuiAutocomplete;
