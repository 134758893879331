import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { VendorForm } from '../index';
import { addVendorRequest } from '../../VendorApiActions';
import { I18n } from 'react-redux-i18n';
import './AddVendor.css';

/**
 * function to render add vendor
 * @param {*} inviteVendor: function to handle invite vendor
 * @param {*} handleCloseDialog: function to handle dialog close
 * @param {*} openDialog: boolean to open form dialog
 * @returns
 */
const AddVendor = ({ inviteVendor, handleCloseDialog, openDialog }) => {
  const handleInviteVendor = (vendor) => {
    inviteVendor(vendor).then((response) => {
      handleCloseDialog();
    });
  };

  /**
   * function to cancel invite vendor
   */
  const onCancelInviteVendor = () => {
    handleCloseDialog();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      open={openDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent className='vendor-dialog-form-content'>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h6' className='vendor-dialog-title'>
              {I18n.t('vendor.add_vendor.form_title_add_vendor_label')}
            </Typography>
            <VendorForm
              onCancelInviteVendor={onCancelInviteVendor}
              submitFormData={(vendor) => handleInviteVendor(vendor)}
              formData={null}
              isUpdate={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    inviteVendor: async (data) => {
      try {
        return await dispatch(addVendorRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
