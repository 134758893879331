import React from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Typography,
} from '@mui/material';
import { CommentItem } from '../index';
import { I18n } from 'react-redux-i18n';

/**
 * function to render comment list
 * @returns
 */
const CommentList = () => {
  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<Icon style={{ color: '#bcccdc' }}>expand_more</Icon>}
        >
          <Typography>
            {I18n.t('whiskey_tube.whiskey_tube_list.comments_label')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <CommentItem
              imageUrl='https://ui-avatars.com/api/?name=Kelly+Jones&background=random'
              name='Kelly Jones'
              text='Love the initiative that is so beacuse it allows everyone else'
            />
            <CommentItem
              imageUrl='https://ui-avatars.com/api/?name=Adam+Jhons&background=random'
              name='Adam Jhons'
              text='@Kelly Jones initiative that is so beacuse it allows everyone else'
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CommentList;
