import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    customGridContainer: {
      // marginTop: '-1px !important',
    },

    customGridItem: {
      minWidth: 'fit-content !important',
    },
  };
});
