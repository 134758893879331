import {
  HIDE_LOADER,
  HIDE_SNACK_BAR,
  SHOW_LOADER,
  SHOW_SNACK_BAR,
} from './AppLoaderActions';

const initialState = {
  showLoader: false,
  showSnackbar: false,
  snackbarVariant: '',
  snackbarMessage: '',
};

const AppLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return Object.assign({}, state, {
        showLoader: action.showLoader,
      });

    case HIDE_LOADER: {
      return Object.assign({}, state, {
        showLoader: action.showLoader,
      });
    }

    case HIDE_SNACK_BAR:
      return Object.assign({}, state, {
        showSnackbar: false,
        snackbarMessage: '',
        snackbarVariant: '',
      });

    case SHOW_SNACK_BAR: {
      return Object.assign({}, state, {
        showSnackbar: true,
        snackbarMessage: action.snackbarMessage,
        snackbarVariant: action.snackbarVariant,
        showLoader: false,
      });
    }

    default:
      return state;
  }
};

export default AppLoaderReducer;
