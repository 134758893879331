import { colors } from '@mui/material';

const MuiTableHead = {
  styleOverrides: {
    root: {
      backgroundColor: colors.grey[50],
      fontFamily: 'Lato',
    },
  },
};

export default MuiTableHead;
