import ThemeOne from './theme.json';
const typography = {
  h1: {
    fontFamily: ThemeOne.h1.fontFamily,
    fontWeight: ThemeOne.h1.fontWeight,
    fontSize: ThemeOne.h1.fontSize,
    fontStyle: ThemeOne.h1.fontStyle,
    color: ThemeOne.h1.color,
    lineHeight: ThemeOne.h1.lineHeight,
    letterSpacing: ThemeOne.h1.letterSpacing,
  },

  h2: {
    fontFamily: ThemeOne.h2.fontFamily,
    fontWeight: ThemeOne.h2.fontWeight,
    fontSize: ThemeOne.h2.fontSize,
    fontStyle: ThemeOne.h2.fontStyle,
    color: ThemeOne.h2.fontStyle,
    lineHeight: ThemeOne.h2.fontStyle,
    letterSpacing: ThemeOne.h2.fontStyle,
  },

  h3: {
    fontFamily: ThemeOne.h3.fontFamily,
    fontWeight: ThemeOne.h3.fontWeight,
    fontSize: ThemeOne.h3.fontSize,
    fontStyle: ThemeOne.h3.fontStyle,
    color: ThemeOne.h3.color,
    lineHeight: ThemeOne.h3.lineHeight,
    letterSpacing: ThemeOne.h3.letterSpacing,
  },

  h4: {
    fontFamily: ThemeOne.h4.fontFamily,
    fontWeight: ThemeOne.h4.fontWeight,
    fontSize: ThemeOne.h4.fontSize,
    fontStyle: ThemeOne.h4.fontStyle,
    color: ThemeOne.h4.color,
    lineHeight: ThemeOne.h4.lineHeight,
    letterSpacing: ThemeOne.h4.letterSpacing,
  },

  h5: {
    fontFamily: ThemeOne.h5.fontFamily,
    fontWeight: ThemeOne.h5.fontWeight,
    fontSize: ThemeOne.h5.fontSize,
    fontStyle: ThemeOne.h5.fontStyle,
    color: ThemeOne.h5.color,
    lineHeight: ThemeOne.h5.lineHeight,
    letterSpacing: ThemeOne.h5.letterSpacing,
  },

  h6: {
    fontFamily: ThemeOne.h6.fontFamily,
    fontWeight: ThemeOne.h6.fontWeight,
    fontSize: ThemeOne.h6.fontSize,
    fontStyle: ThemeOne.h6.fontStyle,
    color: ThemeOne.h6.color,
    lineHeight: ThemeOne.h6.lineHeight,
    letterSpacing: ThemeOne.h6.letterSpacing,
  },

  subtitle1: {
    fontFamily: ThemeOne.subtitle1.fontFamily,
    fontWeight: ThemeOne.subtitle1.fontWeight,
    fontSize: ThemeOne.subtitle1.fontSize,
    fontStyle: ThemeOne.subtitle1.fontStyle,
    color: ThemeOne.subtitle1.color,
    lineHeight: ThemeOne.subtitle1.lineHeight,
    letterSpacing: ThemeOne.subtitle1.letterSpacing,
  },

  subtitle2: {
    fontFamily: ThemeOne.subtitle2.fontFamily,
    fontWeight: ThemeOne.subtitle2.fontWeight,
    fontSize: ThemeOne.subtitle2.fontSize,
    fontStyle: ThemeOne.subtitle2.fontStyle,
    color: ThemeOne.subtitle2.color,
    lineHeight: ThemeOne.subtitle2.lineHeight,
    letterSpacing: ThemeOne.subtitle2.letterSpacing,
  },

  body1: {
    fontFamily: ThemeOne.body1.fontFamily,
    fontWeight: ThemeOne.body1.fontWeight,
    fontSize: ThemeOne.body1.fontSize,
    fontStyle: ThemeOne.body1.fontStyle,
    color: ThemeOne.body1.color,
    lineHeight: ThemeOne.body1.lineHeight,
    letterSpacing: ThemeOne.body1.letterSpacing,
  },

  body2: {
    fontFamily: ThemeOne.body2.fontFamily,
    fontWeight: ThemeOne.body2.fontWeight,
    fontSize: ThemeOne.body2.fontSize,
    fontStyle: ThemeOne.body2.fontStyle,
    color: ThemeOne.body2.color,
    lineHeight: ThemeOne.body2.lineHeight,
    letterSpacing: ThemeOne.body2.letterSpacing,
  },

  button: {
    fontFamily: ThemeOne.button.fontFamily,
    fontWeight: ThemeOne.button.fontWeight,
    fontSize: ThemeOne.button.fontSize,
    fontStyle: ThemeOne.button.fontStyle,
    color: ThemeOne.button.color,
    lineHeight: ThemeOne.button.lineHeight,
    letterSpacing: ThemeOne.button.letterSpacing,
  },

  caption: {
    color: '#bcccdc',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.25px',
    fontFamily: 'Lato',
  },

  overline: {
    color: '#bcccdc',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1.5px',
    fontFamily: 'Lato',
  },
};

export default typography;
