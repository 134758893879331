import React from 'react';
import { Button, IconButton, Typography, Icon } from '@mui/material';
import { connect } from 'react-redux';
import './ProfileViewVendor.css';

const ProfileViewVendor = ({
  coverPhoto,
  icon,
  onFileChange,
  loggedInUser,
}) => {
  return (
    <>
      <div className='cover-photo-continer-vendor margin-top-10'>
        {coverPhoto ? (
          <>
            <img className='cover-photo-vendor' src={coverPhoto} alt='cover' />
            <input
              accept='image/*'
              className='icon-button-file-input-vendor'
              id='cover-photo-button'
              type='file'
              onChange={(e) => onFileChange(e, 'coverImgUrl')}
            />
            <label htmlFor='cover-photo-button'>
              {loggedInUser && loggedInUser.userRoles.isIndustryReps ? (
                <></>
              ) : (
                <Button
                  size='small'
                  className='chg-cover-photo-vendor'
                  startIcon={<Icon>photo_camera</Icon>}
                  variant='contained'
                  component='span'
                >
                  Edit Cover Photo
                </Button>
              )}
            </label>
          </>
        ) : (
          <div className='add-cover-photo-container-vendor'>
            {loggedInUser && loggedInUser.userRoles.isIndustryReps ? (
              <></>
            ) : (
              <>
                <Typography variant='h6'>
                  Your cover photo will go here
                </Typography>
                <input
                  accept='image/*'
                  className='icon-button-file-input'
                  id='cover-photo-button'
                  type='file'
                  onChange={(e) => onFileChange(e, 'coverImgUrl')}
                />
                <label htmlFor='cover-photo-button'>
                  <Button
                    size='small'
                    className='add-cover-photo-vendor'
                    startIcon={<Icon>photo_camera</Icon>}
                    variant='contained'
                    component='span'
                  >
                    Add Cover Photo
                  </Button>
                </label>
              </>
            )}
          </div>
        )}
      </div>
      <div className='display-container-vendor'>
        <span className='icon-continer-vendor'>
          {icon && <img className='icon-image-vendor' src={icon} alt='icon' />}

          {loggedInUser && loggedInUser.userRoles.isIndustryReps ? (
            <></>
          ) : (
            <>
              <input
                accept='image/*'
                className='icon-button-file-input-vendor'
                id='icon-button-file'
                type='file'
                onChange={(e) => onFileChange(e, 'profileImgUrl')}
              />
              <label
                htmlFor='icon-button-file'
                className={icon ? 'overlay-vendor' : 'overlay-display-vendor'}
              >
                <IconButton
                  className='camera-icon-vendor'
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                >
                  <Icon>photo_camera</Icon>
                </IconButton>
              </label>
            </>
          )}
        </span>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};
export default connect(mapStateToProps, null)(ProfileViewVendor);
