import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Card } from '@mui/material';
import { ProductForm } from '../index';
import { I18n } from 'react-redux-i18n';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {
  updateProductRequest,
  getProductRequest,
} from '../../ProductApiActions';
import './UpdateProduct.css';

/**
 * function to render update product
 * @param {*} updateProduct: function to handle update product
 * @param {*} getProduct: function to get product details
 * @param {*} match: match
 * @param {*} history: history
 * @returns
 */
const UpdateProduct = ({ updateProduct, getProduct, match, history }) => {
  const [product, setProduct] = useState(undefined);
  const productId = match.params.productId;
  const vendorId = match.params.vendorId;

  useEffect(() => {
    getProduct(productId).then((res) => {
      let data = {
        name: res.name,
        overview: res.overview,
        link: res.link,
        description: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(res.description).contentBlocks,
            htmlToDraft(res.description).entityMap
          )
        ),
        profileImgUrl: '',
        mediaUrls: [],
        categoryId: res.CategoryId,
        associatedUsers: [],
        uploadedFiles: [],
      };
      res.associatedUsers.forEach((user) => {
        data.associatedUsers.push(user.id);
      });
      res.mediaUrls.forEach((media) => {
        const fileExtension = media.path.split('.').pop();
        data.uploadedFiles.push({
          type: fileExtension,
          url: media.readSignedURL,
          path: media.path,
          fileName: media.fileName,
          fileType: media.fileType,
        });
      });
      setProduct(data);
    });
    // setProduct(productDetails);
    //eslint-disable-next-line
  }, []);

  /**
   * function to handle update product
   * @param {*} data: data
   */
  const handleUpdateProduct = (data) => {
    if (data.uploadedFiles.length > 0) {
      data.uploadedFiles.forEach((singleFile) => {
        data.mediaUrls.push({
          path: singleFile.path,
          fileName: singleFile.fileName,
          fileType: singleFile.fileType,
        });
      });
    }

    updateProduct(productId, data).then((res) => {
      history.goBack();
    });
  };

  /**
   * function to handle cancel update product
   */
  const onCancelUpdateProduct = () => {
    history.goBack();
  };

  return (
    <Card className='product-card-container'>
      <Grid container spacing={0}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant='h6'
            className='product-dialog-title'
            style={{ marginBottom: '1rem' }}
          >
            {I18n.t('product.add_product.form_title_update_product_label')}
          </Typography>
          <ProductForm
            onCancelUpdateProduct={onCancelUpdateProduct}
            submitFormData={handleUpdateProduct}
            formData={product}
            isUpdate={true}
            vendorId={vendorId}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateProduct: async (productId, product) => {
      try {
        return await dispatch(updateProductRequest(productId, product));
      } catch (error) {
        throw error;
      }
    },
    getProduct: async (productId) => {
      try {
        return await dispatch(getProductRequest(productId));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduct);
