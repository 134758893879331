import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, InputAdornment, Typography, Icon, Box } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { logo } from '../../../../assets';
import { AppLoader } from '../../../../utils/components';
import { I18n } from 'react-redux-i18n';
import {
  ONLY_BLANK_SPACES,
  VALIDATE_PASSWORD,
  IS_PASSWORD_MATCH,
} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { changePasswordRequest } from '../../AuthenticationApiActions';
import './ForceResetPassword.css';

/**
 * function to render forgot password
 * @param {*} loggedInUser: logged in user info
 * @param {*} onPasswordChange: function to handle password change
 * @returns
 */
const ForceResetPassword = ({ loggedInUser, onPasswordChange }) => {
  const [user, setUser] = useState({ password: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    document.title = I18n.t('global.app_name');

    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );
    ValidatorForm.addValidationRule(
      VALIDATE_PASSWORD,
      UtilHelper.validatePassword
    );
    ValidatorForm.addValidationRule(IS_PASSWORD_MATCH, (value) => {
      return UtilHelper.isPasswordMatch(user.password, user.confirmPassword);
    });

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
      ValidatorForm.removeValidationRule(VALIDATE_PASSWORD);
      ValidatorForm.removeValidationRule(IS_PASSWORD_MATCH);
    };
  }, [user]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   */
  const handleChange = (event) => {
    const data = Object.assign({}, user);
    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    const data = Object.assign({}, user);
    data['userId'] = loggedInUser.id;

    try {
      await onPasswordChange(data);
    } catch (error) {}
  };

  if (loggedInUser && !loggedInUser.isSystemGeneratedPassword) {
    return <Redirect to='/' />;
  } else {
    return (
      <Box>
        <Box className='reset-password-form-outer-div'>
          <Box className='reset-password-form-inner-div'>
            <ValidatorForm onSubmit={handleSubmit}>
              <Box className='application-logo-img-div'>
                <img className='application-logo-img' src={logo} alt='logo' />
              </Box>
              <TextValidator
                variant='standard'
                autoFocus
                margin='dense'
                id='password'
                name='password'
                className='reset-passwords-field'
                label={
                  <Typography variant='body2'>
                    {I18n.t('force_reset_password.new_password_label')}
                  </Typography>
                }
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange}
                value={user.password}
                fullWidth
                validators={['required', VALIDATE_PASSWORD]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.valid_password'),
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      className='input-adornment'
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Icon></Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextValidator
                variant='standard'
                margin='dense'
                id='confirmPassword'
                name='confirmPassword'
                className='reset-passwords-field'
                label={
                  <Typography variant='body2'>
                    {I18n.t('force_reset_password.re_enter_password_label')}
                  </Typography>
                }
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={handleChange}
                value={user.confirmPassword}
                fullWidth
                validators={['required', IS_PASSWORD_MATCH]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.password_mismatch'),
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      className='input-adornment'
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <Icon>visibility</Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant='contained'
                type='submit'
                color='secondary'
                className='reset-password-form-button margin-top-10'
              >
                {I18n.t('force_reset_password.set_password_button')}
              </Button>
            </ValidatorForm>
          </Box>
        </Box>
        <AppLoader />
      </Box>
    );
  }
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    onPasswordChange: async (data) => {
      try {
        return await dispatch(changePasswordRequest(data));
        //DONE: Do we need Promise.resolve here?
      } catch (error) {
        //DONE: Do we need Promise.reject here?
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForceResetPassword);
