import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Chip,
  Icon,
  Tooltip,
} from '@mui/material';
import { useStyles } from './styles';
import ReactPlayer from 'react-player/youtube';
import ShowMore from 'react-show-more-button';
import { CommentList } from '../index';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

/**
 * fnction to render whiskey tube card
 * @param {*} video: video
 * @param {*} handleCardEditButtonClick: function to handle edit click
 * @param {*} onArchiveUnarchive: function to handle archive or unarchive
 * @returns
 */
const WhiskeyTubeCard = ({
  video,
  handleCardEditButtonClick,
  onArchiveUnarchive,
}) => {
  const classes = useStyles();

  return (
    <Paper component={Box} p={2} mt={2}>
      <Box>
        <Grid container justifyContent='space-between'>
          <Grid
            item
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box display='inline-block'>
              <Typography classes={{ root: classes.titleText }}>
                {video.title}
              </Typography>
            </Box>
            <Box display='inline-block'>
              {video.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag.name}
                  size='small'
                  variant='outlined'
                  classes={{ root: classes.tagChip }}
                />
              ))}
            </Box>
            <Box display='inline-block'>
              <Typography>
                {I18n.t('whiskey_tube.whiskey_tube_card.vendor_label')}:{' '}
                {video.vendor?.name ? video.vendor?.name : 'NA'}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{ textAlign: 'right' }}
          >
            <Tooltip
              title={I18n.t('whiskey_tube.whiskey_tube_card.edit_tooltip')}
              arrow
              placement='top'
            >
              <Icon
                classes={{ root: classes.actionIcon }}
                onClick={() => handleCardEditButtonClick(video.id)}
              >
                edit
              </Icon>
            </Tooltip>
            <Tooltip
              title={I18n.t('whiskey_tube.whiskey_tube_card.archive_tooltip')}
              arrow
              placement='top'
            >
              <Icon
                classes={{ root: classes.actionIcon }}
                onClick={() =>
                  onArchiveUnarchive(video.id, video.isArchived, video.title)
                }
              >
                archive
              </Icon>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box display='flex' alignItems='center'>
          <Box display='flex' alignItems='center' mr={2}>
            <Typography classes={{ root: classes.viewsText }}>
              {video.views} views
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' mr={2}>
            <Icon>chat_bubble</Icon>{' '}
            <Typography classes={{ root: classes.countText }}>
              {video.comments}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' mr={2}>
            <Icon>share</Icon>{' '}
            <Typography classes={{ root: classes.countText }}>
              {video.shares}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' mr={2}>
            <Icon>favorite</Icon>{' '}
            <Typography classes={{ root: classes.countText }}>
              {video.likes}
            </Typography>
          </Box>

          <Box display='flex' alignItems='center' mr={2}>
            <Icon>bookmark</Icon>{' '}
            <Typography classes={{ root: classes.countText }}>
              {video.saves}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <ReactPlayer
              url={video.youtubeVideoLink}
              controls={true}
              width='100%'
              height='145px'
            />
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <Box>
              <Typography classes={{ root: classes.createdAndUpdatedByText }}>
                {I18n.t('whiskey_tube.whiskey_tube_card.created_by_label')}:{' '}
                <span className={classes.nameAndDate}>
                  {video.createdBy} on{' '}
                  {moment(video.createdAt).format(`DD-MM-YYYY, hh:mm a`)}
                </span>
              </Typography>
              <Typography classes={{ root: classes.createdAndUpdatedByText }}>
                {I18n.t('whiskey_tube.whiskey_tube_card.last_updated_by_label')}
                :{' '}
                <span className={classes.nameAndDate}>
                  {video.updatedBy} on{' '}
                  {moment(video.updatedAt).format(`DD-MM-YYYY, hh:mm a`)}
                </span>
              </Typography>
            </Box>

            <Box>
              <ShowMore
                maxHeight={105}
                backgroundColor='trasparent'
                classNameButton={classes.showMoreLessButton}
                classNameButtonDiv={classes.showMoreLessButtonContainer}
              >
                <div dangerouslySetInnerHTML={{ __html: video.description }} />
              </ShowMore>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <CommentList />
      </Box>
    </Paper>
  );
};

export default WhiskeyTubeCard;
