import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SuperAdminList, VendorAdminList, EndUserList } from '../index';

/**
 * function to render users ui
 * @returns
 */
const Users = () => {
  const [value, setValue] = useState('1');

  /**
   * function to handle tab change
   * @param {*} event: event object
   * @param {*} newTabValue: new tab value
   */
  const handleChange = (event, newTabValue) => {
    setValue(newTabValue);
  };

  /**
   * function to handle render tab panel
   * @param {*} tabValue: tab value
   * @returns
   */
  const renderTabPanel = (tabValue) => {
    switch (tabValue) {
      case '1':
        return <SuperAdminList />;
      case '2':
        return <VendorAdminList />;
      case '3':
        return <EndUserList />;
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            indicatorColor='secondary'
            textColor='primary'
          >
            <Tab label='Super Admins' value='1' />
            {/* <Tab label='Vendor Admins' value='2' /> */}
            <Tab label='Customers' value='3' />
          </TabList>
        </Box>

        <TabPanel value={value} index={value}>
          {renderTabPanel(value)}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Users;
