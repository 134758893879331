import React from 'react';
import { Typography, Tooltip, Icon } from '@mui/material';
import './Preview.css';

const Preview = React.memo(({ media, totalCount, page, handleChange }) => {
  const documentRenderBasedOnType = (type, fileUrl) => {
    type = type.toLowerCase();
    switch (type) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        return (
          <div className='gallery-media-container'>
            <img className='gallery-media' alt='gallery' src={fileUrl} />
            <Tooltip title='Download'>
              <a href={fileUrl} download>
                <Icon className='download-image-file-icon'>
                  get_app_rounded
                </Icon>
              </a>
            </Tooltip>
          </div>
        );
      // break;
      case 'mp4':
        return (
          <video className='gallery-media' controls>
            <source src={fileUrl} type='video/mp4' />
          </video>
        );
      // break;
      case 'pdf':
        return (
          <div className='grid-download-icon-label'>
            <div class='download-container'>
              <a href={fileUrl} download>
                <Icon className='download-icon'>get_app_rounded</Icon>
                <Typography>Download File</Typography>
              </a>
            </div>
          </div>
        );
      // break;
      default:
        return (
          <div className='grid-download-icon-label'>
            <div class='download-container'>
              <a href={fileUrl} download>
                <Icon className='download-icon'>get_app_rounded</Icon>
                <Typography>Download File</Typography>
              </a>
            </div>
          </div>
        );
      // break;
      // code block
    }
  };

  return (
    <>
      {page !== 1 ? (
        <Icon
          className='left-click-button'
          onClick={() => handleChange(page - 1)}
        >
          chevron_left_rounded
        </Icon>
      ) : (
        <></>
      )}

      {documentRenderBasedOnType(media.type, media.url)}
      {/* <video className="gallery-Image" controls>
                    <source src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"type="video/mp4"/>
                </video> */}

      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                    <div style={{ height: '500px',}}>
                    <Viewer fileUrl={Dummy} plugins={[defaultLayoutPluginInstance]} />
                    </div>
                </Worker> */}

      {page < totalCount ? (
        <Icon
          className='right-click-button'
          onClick={() => handleChange(page + 1)}
        >
          chevron_right_rounded
        </Icon>
      ) : (
        <></>
      )}
    </>
  );
});

export default Preview;
