/**
 * spirit list
 */
export const spiritListData = {
  count: 100,
  rows: [
    {
      id: '1',
      enabled: false,
      name: 'The Sinister Guard',
      cost: '85',
      mediaUrl:
        'https://i.picsum.photos/id/1002/4312/2868.jpg?hmac=5LlLE-NY9oMnmIQp7ms6IfdvSUQOzP_O3DPMWmyNxwo',
      recommendedWeather: {
        Sunny: true,
        Cloudy: false,
        Rainy: false,
        Drizzle: false,
        Snowy: true,
      },
      recommendedTiming: {
        Morning: true,
        Afternoon: true,
        Evening: false,
        Night: true,
      },
      recommendedCuisines: {
        Chinese: true,
        French: true,
        Italian: false,
        Indian: false,
        Japanese: false,
        Moroccan: true,
        Spanish: true,
        Thai: true,
        Turkish: false,
        Indonesian: false,
        Mexican: false,
        Greek: false,
        Malaysian: true,
      },
      recommendedForMood: {
        Cheerful: true,
        Reflective: true,
        Gloomy: false,
        Humorous: false,
        Melancholy: false,
        Idyllic: true,
        Whimsical: true,
        Romantic: true,
        Calm: false,
        Lonely: false,
      },
    },
    {
      id: '2',
      enabled: false,
      name: 'The Vicious Baron',
      cost: '102',
      mediaUrl:
        'https://i.picsum.photos/id/1000/5626/3635.jpg?hmac=qWh065Fr_M8Oa3sNsdDL8ngWXv2Jb-EE49ZIn6c0P-g',
      recommendedWeather: {
        Sunny: true,
        Cloudy: false,
        Rainy: true,
        Drizzle: false,
        Snowy: true,
      },
      recommendedTiming: {
        Morning: true,
        Afternoon: true,
        Evening: false,
        Night: false,
      },
      recommendedCuisines: {
        Chinese: true,
        French: true,
        Italian: false,
        Indian: false,
        Japanese: false,
        Moroccan: true,
        Spanish: true,
        Thai: true,
        Turkish: false,
        Indonesian: false,
        Mexican: false,
        Greek: false,
        Malaysian: true,
      },
      recommendedForMood: {
        Cheerful: true,
        Reflective: true,
        Gloomy: false,
        Humorous: false,
        Melancholy: false,
        Idyllic: true,
        Whimsical: true,
        Romantic: true,
        Calm: false,
        Lonely: false,
      },
    },
    {
      id: '3',
      enabled: true,
      name: 'The Hostile Visitor',
      cost: '79',
      mediaUrl:
        'https://i.picsum.photos/id/102/4320/3240.jpg?hmac=ico2KysoswVG8E8r550V_afIWN963F6ygTVrqHeHeRc',
      recommendedWeather: {
        Sunny: true,
        Cloudy: false,
        Rainy: false,
        Drizzle: false,
        Snowy: true,
      },
      recommendedTiming: {
        Morning: true,
        Afternoon: true,
        Evening: false,
        Night: false,
      },
      recommendedCuisines: {
        Chinese: true,
        French: true,
        Italian: false,
        Indian: false,
        Japanese: false,
        Moroccan: true,
        Spanish: true,
        Thai: true,
        Turkish: false,
        Indonesian: false,
        Mexican: false,
        Greek: false,
        Malaysian: true,
      },
      recommendedForMood: {
        Cheerful: true,
        Reflective: true,
        Gloomy: false,
        Humorous: false,
        Melancholy: false,
        Idyllic: true,
        Whimsical: true,
        Romantic: true,
        Calm: false,
        Lonely: false,
      },
    },
  ],
};
