import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Typography,
  Paper,
  TableContainer,
  Box,
  Icon,
} from '@mui/material';
import { I18n } from 'react-redux-i18n';
import Constants from '../../../../utils/Constants';
import { CustomTable } from '../../../../utils/components';
import {
  MultiselectFilter,
  NumberRangeFilter,
  DateRangeFilter,
} from '../../../../utils/components/Filter';
import { productListData, vendorOptions } from '../../data';
import UtilHelper from '../../../../utils/UtilHelper';
import { getEventsRequest } from '../../EventsApiActions';
import { useStyles } from './styles';
import moment from 'moment';

/**
 * function to render event list list
 * @param {*} history: history object
 * @param {*} getEvents: function to get events
 * @param {*} routeFrom: string value to manage dynamic route
 * @returns
 */
const EventList = ({ history, getEvents, routeFrom }) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      vendors: [],
      date: null,
      tickets: null,
      cost: null,
      sold: null,
    },
  });
  const [productList, setProductList] = useState(null);

  /**
   * function to get event list
   * @param {*} requestData: request data object
   */
  const getEventList = async (requestData) => {
    /**
     * demo data for UI
     */
    setProductList(productListData);

    try {
      const data = getEvents(requestData);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    getEventList(paginationParams);
    //eslint-disable-next-line
  }, [paginationParams]);

  const columns = [
    {
      title: I18n.t('event.event_table.table_column_name_label'),
      field: 'name',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ id, name }) => {
        return (
          <Box
            component={Link}
            to={`${match?.url}${routeFrom ? routeFrom : ''}/${id}`}
          >
            <Typography variant='body2'>
              {UtilHelper.ellipsisRender(name, 30)}
            </Typography>
          </Box>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_address_label'),
      field: 'address',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ address }) => {
        return (
          <Typography variant='body2'>
            {UtilHelper.ellipsisRender(address, 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_vendor_label'),
      field: 'vendor',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ vendor }) => {
        return (
          <Typography variant='body2'>
            {UtilHelper.ellipsisRender(vendor, 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_date_label'),
      field: 'startTime',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ startTime, endTime }) => {
        return (
          <>
            <Typography variant='body2'>
              {moment(startTime).format('DD-MM-YYYY')}
            </Typography>
            <Typography variant='body2'>
              {moment(startTime).format('hh A')} -{' '}
              {moment(endTime).format('hh A')}
            </Typography>
          </>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_contact_label'),
      field: 'contactName',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ contactName, contactPhone }) => {
        return (
          <>
            <Typography variant='body2'>{contactName}</Typography>
            <Typography variant='body2'>{contactPhone}</Typography>
          </>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_tickets_label'),
      field: 'tickets',
      width: '7%',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({ tickets }) => {
        return <Typography variant='body2'>{tickets}</Typography>;
      },
    },
    {
      title: I18n.t('event.event_table.table_column_cost_label'),
      field: 'cost',
      width: '7%',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({ cost }) => {
        return <Typography variant='body2'>{cost}</Typography>;
      },
    },
    {
      title: I18n.t('event.event_table.table_column_sold_label'),
      field: 'sold',
      width: '7%',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({ sold }) => {
        return <Typography variant='body2'>{sold}</Typography>;
      },
    },
  ];

  /**
   * function to handle add event
   */
  const onAddEvent = () => {
    const url = `${match?.url}${routeFrom ? routeFrom : ''}/add`;
    history.push(url);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={1}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          color='#fff'
        >
          <Grid
            container
            spacing={1}
            classes={{ container: classes.customGridContainer }}
          >
            <Grid item classes={{ item: classes.customGridItem }}>
              <MultiselectFilter
                arrayList={vendorOptions}
                filterName={I18n.t(
                  'event.event_table.table_filter_vendor_label'
                )}
                title={I18n.t('event.event_table.table_filter_vendor_label')}
                filterOn='name'
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.vendors}
                onChangeFilter={(value) => handleChangeFilter('vendors', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <DateRangeFilter
                filterName={I18n.t('event.event_table.table_filter_date_label')}
                title={I18n.t('event.event_table.table_filter_date_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.date}
                onChangeFilter={(value) => handleChangeFilter('date', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'event.event_table.table_filter_ticket_label'
                )}
                title={I18n.t('event.event_table.table_filter_ticket_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.tickets}
                onChangeFilter={(value) => handleChangeFilter('tickets', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t('event.event_table.table_filter_cost_label')}
                title={I18n.t('event.event_table.table_filter_cost_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.cost}
                onChangeFilter={(value) => handleChangeFilter('cost', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t('event.event_table.table_filter_sold_label')}
                title={I18n.t('event.event_table.table_filter_sold_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.sold}
                onChangeFilter={(value) => handleChangeFilter('sold', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant='contained'
              startIcon={<Icon>add_circle</Icon>}
              color='secondary'
              onClick={() => onAddEvent()}
            >
              {I18n.t('event.event_table.add_event_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableContainer>
      {renderTableTitle()}

      <CustomTable
        columns={columns}
        data={productList?.rows}
        hidePaging={false}
        hideBorderShadow={true}
        rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
        rowsPerPage={paginationParams.rowsPerPage}
        count={productList?.count}
        page={paginationParams.page}
        onPageChange={(page) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = page;
          setPaginationParams(pagination);
        }}
        onRowsPerPageChange={(rowsPerPage) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = 0;
          pagination.rowsPerPage = rowsPerPage;
          setPaginationParams(pagination);
        }}
        // columns={columns}
        // data={fileList ? fileList.csv_list : []}
        // pages={10}
        // pages={Math.ceil(allUsers.count / paginationParams.rowsPerPage)}
        // pages={fileList ? fileList.max_page_count : 0}
        // pageSize={10}
        // pageSize={paginationParams.rowPerPage}
        // totalCount={fileList ? fileList.max_record_count : 0}
        // page={paginationParams.page}
      />
    </TableContainer>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getEvents: async (data) => {
      try {
        return await dispatch(getEventsRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
