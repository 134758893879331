import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ROUTE_ACTIONS } from '../../utils/Constants';
import { UnAuthorized } from '../../utils/components';
import { ChangePassword } from '../Authentication/components';
import { Users } from '../UserManagement/components';
import { WhiskeyTubeList } from '../WhiskeyTube/components';
import { VendorList, VendorDetails } from '../Vendors/components';
import {
  ProductList,
  ProductDetails,
  AddProduct,
  UpdateProduct,
} from '../Product/components';
import { EventList, EventForm, EventDetails } from '../Events/components';
import { SurveyList, SurveyForm, SurveyDetails } from '../Survey/components';
import { SpiritList } from '../Spirits/components';

/**
 * function to render dashboard routes based on user role
 * @param {*} loggedInUser: logged in user info
 * @param {*} menuName: nenu name
 * @returns
 */
const DasboardRoutes = ({ loggedInUser, menuName }) => {
  return (
    <>
      {loggedInUser && loggedInUser.userRoles.isSystemSuperAdmin && (
        <Switch>
          <Route
            exact
            path={ROUTE_ACTIONS.CHANGE_PASSWORD}
            render={(props) => {
              return <ChangePassword {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.DASHBOARD}
            render={(props) => {
              return <Users {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS}
            render={(props) => {
              return <VendorList {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS_PRODUCTS_PRODUCTS_ADD}
            render={(props) => {
              return <AddProduct {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS_PRODUCTS_PRODUCTS_UPDATE}
            render={(props) => {
              return <UpdateProduct {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS_PRODUCTS_PRODUCTS_DETAILS}
            render={(props) => {
              return <ProductDetails {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS_EVENTS_EVENTS_ADD}
            render={(props) => {
              return <EventForm {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS_EVENTS_EVENTS_UPDATE}
            render={(props) => {
              return <EventForm {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS_EVENTS_EVENTS_DETAILS}
            render={(props) => {
              return <EventDetails {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.VENDORS_DETAILS}
            render={(props) => {
              return <VendorDetails {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.PRODUCTS}
            render={(props) => {
              return <ProductList {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.PRODUCTS_ADD}
            render={(props) => {
              return <AddProduct {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.PRODUCTS_UPDATE}
            render={(props) => {
              return <UpdateProduct {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.PRODUCTS_DETAILS}
            render={(props) => {
              return <ProductDetails {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.WHISKEY_TUBE}
            render={(props) => {
              return <WhiskeyTubeList {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.EVENTS}
            render={(props) => {
              return <EventList {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.EVENTS_ADD}
            render={(props) => {
              return <EventForm {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.EVENTS_UPDATE}
            render={(props) => {
              return <EventForm {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.EVENTS_DETAILS}
            render={(props) => {
              return <EventDetails {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.SURVEYS}
            render={(props) => {
              return <SurveyList {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.SURVEYS_ADD}
            render={(props) => {
              return <SurveyForm {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.SURVEYS_UPDATE}
            render={(props) => {
              return <SurveyForm {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.SURVEYS_DETAILS}
            render={(props) => {
              return <SurveyDetails {...props} menuName={menuName} />;
            }}
          />

          <Route
            exact
            path={ROUTE_ACTIONS.SPIRITS}
            render={(props) => {
              return <SpiritList {...props} menuName={menuName} />;
            }}
          />

          <Route component={UnAuthorized} />
        </Switch>
      )}
    </>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DasboardRoutes);
