/**
 * vendor options
 */
export const vendorOptions = [
  {
    id: 1,
    name: 'Vendor 1',
  },
  {
    id: 2,
    name: 'Vendor 2',
  },
  {
    id: 3,
    name: 'Vendor 3',
  },
];

/**
 * tag options
 */
export const tagOptions = [
  {
    id: 1,
    name: 'Tag 1',
  },
  {
    id: 2,
    name: 'Tag 2',
  },
  {
    id: 3,
    name: 'Tag 3',
  },
  {
    id: 4,
    name: 'Tag 4',
  },
];

/**
 * whiskey tube list
 */
export const whiskeyTubeListData = {
  count: 100,
  rows: [
    {
      id: '1',
      isArchived: false,
      title: 'How Jack Daniels Whiskey is Made?',
      tags: [
        {
          id: '1',
          name: 'Tag 1',
        },
        {
          id: '2',
          name: 'Tag 2',
        },
        {
          id: '3',
          name: 'Tag 3',
        },
        {
          id: '4',
          name: 'Tag 4',
        },
      ],
      vendor: {
        id: '1',
        name: 'Vendor 1',
      },
      views: '1014910',
      comments: '33',
      shares: '56',
      likes: '12',
      saves: '33',
      youtubeVideoLink: 'https://www.youtube.com/watch?v=nezwMMDauaI',
      createdAt: '2021-10-13T06:07:54.144Z',
      createdBy: 'Harrison Turner',
      updatedAt: '2021-10-13T06:07:54.144Z',
      updatedBy: 'Oscar Russell',
      description: `<h2 style="text-align:left;"><span style="font-size: 24px;font-family: DauphinPlain;">What is Lorem Ipsum?</span></h2><p style="text-align:justify;"><span style="font-size: 14px;font-family: Open Sans", Arial, sans-serif;"><strong>Lorem Ipsum</strong></span> <span style="font-size: 14px;font-family: Open Sans", Arial, sans-serif;">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span></p>`,
    },
    {
      id: '2',
      isArchived: false,
      title: 'Jack Daniels Ad',
      tags: [
        {
          id: '1',
          name: 'Tag 1',
        },
        {
          id: '2',
          name: 'Tag 2',
        },
      ],
      vendor: null,
      views: '250',
      comments: '6',
      shares: '2',
      likes: '4',
      saves: '20',
      youtubeVideoLink: 'https://www.youtube.com/watch?v=7t5gm5uA-RE',
      createdAt: '2021-10-13T06:07:54.144Z',
      createdBy: 'Harrison Turner',
      updatedAt: '2021-10-13T06:07:54.144Z',
      updatedBy: 'Oscar Russell',
      description:
        '<p><strong>Check it out</strong></p><p><strong><em>latest one</em></strong></p>',
    },
    {
      id: '3',
      isArchived: true,
      title: 'Jack Daniels Ad',
      tags: [
        {
          id: '1',
          name: 'Tag 1',
        },
        {
          id: '2',
          name: 'Tag 2',
        },
      ],
      vendor: null,
      views: '250',
      comments: '6',
      shares: '2',
      likes: '4',
      saves: '20',
      youtubeVideoLink: 'https://www.youtube.com/watch?v=7t5gm5uA-RE',
      createdAt: '2021-10-13T06:07:54.144Z',
      createdBy: 'Harrison Turner',
      updatedAt: '2021-10-13T06:07:54.144Z',
      updatedBy: 'Oscar Russell',
      description: `<p><strong>My Video</strong></p><p></p><p><strong><em>Check out the video</em></strong></p><p><strong>😀 Very important one</strong></p><p style="text-align:center;"><strong>Thank you!</strong></p>`,
    },
  ],
};
