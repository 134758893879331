import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControlLabel,
  Button,
  Typography,
  Popper,
  Paper,
  Fade,
  RadioGroup,
  Radio,
  ClickAwayListener,
  Icon,
} from '@mui/material';
import { useStyles } from './styles';

const RadioButtonFilter = React.memo(
  ({
    arrayList,
    filterName,
    title,
    saveButtonText,
    clearButtonText,
    onChangeFilter,
    value,
  }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    useEffect(() => {
      setSelectedValue(value);
    }, [value]);

    const handleChange = (event) => {
      let val = '';
      switch (event.target.value) {
        case 'true':
          val = true;
          break;
        case 'false':
          val = false;
          break;
        default:
          val = event.target.value;
      }

      setSelectedValue(val);
    };

    const onApplyFilter = () => {
      onChangeFilter(selectedValue);
      setAnchorEl(null);
      setOpen(false);
    };

    const onClearFilter = (event) => {
      if (event) {
        event.stopPropagation();
      } else {
      }
      onChangeFilter('');
      setAnchorEl(null);
      setOpen(false);
    };

    const clickAwayHandler = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    return (
      <>
        {open && (
          <ClickAwayListener onClickAway={() => clickAwayHandler()}>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement='bottom-start'
              transition
              className={classes.popperContainer}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Paper classes={{ root: classes.paperRoot }}>
                    <Typography variant='h6'>{title}</Typography>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ marginTop: '12px' }}
                      >
                        <RadioGroup
                          row
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          {arrayList.map((item, index) => (
                            <Grid
                              item
                              xl={4}
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              key={index}
                            >
                              <FormControlLabel
                                value={item.value}
                                control={<Radio color='secondary' />}
                                label={item.label}
                              />
                            </Grid>
                          ))}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        classes={{ item: classes.actionsGrid }}
                      >
                        <Button
                          variant='text'
                          color='inherit'
                          onClick={() => onClearFilter()}
                        >
                          {clearButtonText}
                        </Button>

                        <Button
                          classes={{ root: classes.applyButton }}
                          variant='contained'
                          color='secondary'
                          onClick={() => onApplyFilter()}
                        >
                          {saveButtonText}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}

        <Button
          size='small'
          variant={value !== '' ? 'contained' : 'outlined'}
          color={value !== '' ? 'secondary' : 'inherit'}
          onClick={handleClickMenu}
        >
          {filterName}
          {value !== '' && (
            <Icon onClick={(e) => onClearFilter(e)}>close_rounded</Icon>
          )}
        </Button>
      </>
    );
  }
);

export default RadioButtonFilter;
