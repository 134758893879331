import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Grid,
  Card,
  Typography,
  Tooltip,
  Tab,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Icon,
  Box,
} from '@mui/material';
import {
  getVendorRequest,
  activateDeactivateVendorRequest,
  publishUnpublishVendorRequest,
  getSignedUrlRequest,
  uploadToS3Request,
  updateVendorRequest,
} from '../../VendorApiActions';
import { UpdateVendor } from '../index';
import { ProfileViewVendor, MessageModal } from '../../../../utils/components';
import UtilHelper from '../../../../utils/UtilHelper';
import { showSnackbar } from '../../../../utils/components/AppLoader/AppLoaderActions';
import { SNACKBAR_VARIAINT } from '../../../../utils/Constants';
import { ProductList } from '../../../Product/components';
import { EventList } from '../../../Events/components';
import './VendorDetails.css';

/**
 * function to render vendor detailed view
 * @param {*} history: history object
 * @param {*} match: match object
 * @param {*} getVendorDetails: function to get vendor details
 * @param {*} activateDeactivateVendor: function to activate deactivate vendor
 * @param {*} getSignedUrl: function to get signed url
 * @param {*} uploadToS3: function to upload to signed url
 * @param {*} updateVendor: function to update vendor
 * @param {*} showSnackbarMessage: function to show snackbar message
 * @returns
 */
const VendorDetails = ({
  history,
  match,
  getVendorDetails,
  activateDeactivateVendor,
  getSignedUrl,
  uploadToS3,
  updateVendor,
  showSnackbarMessage,
}) => {
  const vendorId = match.params.vendorId;
  const [vendor, setVendor] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [value, setValue] = useState('1');
  const modalRef = useRef();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * function to handle tab change
   * @param {*} event: event object
   * @param {*} newTabValue: new tab value
   */
  const handleChange = (event, newTabValue) => {
    setValue(newTabValue);
  };

  /**
   * function to render tab content
   * @param {*} tabValue: tab value
   * @returns
   */
  const renderTabPanel = (tabValue) => {
    switch (tabValue) {
      case '1':
        return <ProductList history={history} routeFrom='/products' />;
      case '2':
        return 'Survey';
      case '3':
        return <EventList history={history} routeFrom='/events' />;
      default:
        return <></>;
    }
  };

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to close menu
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  /**
   * function to get vendor details
   * @param {*} id: id
   */
  const getVendorDetailsById = (id) => {
    getVendorDetails(id).then((res) => {
      setVendor(res);
    });
  };

  useEffect(() => {
    setVendor({
      id: '1',
      name: 'Vendor 1',
      coverImgUrl:
        'https://ui-avatars.com/api/?name=Super+Admin&background=random',
      enabled: true,
      profileImgUrl:
        'https://ui-avatars.com/api/?name=Super+Admin&background=random',
      published: true,
      title: 'Title',
      description: 'Description',
    });

    getVendorDetailsById(vendorId);
    //eslint-disable-next-line
  }, []);

  /**
   * function to close form dialog
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
    getVendorDetailsById(vendorId);
  };

  /**
   * function to activate deactivate vendor
   * @param {*} vendorId: vendor id
   * @param {*} value: value
   */
  const onActivateDeactivate = (vendorId, value) => {
    const title = `${value ? 'Disable' : 'Enable'} Vendor`;
    const message = `Are you sure you want to ${value ? 'disable' : 'enable'} ${
      vendor.name
    }?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        const data = {
          // note: note,
          enabled: !value,
        };
        activateDeactivateVendor(vendorId, data).then((res) => {
          getVendorDetailsById(vendorId);
        });
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle file change
   * @param {*} event: event object
   * @param {*} elementName: element name
   */
  const onFileChange = (event, elementName) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const extensionList =
      process.env.REACT_APP_PROFILE_IMAGE_SUPPORTED.split(',');
    if (UtilHelper.checkAllowedExtension(fileName, extensionList)) {
      const data = {
        fileName: fileName,
        fileType: '.' + fileExtension,
      };
      getSignedUrl(data).then((res) => {
        uploadToS3(res.urls, file).then((response) => {
          const pathData = {};
          if (elementName === 'profileImgUrl') {
            pathData.profileImgUrl = res.path;
          } else {
            pathData.coverImgUrl = res.path;
          }
          updateVendor(vendorId, pathData).then((res) => {
            let reader = new FileReader();
            let tempUrl = '';
            reader.onload = function (e) {
              const data = Object.assign({}, vendor);
              tempUrl = e.target.result;
              if (elementName === 'profileImgUrl') {
                data.profileImgUrl = tempUrl;
              } else {
                data.coverImgUrl = tempUrl;
              }
              setVendor(data);
            };
            reader.readAsDataURL(file);
          });
        });
      });
    } else {
      showSnackbarMessage(
        I18n.t('error_messages.invalid_file_type'),
        SNACKBAR_VARIAINT.ERROR
      );
    }
  };

  /**
   * function to handle edit click
   */
  const onEditVendor = () => {
    setOpenDialog(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card className='vendor-account-card-container'>
          <Tooltip title='Back'>
            <Icon
              className='commuity-back-arrow-icon'
              onClick={() => history.goBack()}
            >
              arrow_back_rounded
            </Icon>
          </Tooltip>

          <ProfileViewVendor
            coverPhoto={vendor.coverImgUrl}
            icon={vendor.profileImgUrl}
            onFileChange={onFileChange}
            // enabled={vendor.enabled}
            // onEditCommunity={onEditVendor}
            // published={vendor.published}
            // onPublishUnpublish={() =>
            //   onPublishUnpublish(vendor.id, vendor.published)
            // }
            // onActivateDeactivate={() =>
            //   onActivateDeactivate(vendor.id, vendor.enabled, vendor.name)
            // }
          />
          <Grid container spacing={0}>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}></Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              {vendor && !vendor.enabled ? (
                <Button
                  startIcon={<Icon>check_circle_rounded</Icon>}
                  className='vendor-account-publish-unpublish-icon'
                  variant='contained'
                  size='small'
                  color='secondary'
                >
                  {I18n.t('vendor.vendor_details.vendor_enable_tooltip_title')}
                </Button>
              ) : (
                <Button
                  startIcon={<Icon>check_circle_rounded</Icon>}
                  className='vendor-account-published-icon disable-btn'
                  variant='contained'
                  size='small'
                  color='secondary'
                >
                  {I18n.t('vendor.vendor_details.vendor_disable_tooltip_title')}
                </Button>
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid container spacing={0}>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant='body1'>
                    <Tooltip title='Visiters'>
                      <span className='stat-icon'>
                        <Icon className='margin-rigth-5 stat-icon-one'>
                          assignment_rounded
                        </Icon>{' '}
                        {4}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant='body1'>
                    <Tooltip title='Visiters'>
                      <span className='stat-icon'>
                        <Icon className='margin-rigth-5 stat-icon-one'>
                          assignment_rounded
                        </Icon>{' '}
                        {4}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant='body1'>
                    <Tooltip title='Mentions'>
                      <span className='stat-icon'>
                        <Icon className='margin-rigth-5 stat-icon-two'>
                          group_rounded
                        </Icon>{' '}
                        {9}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant='body1'>
                    <Tooltip title='Products'>
                      <span className='stat-icon'>
                        <Icon className='margin-rigth-5 stat-icon-three'>
                          star_rounded
                        </Icon>{' '}
                        {21}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Tooltip
                title={I18n.t(
                  'vendor.vendor_details.vendor_actions_tooltip_title'
                )}
              >
                <Button
                  variant='outlined'
                  size='small'
                  color='inherit'
                  className='edit-vendor-icon'
                  onClick={(event) => handleMenuClick(event)}
                >
                  <Icon>more_horiz</Icon>
                </Button>
              </Tooltip>

              <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={`customized-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={anchorEl}
                onClose={() => {
                  handleMenuClose();
                }}
              >
                <MenuItem
                  onClick={() => {
                    onEditVendor();
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <Icon fontSize='small'>edit_rounded</Icon>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={I18n.t(
                      'vendor.vendor_details.vendor_edit_tooltip_title'
                    )}
                  />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onActivateDeactivate(
                      vendor.id,
                      vendor.enabled,
                      vendor.name
                    );
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    {vendor.enabled ? (
                      <Icon fontSize='small'>lock_open_rounded</Icon>
                    ) : (
                      <Icon fontSize='small'>lock_rounded</Icon>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={vendor.enabled ? 'Disable' : 'Enable'}
                  />
                </MenuItem>
              </Menu>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className='margin-top-10'
            >
              <Typography variant='h5' className={`vendor-name`}>
                {vendor ? UtilHelper.ellipsisRender(vendor.name, 150) : ''}
              </Typography>
              <Typography variant='h6' className='vendor-title'>
                {vendor ? UtilHelper.ellipsisRender(vendor.title, 150) : ''}
              </Typography>
              <Typography variant='body2' className='vendor-description'>
                {vendor
                  ? UtilHelper.ellipsisRender(vendor.description, 250)
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                indicatorColor='secondary'
                textColor='primary'
              >
                <Tab
                  label={I18n.t('vendor.vendor_details.tab_products_label')}
                  value='1'
                />
                <Tab
                  label={I18n.t('vendor.vendor_details.tab_survey_label')}
                  value='2'
                />
                <Tab
                  label={I18n.t('vendor.vendor_details.tab_events_label')}
                  value='3'
                />
              </TabList>
            </Box>

            <TabPanel value={value} index={value}>
              {renderTabPanel(value)}
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>

      {openDialog && (
        <UpdateVendor
          openDialog={openDialog}
          vendorId={vendorId}
          handleCloseDialog={handleCloseDialog}
        />
      )}

      <MessageModal ref={modalRef} />
    </Grid>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorDetails: async (vendorId) => {
      try {
        return await dispatch(getVendorRequest(vendorId));
      } catch (error) {
        throw error;
      }
    },

    activateDeactivateVendor: async (vendorId, data) => {
      try {
        return await dispatch(activateDeactivateVendorRequest(vendorId, data));
      } catch (error) {
        throw error;
      }
    },

    publishUnpublishVendor: async (vendorId, data) => {
      try {
        return await dispatch(
          dispatch(publishUnpublishVendorRequest(vendorId, data))
        );
      } catch (error) {
        throw error;
      }
    },

    getSignedUrl: async (data) => {
      try {
        return await dispatch(dispatch(getSignedUrlRequest(data)));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },

    updateVendor: async (vendorId, data) => {
      try {
        return await dispatch(updateVendorRequest(vendorId, data));
      } catch (error) {
        throw error;
      }
    },

    showSnackbarMessage: (message, varient) => {
      dispatch(showSnackbar(message, varient));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails);
