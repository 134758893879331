exports.API_ACTIONS = {
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  FORGOT_PASSWORD: '/auth/forgotpassword',
  RESET_PASSWORD: '/auth/resetPassword',
  SET_PASSWORD: '/auth/setpassword',
  CHANGE_PASSWORD: '/auth/changepassword',
  RESOURCES: '/auth/resources',
  ROLES_PERMISSIONS: '/auth/roles/permissions',
  ROLES: '/auth/roles',
  RESEND_INVITATION: '/auth/resendInvitation',

  USERS: '/users',
  CATEGORY: '/category',
  PRODUCT: '/product',
  COMMUNITY: '/community',
  USER_INVITE: '/users/invite',
  VENDORS: '/vendor',
  COMMUNITY_CATEGORY: '/communityCategory',
  TAGS: '/tag',
  DIAGNOSIS: '/tag',
  SPECIALITY: '/tag',
  DOMAINS: '/tag',
  PROCEDURES: '/tag',
  NOTIFICATIONS: '/nes/notifications',
  ROOM: '/nes/room',
  ROOM_MESSAGES: '/nes/messages',
  APPROACHES: '/tag',
  ACTUAL_STEPS: '/tag',
  POST: '/post',
  GET_PART_UPLOAD_URL: '/post/multipart/getPartUploadUrl',
  COMPLETE_MULTIPART_UPLOAD: '/post/multipart/completeUpload',
  SINGLE_POST: '/post',
  USER_POSTS_BY_ID: '/post/user',
  SINGLE_POST_COMMENTS: '/comment',
  UPVOTE_DOWNVOTE: '/comment',
  COMMENT: '/comment',
  REPORT_COMMENT: '/comment',
  USER_SAVED_POSTS: '/post/saved',
  EDIT_COMMENT: '/comment',
  ALL_REPORTED_POST: '/post/reported',
  ALL_REPORTED_COMMENTS: '/comment/reported',
  DELETE_REPORTED_POST: '/post',
  DELETE_REPORTED_COMMENT: '/comment',
  RESTORE_POSTS: '/post',
  RESTORE_COMMENTS: '/comment',
};

exports.ROUTE_ACTIONS = {
  // auth-routes
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgetPassword',
  RESET_PASSWORD: '/resetPassword/:userId/:token',
  FORCE_RESET_PASSWORD: '/forceResetPassword',
  CHANGE_PASSWORD: '/changePassword',

  // home-route
  HOME: '/',

  // users-route
  DASHBOARD: '/users',
  USERS: '/users',

  //vendors-route
  VENDORS: '/vendors',
  // vendor-products-routes
  VENDORS_PRODUCTS_PRODUCTS_ADD: '/vendors/:vendorId/products/add',
  VENDORS_PRODUCTS_PRODUCTS_UPDATE:
    '/vendors/:vendorId/products/:productId/update',
  VENDORS_PRODUCTS_PRODUCTS_DETAILS: '/vendors/:vendorId/products/:productId',
  // vendor-events-routes
  VENDORS_EVENTS_EVENTS_ADD: '/vendors/:vendorId/events/add',
  VENDORS_EVENTS_EVENTS_UPDATE: '/vendors/:vendorId/events/:eventId/update',
  VENDORS_EVENTS_EVENTS_DETAILS: '/vendors/:vendorId/events/:eventId',
  //vendor-details-at-the-end
  VENDORS_DETAILS: '/vendors/:vendorId',

  // whiskey-tube-routes
  WHISKEY_TUBE: '/whiskeyTube',

  // products-route
  PRODUCTS: '/products',
  PRODUCTS_ADD: '/products/add',
  PRODUCTS_UPDATE: '/products/:productId/update',
  PRODUCTS_DETAILS: '/products/:productId',

  // events-route
  EVENTS: '/events',
  EVENTS_ADD: '/events/add',
  EVENTS_UPDATE: '/events/:eventId/update',
  EVENTS_DETAILS: '/events/:eventId',

  // surveys-route
  SURVEYS: '/surveys',
  SURVEYS_ADD: '/surveys/add',
  SURVEYS_UPDATE: '/surveys/:surveyId/update',
  SURVEYS_DETAILS: '/surveys/:surveyId',

  // spirits-route
  SPIRITS: '/spirits',
};

exports.ONLY_BLANK_SPACES = 'onlyBlankSpaces';
exports.VALIDATE_PASSWORD = 'validatePassword';
exports.IS_PASSWORD_MATCH = 'isPasswordMatch';

exports.ONLY_CHARACTER_ACCEPT = 'onlyCharacterAccept';
exports.BLOCK_SPECIAL_CHARACTER = 'blockSpecialCharacter';
exports.VALID_EMAIL_ADDRESS = 'validEmailAddress';
exports.DECIMAL_INPUT = 'onlyDecimalInput';

exports.VALIDATIONS = {
  MAXIMUM_CHARACTERS_TEXT_FIELD: 30,
  MAXIMUM_CHARACTERS_TEXT_AREA: 255,
  MAXIMUM_CHARACTERS_TEXT_500: 500,
  MAXIMUM_CHARACTERS_TEXT_200: 200,
  MAXIMUM_CHARACTERS_TEXT_300: 300,
  MAXIMUM_CHARACTERS_TEXT_120: 120,
  MAXIMUM_CHARACTERS_TEXT_100: 100,
  MAXIMUM_CHARACTERS_TEXT_50: 50,
  MAXIMUM_CHARACTERS_TEXT_18: 18,
  MAXIMUM_CHARACTERS_ZIP_CODE: 9,
  MINIMUM_CHARACTERS_ZIP_CODE: 4,
  MINIMUM_CHARACTERS_OTP_FIELD: 6,
  MAXIMUM_CHARACTERS_OTP_FIELD: 6,

  MAXIMUM_FLOAT_VALUE: 12554190.0,
};

exports.ROW_PER_PAGE_10 = 10;
exports.ROW_PER_PAGE_20 = 20;
exports.ROW_PER_PAGE_5 = 5;
exports.POST_TITLE_LENGTH_VALIDATION_280 = 280;
exports.PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];

exports.SNACKBAR_VARIAINT = {
  SUCCESS: 'success',
  ERROR: 'error',
};

exports.DRAWER_MENU_WIDTH = 250;

exports.ONLY_CHARACTER_ACCEPT = 'onlyCharacterAccept';
exports.ONLY_BLANK_SPACES = 'onlyBlankSpaces';
exports.VALID_EMAIL_ADDRESS = 'validEmailAddress';
exports.PASSWORD_VALIDATION = 'validPassword';

exports.SIDE_MENU_SA = [
  { label: 'side_menu.user_management', action: this.ROUTE_ACTIONS.USERS },
  { label: 'side_menu.vendor_management', action: this.ROUTE_ACTIONS.VENDORS },
  { label: 'side_menu.whiskey_tube', action: this.ROUTE_ACTIONS.WHISKEY_TUBE },
  { label: 'side_menu.products', action: this.ROUTE_ACTIONS.PRODUCTS },
  { label: 'side_menu.events', action: this.ROUTE_ACTIONS.EVENTS },
  { label: 'side_menu.surveys', action: this.ROUTE_ACTIONS.SURVEYS },
  { label: 'side_menu.spirits', action: this.ROUTE_ACTIONS.SPIRITS },
];

exports.ACTIVE_STATUS_OPTIONS_FOR_FILTER = [
  { label: 'All', value: '' },
  { label: 'Enable', value: true },
  { label: 'Disable', value: false },
];

exports.ARCHIVE_STATUS_OPTIONS_FOR_FILTER = [
  { label: 'All', value: '' },
  { label: 'Unarchived', value: false },
  { label: 'Archived', value: true },
];

exports.USER_ROLES = {
  SUPER_ADMIN: 'Super Admin',
  CUSTOMER: 'Customer',
  VENDOR: 'Vendor',
};
