import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTE_ACTIONS } from '../../utils/Constants';

/**
 * function to render home screen ui based on different role
 * @param {*} loggedInUser: logged in user info
 * @returns
 */
const Home = ({ loggedInUser }) => {
  if (loggedInUser && loggedInUser.isSystemGeneratedPassword) {
    return <Redirect to={ROUTE_ACTIONS.FORCE_RESET_PASSWORD} />;
  } else if (loggedInUser) {
    if (loggedInUser.userRoles.isSystemSuperAdmin) {
      return <Redirect to={ROUTE_ACTIONS.DASHBOARD} />;
    }
  } else {
    return <Redirect to={ROUTE_ACTIONS.SIGNIN} />;
  }
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch acti	on to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
