import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, InputAdornment, Icon, Box } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { updatePasswordRequest } from '../../AuthenticationApiActions';
import Card from '@mui/material/Card';
import { CardContent, CardHeader } from '@mui/material';
import { I18n } from 'react-redux-i18n';
import {
  ONLY_BLANK_SPACES,
  VALIDATE_PASSWORD,
  IS_PASSWORD_MATCH,
} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import './ChangePassword.css';

/**
 * function to render change password
 * @param {*} history: history object
 * @param {*} changePassword: function to handle change password
 * @returns
 */
const ChangePassword = ({ history, changePassword }) => {
  const [user, setUser] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );
    ValidatorForm.addValidationRule(
      VALIDATE_PASSWORD,
      UtilHelper.validatePassword
    );
    ValidatorForm.addValidationRule(IS_PASSWORD_MATCH, (value) => {
      return UtilHelper.isPasswordMatch(user.newPassword, user.confirmPassword);
    });

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
      ValidatorForm.removeValidationRule(VALIDATE_PASSWORD);
      ValidatorForm.removeValidationRule(IS_PASSWORD_MATCH);
    };
  }, [user]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   */
  const handleChange = (event) => {
    let data = Object.assign({}, user);

    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    try {
      await changePassword(user.newPassword, user.currentPassword);
      navigateBack();
    } catch (error) {}
  };

  /**
   * function to handle back navigation
   */
  const navigateBack = () => {
    history.goBack();
  };

  return (
    <Box className='sivotec-page-content'>
      <Box className='password-container'>
        <Card className='card-container'>
          <CardHeader title='Change Password' />
          <CardContent>
            <ValidatorForm noValidate onSubmit={handleSubmit}>
              <TextValidator
                variant='standard'
                required
                autoFocus
                margin='dense'
                name='currentPassword'
                label={I18n.t('change_password.current_password_form_label')}
                type={showCurrentPassword ? 'text' : 'password'}
                onChange={handleChange}
                value={user.currentPassword}
                fullWidth
                validators={['required', ONLY_BLANK_SPACES]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      className='input-adornment'
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                    >
                      {showCurrentPassword ? (
                        <Icon>visibility</Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextValidator
                variant='standard'
                required
                margin='dense'
                name='newPassword'
                label={I18n.t('change_password.new_password_form_label')}
                type={showNewPassword ? 'text' : 'password'}
                onChange={handleChange}
                value={user.newPassword}
                fullWidth
                validators={['required', ONLY_BLANK_SPACES, VALIDATE_PASSWORD]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t('error_messages.valid_password'),
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      className='input-adornment'
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? (
                        <Icon>visibility</Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextValidator
                variant='standard'
                required
                margin='dense'
                name='confirmPassword'
                label={I18n.t(
                  'change_password.re_enter_new_password_form_label'
                )}
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={handleChange}
                value={user.confirmPassword}
                fullWidth
                validators={['required', IS_PASSWORD_MATCH]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.password_mismatch'),
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      className='input-adornment'
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <Icon>visibility</Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <Box textAlign='right' mt={1.5}>
                <Button color='inherit' onClick={navigateBack}>
                  {I18n.t('change_password.cancel_button')}
                </Button>

                <Button
                  size='small'
                  variant='contained'
                  type='submit'
                  color='secondary'
                  style={{ marginLeft: '0.5rem' }}
                >
                  {I18n.t('change_password.reset_password_button')}
                </Button>
              </Box>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: async (password, currentPassword) => {
      try {
        //DONE: Do we need Promise.resolve here?
        return await dispatch(updatePasswordRequest(password, currentPassword));
      } catch (error) {
        //DONE: Do we need Promise.reject here?
        throw error;
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
