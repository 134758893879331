/**
 * product list data
 */
export const productListData = {
  count: 100,
  rows: [
    {
      id: '1',
      profileImgUrl:
        'https://ui-avatars.com/api/?name=Vendor+1&background=random',

      name: 'Product 1',
      category: 'T-shirts',
      vendor: 'Vendor 1',
      cost: '97394',
      itemsSold: '20',
      archiveStatus: true,
      enabled: true,
      merchandisesCount: 20,
      surveyCount: 30,
      eventsCount: 40,
      mentionsCount: 38,
      overview: 'overview',
    },
    {
      id: '1',
      profileImgUrl:
        'https://ui-avatars.com/api/?name=Vendor+1&background=random',

      name: 'Product 2',
      category: 'Games',
      vendor: 'Vendor 2',
      cost: '973',
      itemsSold: '102',
      archiveStatus: false,
      enabled: false,
      merchandisesCount: 20,
      surveyCount: 30,
      eventsCount: 40,
      mentionsCount: 38,

      overview: 'overview',
    },
  ],
};

/**
 * category options
 */
export const categoryOptions = [
  {
    id: 1,
    name: 'Games',
  },
  {
    id: 2,
    name: 'Bar Equipments',
  },
  {
    id: 3,
    name: 'T-shirts',
  },
];

/**
 * vendor options
 */
export const vendorOptions = [
  {
    id: 1,
    name: 'Vendor 1',
  },
  {
    id: 2,
    name: 'Vendor 2',
  },
  {
    id: 3,
    name: 'Vendor 3',
  },
];

export const mediaUrlsDemo = [
  {
    id: '1',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=M+I&background=random',
  },
  {
    id: '2',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=T+I&background=random',
  },
  {
    id: '3',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=L+I&background=random',
  },
  {
    id: '4',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=G+I&background=random',
  },
];
