import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  Tooltip,
  Grid,
  Typography,
  Avatar,
  Chip,
  Icon,
  Box,
  FormControlLabel,
  FormControl,
  Radio,
  FormGroup,
  Checkbox,
  RadioGroup,
  Paper,
} from '@mui/material';
import { I18n } from 'react-redux-i18n';
import { CustomFilePreviewSA } from '../../../../utils/components';
import { getProductRequest } from '../../ProductApiActions';
import { mediaUrlsDemo } from '../../data';
import 'react-image-gallery/styles/css/image-gallery.css';
import './ProductDetails.css';
import { useStyles } from './styles';
import { MessageModal } from '../../../../utils/components';

/**
 * function to render product detailed view
 * @param {*} history: history object
 * @param {*} getProduct: function to get product details
 * @returns
 */
const ProductDetails = ({ history, getProduct }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const modalRef = useRef();
  const productId = match.params.productId;
  const [product, setProduct] = useState(undefined);

  useEffect(() => {
    //demo

    let data = {
      name: 'name',
      description: 'desc',
      category: 'category',
      vendor: 'vendor 1',
      mediaUrls: mediaUrlsDemo,
      sizes: {
        S: true,
        M: false,
        L: false,
        XL: true,
        XXL: false,
        'Free Size': false,
      },
      isAvailable: {
        S: 'yes',
        M: '',
        L: '',
        XL: 'no',
        XXL: '',
        'Free Size': '',
      },
    };

    setProduct(data);

    // getProduct(productId).then((res) => {
    //   let data = {
    //     name: res.name,
    //     overview: res.overview,
    //     link: res.link,
    //     description: res.description, //EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(res.description).contentBlocks, htmlToDraft(res.description).entityMap)),
    //     profileImgUrl: '',
    //     categoryId: res.category,
    //     associatedUsers: res.associatedUsers,
    //     mediaUrls: [],
    //   };

    //   res.mediaUrls.forEach((media) => {
    //     const fileExtension = media.path.split('.').pop();
    //     let tempObj = {
    //       type: fileExtension,
    //       url: media.readSignedURL,
    //       path: media.path,
    //       fileName: media.fileName,
    //       fileType: media.fileType,
    //       original: media.readSignedURL,
    //       thumbnail: media.readSignedURL,
    //     };
    //     if (fileExtension === 'pdf') {
    //       tempObj.renderItem = (props) => {
    //         return (
    //           <FileViewer
    //             fileType={props.type}
    //             filePath={props.url}
    //             errorComponent={CustomErrorComponent}
    //           />
    //         );
    //       };
    //       tempObj.renderThumbInner = (aa) => {
    //         return 'pdf';
    //       };
    //     } else if (fileExtension === 'mp4') {
    //       tempObj.renderItem = (props) => {
    //         return <ReactPlayer playing={true} url={props.url} />;
    //       };
    //       tempObj.renderThumbInner = (aa) => {
    //         return <MovieIcon fontSize='large' />;
    //       };
    //     } else {
    //     }
    //     data.mediaUrls.push(tempObj);
    //   });
    //   setProduct(data);
    // });
  }, []);

  /**
   * function to handle edit click
   */
  const onEditProduct = () => {
    const url = `${match.url}/update`;
    history.push(url);
  };

  /**
   * funtion to activate deactivate product
   * @param {*} userId: user id
   * @param {*} enabled: enabled
   * @param {*} name: name
   */
  const onActivateDeactivate = (userId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} Product`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //   // note: note,
        //   enabled: !enabled,
        // };
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle archive or unarchive
   * @param {*} userId: user id
   * @param {*} enabled: enabled
   * @param {*} name: name
   */
  const onArchiveUnarchive = (userId, enabled, name) => {
    const title = `${enabled ? 'Archive' : 'Unarchive'} Product`;
    const message = `Are you sure you want to ${
      enabled ? 'archive' : 'unarchive'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //   // note: note,
        //   enabled: !enabled,
        // };
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle mark out of stock
   * @param {*} userId: user id
   * @param {*} enabled: enabled
   * @param {*} name: name
   */
  const onMarkOutOfStock = (userId, enabled, name) => {
    const title = I18n.t('product.confirmation_dialog.out_of_stock');
    const message = I18n.t('product.confirmation_dialog.out_of_stock_message');
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //   // note: note,
        //   enabled: !enabled,
        // };
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Paper component={Box} p={3}>
        <Box mb={4}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center'>
                <Box mt={0.4} mr={1} ml={-0.5}>
                  <Tooltip title='Back' placement='top' arrow>
                    <Icon
                      onClick={() => history.goBack()}
                      classes={{ root: classes.actionIcon }}
                    >
                      arrow_back_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box>
                  <Typography variant='h6'>
                    {I18n.t('product.product_details.page_header_label')}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Box display='flex' alignItems='center' mr={4}>
                  <Icon>inventory_2</Icon>{' '}
                  <Typography variant='body2'>16</Typography>
                </Box>

                <Box display='flex' alignItems='center' mr={4}>
                  <Icon>bookmark</Icon>{' '}
                  <Typography variant='body2'>16</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <Box>
                  <Tooltip
                    placement='top'
                    arrow
                    title={I18n.t(
                      'product.product_details.event_edit_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{ root: classes.actionIcon }}
                      onClick={() => onEditProduct(productId)}
                    >
                      edit_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box ml={1}>
                  <Tooltip
                    placement='top'
                    arrow
                    title={I18n.t(
                      'product.product_details.event_enabled_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{ root: classes.actionIcon }}
                      onClick={() => {
                        // onActivateDeactivate(props.id, props.enabled, props.name);
                        onActivateDeactivate('1', false, 'the Product');
                      }}
                    >
                      lock
                    </Icon>
                  </Tooltip>
                </Box>

                <Box ml={1}>
                  <Tooltip
                    placement='top'
                    arrow
                    title={I18n.t(
                      'product.product_details.event_archive_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{ root: classes.actionIcon }}
                      onClick={() => {
                        onArchiveUnarchive('1', true, 'the Product');
                      }}
                    >
                      archive
                    </Icon>
                  </Tooltip>
                </Box>

                <Box ml={1}>
                  <Tooltip
                    placement='top'
                    arrow
                    title={I18n.t(
                      'product.product_details.event_out_of_stock_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{ root: classes.actionIcon }}
                      onClick={() => {
                        onMarkOutOfStock('1', true, 'the Product');
                      }}
                    >
                      remove_shopping_cart
                    </Icon>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('product.product_details.product_field_name_label')}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>{product?.name}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('product.product_details.product_field_cost_label')}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>1467</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t(
                      'product.product_details.product_field_category_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Chip variant='outlined' label={product?.category} />
                </Box>
              </Box>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t(
                      'product.product_details.product_field_vendor_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Chip
                    variant='outlined'
                    avatar={
                      <Avatar
                        alt='vendor'
                        src={`https://ui-avatars.com/api/?name=Super+Admin&background=random`}
                      ></Avatar>
                    }
                    label={product?.vendor}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Box mb={2.5}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography>
                      {I18n.t(
                        'product.product_details.product_field_sizes_label'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography>
                      {I18n.t(
                        'product.product_details.product_field_is_available_label'
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      borderBottom='1px solid #334e68'
                      width='76%'
                      mt={1.2}
                      mb={1}
                    ></Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <FormControl
                      component='fieldset'
                      variant='standard'
                      disabled
                    >
                      <FormGroup>
                        {product &&
                          Object.entries(product?.sizes).map(
                            ([key, value], index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    color='secondary'
                                    name={key}
                                    checked={value}
                                    classes={{
                                      disabled: classes.checkboxRadioDisabled,
                                    }}
                                  />
                                }
                                label={key}
                                classes={{
                                  disabled: classes.checkboxRadioLabelDisabled,
                                }}
                              />
                            )
                          )}
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <FormControl component='fieldset' disabled>
                      {product &&
                        Object.entries(product?.isAvailable).map(
                          ([key, value], index) => (
                            <RadioGroup key={index} row value={value}>
                              <FormControlLabel
                                value='yes'
                                control={
                                  <Radio
                                    color='secondary'
                                    classes={{
                                      disabled: classes.checkboxRadioDisabled,
                                    }}
                                  />
                                }
                                label={I18n.t(
                                  'product.product_details.product_field_is_available_yes_label'
                                )}
                                classes={{
                                  disabled: classes.checkboxRadioLabelDisabled,
                                }}
                              />
                              <FormControlLabel
                                value='no'
                                control={
                                  <Radio
                                    color='secondary'
                                    classes={{
                                      disabled: classes.checkboxRadioDisabled,
                                    }}
                                  />
                                }
                                label={I18n.t(
                                  'product.product_details.product_field_is_available_no_label'
                                )}
                                classes={{
                                  disabled: classes.checkboxRadioLabelDisabled,
                                }}
                              />
                            </RadioGroup>
                          )
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box mb={2.5}></Box>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box mb={2.5}>
                <Box>
                  <Typography variant='body1'>
                    {I18n.t(
                      'product.product_details.product_field_description_label'
                    )}
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant='body2'>
                    {product?.description}
                  </Typography>
                </Box>
                <Box>
                  <Box borderBottom='1px solid #334e68' mt={2.5}></Box>
                </Box>
              </Box>
            </Grid>

            {product && product.mediaUrls && product.mediaUrls.length > 0 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box mb={2.5}>
                  <Typography variant='body1'>
                    {I18n.t(
                      'product.product_details.product_field_media_label'
                    )}
                  </Typography>
                </Box>

                <CustomFilePreviewSA files={product.mediaUrls} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>

      <MessageModal ref={modalRef} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: async (productId) => {
      try {
        return await dispatch(getProductRequest(productId));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
