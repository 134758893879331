import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  TextField,
  FormHelperText,
  Autocomplete,
  ListItem,
} from '@mui/material';
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ReactComponent as File } from '../../../../assets/File.svg';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Constants, { ONLY_BLANK_SPACES } from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { MobileNumber, FileUpload } from '../../../../utils/components';
import { vendorOptions } from '../../data';
import { I18n } from 'react-redux-i18n';
import { useStyles } from './styles';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

/**
 * function to render event form
 * @param {*} history: history object
 * @returns
 */
const EventForm = ({ history }) => {
  const classes = useStyles();
  const { eventId } = useParams();

  const [eventInfo, setEventInfo] = useState({
    name: '',
    date: null,
    startTime: null,
    endTime: null,
    personName: '',
    personPhone: '',
    vendor: null,
    tickets: '',
    cost: '',
    address: '',
    about: '',
    terms: '',
  });
  const [files, setFiles] = useState([]);

  const [dateError, setDateError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [personPhoneError, setPersonPhoneError] = useState('');
  const [aboutError, setAboutError] = useState('');
  const [termsError, setTermsError] = useState('');
  //eslint-disable-next-line
  const [openFileViewer, setOpenFileViewer] = useState(false);
  //eslint-disable-next-line
  const [selectedFile, setSelectedFile] = useState(undefined);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (eventInfo.about) {
      setAboutError('');
    }
  }, [eventInfo.about]);

  useEffect(() => {
    if (eventInfo.terms) {
      setTermsError('');
    }
  }, [eventInfo.terms]);

  /**
   * function to handle input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, eventInfo);
    data[event.target.name] = event.target.value;
    setEventInfo(data);
  };

  /**
   * function to handle date time input change
   * @param {*} name: name
   * @param {*} value: value
   */
  const handleDateTimeInputChange = (name, value) => {
    const data = Object.assign({}, eventInfo);
    data[name] = value;
    setEventInfo(data);
  };

  /**
   * function to handle autocomplete input change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeAutocomplete = (elementName, selectedvalue) => {
    const data = Object.assign({}, eventInfo);
    data[elementName] = selectedvalue;
    setEventInfo(data);
  };

  /**
   * function to handle mobile number change
   * @param {*} phone: phone value
   */
  const updateMobileNumber = (phone) => {
    const data = Object.assign({}, eventInfo);
    data['personPhone'] = phone;
    setEventInfo(data);

    if (!UtilHelper.validatePhone(phone)) {
      setPersonPhoneError(I18n.t('error_messages.mobile_number_invalid_error'));
    } else {
      setPersonPhoneError('');
    }
  };

  /**
   * function to handle on file change
   * @param {*} selectedFiles
   * @param {*} updateError
   */
  const onChangeFile = (selectedFiles, updateError) => {
    if (selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();

      const data = {
        fileName: fileName,
        fileType: '.' + fileExtension,
      };

      console.log(data);

      setFiles(selectedFiles);
    }
  };

  /**
   * function to handle file remove
   * @param {*} index
   */
  const onRemoveFile = (index) => {
    let fileList = Object.assign([], files);
    fileList.splice(index, 1);
    setFiles(fileList);
  };

  /**
   * function to handle file preview
   * @param {*} file: file
   */
  const onShowFilePreview = (file) => {
    setSelectedFile(file);
    setOpenFileViewer(true);
  };

  /**
   * function to handle editor state change
   * @param {*} name: name
   * @param {*} value: value
   */
  const onEditorStateChange = (name, value) => {
    const data = Object.assign({}, eventInfo);
    data[name] = value;
    setEventInfo(data);
  };

  /**
   * function to handle validation
   */
  const handleValidation = () => {
    if (eventInfo.personPhone.length === 0) {
      setPersonPhoneError(I18n.t('error_messages.field_required'));
    }

    if (eventInfo.date === null) {
      setDateError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.startTime === null) {
      setStartTimeError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.endTime === null) {
      setEndTimeError(I18n.t('error_messages.field_required'));
    }

    if (eventInfo.about.length === 0) {
      setAboutError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.about) {
      const editorContent = convertToRaw(eventInfo.about.getCurrentContent());
      if (editorContent.blocks[0].text.length === 0) {
        setAboutError(I18n.t('error_messages.field_required'));
      }
    }

    if (eventInfo.terms.length === 0) {
      setTermsError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.terms) {
      const editorContent = convertToRaw(eventInfo.terms.getCurrentContent());
      if (editorContent.blocks[0].text.length === 0) {
        setTermsError(I18n.t('error_messages.field_required'));
      }
    }
  };

  /**
   * function to handle form submit
   */
  const onSubmit = () => {
    handleValidation();

    if (!personPhoneError) {
      const data = {
        ...eventInfo,
        about: eventInfo.about
          ? draftToHtml(convertToRaw(eventInfo.about.getCurrentContent()))
          : '',
      };

      console.log(data);

      // data.about = draftToHtml(
      //   convertToRaw(eventInfo.about.getCurrentContent())
      // );
      // submitFormData(UtilHelper.trimObject(data));
      // console.log(data);
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    handleValidation();
  };

  return (
    <Paper component={Box} p={3}>
      <Box marginBottom='1rem'>
        <Typography variant='h6'>
          {eventId
            ? I18n.t('event.event_form.form_title_update_event_label')
            : I18n.t('event.event_form.form_title_add_event_label')}
        </Typography>
      </Box>

      <Box>
        <ValidatorForm onSubmit={onSubmit} noValidate onError={onFormError}>
          <Grid container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t('event.event_form.form_field_name_label')}
                onChange={handleChangeInput}
                helperText={`${eventInfo.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`}
                name='name'
                value={eventInfo.name}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Autocomplete
                size='small'
                name='vendor'
                required
                getOptionLabel={(option) => (option ? option.name : '')}
                options={vendorOptions}
                onChange={(e, value) =>
                  handleChangeAutocomplete('vendor', value)
                }
                value={eventInfo.vendor}
                renderInput={(params) => {
                  return (
                    <TextValidator
                      variant='standard'
                      name='vendor'
                      {...params}
                      fullWidth
                      label={I18n.t('event.event_form.form_field_vendor_label')}
                      value={eventInfo.vendor}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t(
                  'event.event_form.form_field_total_tickets_label'
                )}
                onChange={handleChangeInput}
                name='tickets'
                value={eventInfo.tickets}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'matchRegexp:^[0-9]+$',
                  'minNumber:1',
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_18,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t('error_messages.only_digit_allow'),
                  I18n.t('error_messages.min_number_allow'),
                  I18n.t(
                    'error_messages.maximum_18_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t(
                  'event.event_form.form_field_cost_per_ticket_label'
                )}
                onChange={handleChangeInput}
                name='cost'
                value={eventInfo.cost}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'matchRegexp:^([0-9]*[.])?[0-9]+$',
                  'maxFloat:' + Constants.VALIDATIONS.MAXIMUM_FLOAT_VALUE,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t('error_messages.int_float_only'),
                  I18n.t('error_messages.maximum_allowed_float', {
                    value: Constants.VALIDATIONS.MAXIMUM_FLOAT_VALUE,
                  }),
                ]}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={I18n.t('event.event_form.form_field_date_label')}
                  minDate={new Date()}
                  inputFormat='dd/MM/yyyy'
                  value={eventInfo.date}
                  onChange={(date) => handleDateTimeInputChange('date', date)}
                  renderInput={(params) => (
                    <TextField
                      variant='standard'
                      required
                      {...params}
                      helperText={dateError}
                      FormHelperTextProps={{
                        error: true,
                      }}
                    />
                  )}
                  onError={(reason, value) => {
                    if (reason === 'invalidDate') {
                      setDateError(I18n.t('error_messages.invalid_date'));
                    }

                    if (reason === 'minDate') {
                      setDateError(I18n.t('error_messages.past_date'));
                    }

                    if (reason === null) {
                      setDateError('');
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopTimePicker
                  label={I18n.t('event.event_form.form_field_start_time_label')}
                  minTime={new Date()}
                  value={eventInfo.startTime}
                  onChange={(date) =>
                    handleDateTimeInputChange('startTime', date)
                  }
                  renderInput={(params) => (
                    <TextField
                      variant='standard'
                      required
                      {...params}
                      helperText={startTimeError}
                      FormHelperTextProps={{
                        error: true,
                      }}
                    />
                  )}
                  onError={(reason, value) => {
                    if (reason === 'invalidDate') {
                      setDateError(I18n.t('error_messages.invalid_time'));
                    }

                    if (reason === 'minTime') {
                      setDateError(I18n.t('error_messages.past_time'));
                    }

                    if (reason === null) {
                      setStartTimeError('');
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopTimePicker
                  label={I18n.t('event.event_form.form_field_end_time_label')}
                  minTime={new Date()}
                  value={eventInfo.endTime}
                  onChange={(date) =>
                    handleDateTimeInputChange('endTime', date)
                  }
                  renderInput={(params) => (
                    <TextField
                      variant='standard'
                      required
                      {...params}
                      helperText={endTimeError}
                      FormHelperTextProps={{
                        error: true,
                      }}
                    />
                  )}
                  onError={(reason, value) => {
                    if (reason === 'invalidDate') {
                      setDateError(I18n.t('error_messages.invalid_time'));
                    }

                    if (reason === 'minTime') {
                      setDateError(I18n.t('error_messages.past_time'));
                    }

                    if (reason === null) {
                      setEndTimeError('');
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t('event.event_form.form_field_person_name_label')}
                onChange={handleChangeInput}
                helperText={`${eventInfo.personName.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`}
                name='personName'
                value={eventInfo.personName}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_allowed_characters_for_text_field'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <MobileNumber
                customLabel={`${I18n.t(
                  'event.event_form.form_field_person_phone_label'
                )} *`}
                phone={eventInfo.personPhone}
                updateMobileNumber={updateMobileNumber}
              />

              {personPhoneError && (
                <FormHelperText
                  error
                  style={{ marginTop: '7px', marginLeft: '14px' }}
                >
                  {personPhoneError}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextValidator
                variant='standard'
                required
                fullWidth
                size='small'
                multiline
                rows={3}
                maxRows={5}
                label={I18n.t('event.event_form.form_field_address_label')}
                onChange={handleChangeInput}
                helperText={`${eventInfo.address.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_500}`}
                name='address'
                value={eventInfo.address}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_500,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_500_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography classes={{ root: classes.label }}>
                {`${I18n.t('event.event_form.form_field_about_event_label')} *`}
              </Typography>

              <Editor
                editorState={eventInfo.about}
                onEditorStateChange={(value) =>
                  onEditorStateChange('about', value)
                }
                wrapperStyle={{
                  borderBottom: '1px solid #334e68',
                }}
                toolbarStyle={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0px',
                  margin: '0px',
                  marginLeft: '-3px',
                }}
                editorStyle={{
                  heigth: '300px !important',
                }}
                toolbar={{
                  options: [
                    'inline',
                    'textAlign',
                    'link',
                    'emoji',
                    'remove',
                    'history',
                  ],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic'],
                  },
                }}
              />

              {aboutError && (
                <FormHelperText
                  error
                  style={{ marginTop: '7px', marginLeft: '14px' }}
                >
                  {aboutError}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography classes={{ root: classes.label }}>
                {`${I18n.t('event.event_form.form_field_terms_label')} *`}
              </Typography>
              <Editor
                editorState={eventInfo.terms}
                onEditorStateChange={(value) =>
                  onEditorStateChange('terms', value)
                }
                wrapperStyle={{
                  borderBottom: '1px solid #334e68',
                }}
                toolbarStyle={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0px',
                  margin: '0px',
                  marginLeft: '-3px',
                }}
                editorStyle={{
                  heigth: '300px !important',
                }}
                toolbar={{
                  options: [
                    'inline',
                    'textAlign',
                    'link',
                    'emoji',
                    'remove',
                    'history',
                  ],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic'],
                  },
                }}
              />

              {termsError && (
                <FormHelperText
                  error
                  style={{ marginTop: '7px', marginLeft: '14px' }}
                >
                  {termsError}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FileUpload
                files={files}
                acceptedFiles={[process.env.REACT_APP_PRODUCTS_FILES_SUPPORTED]}
                filesLimit={process.env.REACT_APP_PRODUCT_UPLOAD_FILE_LIMIT_5}
                showPreviewsInDropzone={true}
                fieldLabel={I18n.t('event.event_form.form_field_upload_label')}
                dropzoneLabel={I18n.t(
                  'event.event_form.form_field_dropzon_label'
                )}
                updateFileList={(selectedFiles, updateError) =>
                  onChangeFile(selectedFiles, updateError)
                }
                removeFileFromSelection={(index) => onRemoveFile(index)}
              />

              {eventInfo.uploadedFiles && eventInfo.uploadedFiles.length > 0 ? (
                <>
                  <Typography
                    variant='h6'
                    className='dropzone-label margin-top-10'
                  >
                    {I18n.t(
                      'product.add_product.form_field_uploaded_media_label'
                    )}
                  </Typography>
                  {eventInfo.uploadedFiles.map((file, index) => (
                    <Grid container spacing={3} key={index}>
                      <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                        <div className='selected-file-container'>
                          <ListItem className={'file-list-item'}>
                            <File className={'selected-file'} />
                            <Typography
                              variant='h6'
                              className='selected-file-name'
                            >
                              {file.fileName}
                            </Typography>
                            {/* <RemoveIcon className="remove-file-icon" color="primary" onClick={() => removeFileFromSelection(index)}/> */}
                          </ListItem>
                        </div>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Button
                          size='large'
                          variant='outlined'
                          color='primary'
                          fullWidth
                          onClick={() => onShowFilePreview(file)}
                        >
                          {I18n.t(
                            'product.product_details.product_field_media_preview_button_label'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <Button
                  color='inherit'
                  variant='text'
                  onClick={() => history.goBack()}
                >
                  {I18n.t('global.button_labels.cancel')}
                </Button>
                <Box ml={1}>
                  <Button type='submit' color='secondary' variant='contained'>
                    {I18n.t('global.button_labels.save')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>
    </Paper>
  );
};

export default EventForm;
