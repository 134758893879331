import React from 'react';
import './PageNotFound.css';
import { Typography, Button, Grid } from '@mui/material';

/**
 * function to render page not found
 * @returns
 */
const PageNotFound = () => {
  return (
    <Grid
      container
      direction='column'
      justify='center'
      spacing={0}
      alignItems='center'
      style={{ minHeight: '90vh' }}
    >
      <Grid key={'value'} item>
        <Typography className='number-container' color='primary'>
          <span className='four'>4</span>
          <span className='zero'>0</span>
          <span className='four'>4</span>
        </Typography>
        <div className='page-not-found'>
          <Typography variant='h1'>Page Not Found</Typography>
          <Typography variant='h3'>
            Something went wrong while accessing the link.
          </Typography>
        </div>
        <div className='back-to-home'>
          <Button
            variant='outlined'
            size='medium'
            color='primary'
            className={'text-transform-capitalize back-to-home-button'}
            onClick={() => (window.location = '/')}
          >
            Back to Home
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
