import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Paper,
  TableContainer,
  Box,
  Icon,
} from '@mui/material';
import { I18n } from 'react-redux-i18n';
import Constants from '../../../../utils/Constants';
import { CustomTable, AvatarCell } from '../../../../utils/components';
import {
  InputFilter,
  NumberRangeFilter,
  RadioButtonFilter,
} from '../../../../utils/components/Filter';
import { getVendorsRequest } from '../../VendorApiActions';
import { vendorListData } from '../../data';
import { AddVendor } from '../index';
import { useStyles } from './styles';

/**
 * function to render vendor list
 * @param {*} history: history object
 * @param {*} getVendors: function to get vendors
 * @returns
 */
const VendorList = ({ history, getVendors }) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      searchFilter: '',
      enabled: '',
      merchandisesCount: null,
      surveyCount: null,
      eventsCount: null,
      mentionsCount: null,
    },
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [vendorList, setVendorList] = useState(null);

  /**
   * function to get vendor list
   * @param {*} requestData: request data
   */
  const getVendorList = async (requestData) => {
    /**
     * demo data for UI
     */
    setVendorList(vendorListData);

    try {
      const data = getVendors(requestData);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    getVendorList(paginationParams);
    //eslint-disable-next-line
  }, [paginationParams]);

  /**
   * function to handle vendor profile view
   * @param {*} vendorId: vendor id
   */
  const onViewVendor = (vendorId) => {
    const url = `${match.url}/${vendorId}`;
    history.push(url);
  };

  /**
   * function to handle form dialog close
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
    getVendorList(paginationParams);
  };

  const columns = [
    {
      title: I18n.t('vendor.vendor_table.table_column_name_label'),
      field: 'name',
      width: '20%',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ id, name, profileImgUrl }) => {
        return (
          <Box
            onClick={() => onViewVendor(id)}
            className={classes.avatarContainer}
          >
            <AvatarCell image={profileImgUrl} heading={name} />
          </Box>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_merchandises'),
      field: 'merchandisesCount',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({ merchandisesCount }) => {
        return (
          <Typography variant='body2'>
            {merchandisesCount ? merchandisesCount : 0}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_survey'),
      field: 'surveyCount',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({ surveyCount }) => {
        return (
          <Typography variant='body2'>
            {surveyCount ? surveyCount : 0}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_events'),
      field: 'eventsCount',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({ eventsCount }) => {
        return (
          <Typography variant='body2'>
            {eventsCount ? eventsCount : 0}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_mentions'),
      field: 'mentionsCount',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({ mentionsCount }) => {
        return (
          <Typography variant='body2'>
            {mentionsCount ? mentionsCount : 0}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_activation_status_label'),
      field: 'enabled',
      sorting: false,
      width: '10%',
      cellStyle: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
      render: ({ enabled }) => {
        return (
          <Typography
            variant='body2'
            className={enabled ? 'status-success' : 'status-danger'}
          >
            {enabled
              ? I18n.t('vendor.vendor_table.table_column_enabled_status_label')
              : I18n.t('vendor.vendor_table.table_column_disabled_label')}
          </Typography>
        );
      },
    },
  ];

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={1}
          display='flex'
          alignItems='flex-start'
          justifyContent='space-between'
          color='#fff'
        >
          <Grid
            container
            spacing={1}
            classes={{ container: classes.customGridContainer }}
          >
            <Grid item classes={{ item: classes.customGridItem }}>
              <Box width='220px' mr={1}>
                <InputFilter
                  filterName=''
                  placeholder={I18n.t(
                    'vendor.vendor_table.table_filter_search_by_name'
                  )}
                  onChangeFilter={(value) =>
                    handleChangeFilter('searchFilter', value)
                  }
                />
              </Box>
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <RadioButtonFilter
                arrayList={Constants.ACTIVE_STATUS_OPTIONS_FOR_FILTER}
                filterName={I18n.t(
                  'vendor.vendor_table.filter_active_status_label'
                )}
                title={I18n.t('vendor.vendor_table.filter_active_status_label')}
                value={paginationParams.searchData.enabled}
                onChangeFilter={(value) => handleChangeFilter('enabled', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'vendor.vendor_table.filter_merchandises_label'
                )}
                title={I18n.t('vendor.vendor_table.filter_merchandises_label')}
                value={paginationParams.searchData.merchandisesCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('merchandisesCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t('vendor.vendor_table.filter_survey_label')}
                title={I18n.t('vendor.vendor_table.filter_survey_label')}
                value={paginationParams.searchData.surveyCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('surveyCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t('vendor.vendor_table.filter_events_label')}
                title={I18n.t('vendor.vendor_table.filter_events_label')}
                value={paginationParams.searchData.eventsCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('eventsCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{ item: classes.customGridItem }}>
              <NumberRangeFilter
                filterName={I18n.t('vendor.vendor_table.filter_mentions_label')}
                title={I18n.t('vendor.vendor_table.filter_mentions_label')}
                value={paginationParams.searchData.mentionsCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('mentionsCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant='contained'
              startIcon={<Icon>add_circle</Icon>}
              color='secondary'
              onClick={() => setOpenDialog(true)}
            >
              {I18n.t('vendor.vendor_table.add_vendor_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableContainer>
      {renderTableTitle()}

      <CustomTable
        columns={columns}
        data={vendorList?.rows}
        hidePaging={false}
        hideBorderShadow={true}
        rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
        rowsPerPage={paginationParams.rowsPerPage}
        count={vendorList?.count}
        page={paginationParams.page}
        onPageChange={(page) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = page;
          setPaginationParams(pagination);
        }}
        onRowsPerPageChange={(rowsPerPage) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = 0;
          pagination.rowsPerPage = rowsPerPage;
          setPaginationParams(pagination);
        }}
        // columns={columns}
        // data={fileList ? fileList.csv_list : []}
        // pages={10}
        // pages={Math.ceil(allUsers.count / paginationParams.rowsPerPage)}
        // pages={fileList ? fileList.max_page_count : 0}
        // pageSize={10}
        // pageSize={paginationParams.rowPerPage}
        // totalCount={fileList ? fileList.max_record_count : 0}
        // page={paginationParams.page}
      />

      {openDialog ? (
        <AddVendor
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
        />
      ) : (
        <></>
      )}
    </TableContainer>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendors: async (data) => {
      try {
        return await dispatch(getVendorsRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
