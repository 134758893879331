import React, { useRef } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import {
  Paper,
  Box,
  Typography,
  Icon,
  Tooltip,
  Grid,
  Chip,
  Avatar,
} from '@mui/material';
import {
  CustomFilePreviewSA,
  MessageModal,
} from '../../../../utils/components';
import { htmlResAbout, htmlResTerms, mediaUrls } from '../../data';
import { useStyles } from './styles';
import { I18n } from 'react-redux-i18n';

/**
 * function to render event detailed view
 * @param {*} history: history object
 * @returns
 */
const EventDetails = ({ history }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { eventId } = useParams();
  const modalRef = useRef();

  /**
   * function to handle edit icon click
   */
  const onEditEvent = () => {
    const url = `${match.url}/update`;
    history.push(url);
  };

  /**
   * function to handle event cancel
   */
  const onEventCancel = () => {
    const title = I18n.t('event.confirmation_dialog.cancel');
    const message = I18n.t('event.confirmation_dialog.cancel_message');
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        // const data = {
        //   // note: note,
        //   enabled: !enabled,
        // };
        // activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Paper component={Box} p={3}>
        <Box mb={4}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center'>
                <Box mt={0.4} mr={1} ml={-0.5}>
                  <Tooltip title='Back'>
                    <Icon onClick={() => history.goBack()}>
                      arrow_back_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box>
                  <Typography variant='h6'>
                    {I18n.t('event.event_details.page_header_label')}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Box display='flex' alignItems='center' mr={4}>
                  <Icon>bookmark</Icon>{' '}
                  <Typography variant='body2'>16</Typography>
                </Box>

                <Box display='flex' alignItems='center' mr={4}>
                  <Icon>bookmark</Icon>{' '}
                  <Typography variant='body2'>16</Typography>
                </Box>

                <Box display='flex' alignItems='center' mr={4}>
                  <Icon>bookmark</Icon>{' '}
                  <Typography variant='body2'>16</Typography>
                </Box>

                <Box display='flex' alignItems='center'>
                  <Icon>bookmark</Icon>{' '}
                  <Typography variant='body2'>16</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <Box>
                  <Tooltip
                    placement='top'
                    title={I18n.t(
                      'event.event_details.event_edit_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{ root: classes.actionIcon }}
                      onClick={() => onEditEvent(eventId)}
                    >
                      edit_rounded
                    </Icon>
                  </Tooltip>
                </Box>
                <Box ml={1}>
                  <Tooltip
                    placement='top'
                    title={I18n.t(
                      'event.event_details.event_cancel_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{ root: classes.actionIcon }}
                      onClick={() => {
                        onEventCancel('1', true, 'the Product');
                      }}
                    >
                      cancel
                    </Icon>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('event.event_details.event_field_name_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>Event 1</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('event.event_details.event_field_vendor_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Chip
                    variant='outlined'
                    avatar={
                      <Avatar
                        alt='vendor'
                        src={`https://ui-avatars.com/api/?name=Super+Admin&background=random`}
                      ></Avatar>
                    }
                    label={`Vendor 1`}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t('event.event_details.event_field_timing_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>
                    31-12-2021, 7:00 PM - 9:00 PM
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display='flex' alignItems='center' mb={2.5}>
                <Box mr={1}>
                  <Typography variant='body1'>
                    {I18n.t(
                      'event.event_details.event_field_contact_details_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Box>
                    <Typography variant='body2'>Mark Andri, </Typography>
                  </Box>
                  <Box ml={1}>
                    <Typography variant='body2'>+19845364756</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2.5}>
                <Box>
                  <Typography variant='body1'>
                    {I18n.t('event.event_details.event_field_address_label')}:
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant='body2'>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Itaque, minus. Aliquam quasi accusamus quisquam eveniet
                    fugiat odio fugit sapiente enim.
                  </Typography>
                </Box>
                <Box borderBottom='1px solid #334e68' mt={2.5}></Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2.5}>
                <Box>
                  <Typography variant='body1'>
                    {I18n.t(
                      'event.event_details.event_field_about_event_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box>
                  <div dangerouslySetInnerHTML={{ __html: htmlResAbout }} />
                </Box>
                <Box borderBottom='1px solid #334e68' mt={2.5}></Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2.5}>
                <Box>
                  <Typography variant='body1'>
                    {I18n.t('event.event_details.event_field_terms_label')}:
                  </Typography>
                </Box>
                <Box>
                  <div dangerouslySetInnerHTML={{ __html: htmlResTerms }} />
                </Box>
                <Box borderBottom='1px solid #334e68' mt={2.5}></Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2.5}>
                <Typography variant='body1'>
                  {I18n.t('event.event_details.event_field_media_label')}:
                </Typography>
              </Box>

              {mediaUrls && mediaUrls.length > 0 && (
                <CustomFilePreviewSA files={mediaUrls} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <MessageModal ref={modalRef} />
    </>
  );
};

export default EventDetails;
