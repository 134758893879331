import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  ListItem,
  Autocomplete,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  RadioGroup,
  Radio,
  Icon,
} from '@mui/material';
import { connect } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Constants, {
  ONLY_BLANK_SPACES,
  ONLY_DIGITS,
} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { I18n } from 'react-redux-i18n';
import {
  getSignedUrlRequest,
  uploadToS3Request,
} from '../../ProductApiActions';
import { FileUpload } from '../../../../utils/components';
import { ReactComponent as File } from '../../../../assets/File.svg';
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
import { categoryOptions, vendorOptions } from '../../data';
import { useStyles } from './styles';

/**
 * function to render product form
 * @param {*} submitFormData: function to handle form submit
 * @param {*} isUpdate: boolean for is update
 * @param {*} formData: form data
 * @param {*} onCancelAddProduct: function to handle cancel add product
 * @param {*} onCancelUpdateProduct: function to handle cancel update product
 * @param {*} getSignedUrl: function to get signed url
 * @param {*} uploadToS3: function to upload to signed url
 * @returns
 */
const ProductForm = ({
  submitFormData,
  isUpdate,
  formData,
  onCancelAddProduct,
  onCancelUpdateProduct,
  getSignedUrl,
  uploadToS3,
}) => {
  const classes = useStyles();
  //eslint-disable-next-line
  const [formSubmit, setFormSubmit] = useState(false);
  const [product, setProduct] = useState({
    name: '',
    cost: '',
    category: null,
    vendor: null,
    description: '',
    sizes: {
      S: false,
      M: false,
      L: false,
      XL: false,
      XXL: false,
      'Free Size': false,
    },
    isAvailable: {
      S: '',
      M: '',
      L: '',
      XL: '',
      XXL: '',
      'Free Size': '',
    },
  });
  const [files, setFiles] = useState([]);
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);

  console.log(product);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );
    ValidatorForm.addValidationRule(ONLY_DIGITS, UtilHelper.validateOnlyNumber);

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
      ValidatorForm.removeValidationRule(ONLY_DIGITS);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isUpdate && formData) {
      setProduct(formData);
    }
  }, [isUpdate, formData]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, product);
    data[event.target.name] = event.target.value;
    setProduct(data);
  };

  /**
   * function to handle autocomplete input change
   * @param {*} elementName
   * @param {*} selectedvalue
   */
  const handleChangeAutocomplete = (elementName, selectedvalue) => {
    const data = Object.assign({}, product);
    data[elementName] = selectedvalue;
    setProduct(data);
  };

  /**
   * function to handle file change
   * @param {*} selectedFiles: selected files
   * @param {*} updateError: update error
   */
  const onChangeFile = (selectedFiles, updateError) => {
    if (selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();

      const data = {
        fileName: fileName,
        fileType: '.' + fileExtension,
      };

      console.log(data);

      setFiles(selectedFiles);
    }
  };

  /**
   * function to handle remove file
   * @param {*} index: index
   */
  const onRemoveFile = (index) => {
    let fileList = Object.assign([], files);
    fileList.splice(index, 1);
    setFiles(fileList);
  };

  /**
   * function to handle checkbox input change
   * @param {*} keyName: key name
   * @param {*} event: event
   */
  const handleCheckboxInputChange = (keyName, event) => {
    const data = Object.assign({}, product);
    data[keyName][event.target.name] = event.target.checked;
    setProduct(data);
  };

  /**
   * function to handle radio input change
   * @param {*} keyName: key name
   * @param {*} name: name
   * @param {*} event: event
   */
  const handleRadioInputChange = (keyName, name, event) => {
    const data = Object.assign({}, product);
    data[keyName][name] = event.target.value;
    setProduct(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = () => {
    // setFormSubmit(true);
    // if (files.length > 0) {
    //   let formData = Object.assign({}, product);
    //   formData.mediaUrls = [];
    //   files.forEach((file, index) => {
    //     const fileName = file.name;
    //     const fileExtension = fileName.split('.').pop();
    //     const data = {
    //       fileName: fileName,
    //       fileType: '.' + fileExtension,
    //     };
    //     getSignedUrl(data).then((res) => {
    //       formData.mediaUrls.push({
    //         path: res.path,
    //         fileName: res.fileName,
    //         fileType: res.fileType,
    //       });
    //       uploadToS3(res.urls, file).then((response) => {
    //         if (files.length === index + 1) {
    //           submitFormData(UtilHelper.trimObject(formData));
    //         }
    //       });
    //     });
    //   });
    // } else {
    //   const data = Object.assign({}, product);
    //   submitFormData(UtilHelper.trimObject(data));
    // }

    const data = Object.assign({}, product);
    const trimmedData = UtilHelper.trimObject(data);
    console.log(trimmedData);
  };

  /**
   * function to handle show file preview
   * @param {*} file: file object
   */
  const onShowFilePreview = (file) => {
    setSelectedFile(file);
    setOpenFileViewer(true);
  };

  /**
   * function to hide file preview
   */
  const onHideFilePreview = () => {
    setSelectedFile(null);
    setOpenFileViewer(false);
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} noValidate onError={() => {}}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <TextValidator
            variant='standard'
            fullWidth
            required
            size='small'
            label={I18n.t('product.add_product.form_field_name_label')}
            onChange={handleChangeInput}
            helperText={`${product.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50}`}
            name='name'
            value={product.name}
            validators={[
              'required',
              ONLY_BLANK_SPACES,
              'maxStringLength:' +
                Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50,
            ]}
            errorMessages={[
              I18n.t('error_messages.field_required'),
              I18n.t('error_messages.blank_spaces_not_allowed'),
              I18n.t('error_messages.maximum_50_allowed_characters_for_text'),
            ]}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <TextValidator
            variant='standard'
            fullWidth
            required
            size='small'
            label={I18n.t('product.add_product.form_field_cost_label')}
            onChange={handleChangeInput}
            helperText={`${product.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_18}`}
            name='cost'
            value={product.cost}
            validators={[
              'required',
              ONLY_BLANK_SPACES,
              'maxStringLength:' +
                Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_18,
              'matchRegexp:^[0-9]+$',
              'minNumber:1',
            ]}
            errorMessages={[
              I18n.t('error_messages.field_required'),
              I18n.t('error_messages.blank_spaces_not_allowed'),
              I18n.t('error_messages.maximum_18_allowed_characters_for_text'),
              I18n.t('error_messages.only_digit_allow'),
              I18n.t('error_messages.min_number_allow'),
            ]}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Autocomplete
            size='small'
            name='category'
            required
            getOptionLabel={(option) => option.name}
            options={categoryOptions}
            onChange={(e, value) => handleChangeAutocomplete('category', value)}
            value={product.category}
            renderInput={(params) => {
              return (
                <TextValidator
                  variant='standard'
                  name='category'
                  {...params}
                  fullWidth
                  required
                  label={I18n.t(
                    'product.add_product.form_field_category_label'
                  )}
                  value={product.category}
                  validators={['required']}
                  errorMessages={[I18n.t('error_messages.field_required')]}
                />
              );
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Autocomplete
            size='small'
            name='vendor'
            getOptionLabel={(option) => option.name}
            options={vendorOptions}
            onChange={(e, value) => handleChangeAutocomplete('vendor', value)}
            value={product.vendor}
            renderInput={(params) => {
              return (
                <TextValidator
                  variant='standard'
                  required
                  name='vendor'
                  {...params}
                  fullWidth
                  label={I18n.t('product.add_product.form_field_vendor_label')}
                  value={product.vendor}
                  validators={['required']}
                  errorMessages={[I18n.t('error_messages.field_required')]}
                />
              );
            }}
          />
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{ marginTop: '0.5rem' }}
        >
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography classes={{ root: classes.formLabelRoot }}>
                {I18n.t('product.add_product.product_field_sizes_label')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography classes={{ root: classes.formLabelRoot }}>
                {I18n.t('product.add_product.product_field_is_available_label')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                borderBottom='1px solid #334e68 !important'
                mt={1.2}
                mb={1}
                width='76%'
              ></Box>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormControl component='fieldset' variant='standard'>
                <FormGroup>
                  {Object.entries(product.sizes).map(([key, value], index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color='secondary'
                          name={key}
                          checked={value}
                          onChange={(event) =>
                            handleCheckboxInputChange('sizes', event)
                          }
                        />
                      }
                      label={key}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormControl component='fieldset'>
                {Object.entries(product.isAvailable).map(
                  ([key, value], index) => (
                    <RadioGroup
                      key={index}
                      row
                      value={value}
                      onChange={(event) =>
                        handleRadioInputChange('isAvailable', key, event)
                      }
                    >
                      <FormControlLabel
                        value='yes'
                        control={<Radio color='secondary' />}
                        label={I18n.t(
                          'product.add_product.product_field_is_available_yes_label'
                        )}
                      />
                      <FormControlLabel
                        value='no'
                        control={<Radio color='secondary' />}
                        label={I18n.t(
                          'product.add_product.product_field_is_available_no_label'
                        )}
                      />
                    </RadioGroup>
                  )
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextValidator
            variant='standard'
            fullWidth
            size='small'
            multiline
            rows={3}
            maxRows={5}
            label={I18n.t('product.add_product.form_field_description_label')}
            onChange={handleChangeInput}
            helperText={`${product.description.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_500}`}
            name='description'
            value={product.description}
            validators={[
              ONLY_BLANK_SPACES,
              'maxStringLength:' +
                Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_500,
            ]}
            errorMessages={[
              I18n.t('error_messages.blank_spaces_not_allowed'),
              I18n.t('error_messages.maximum_500_allowed_characters_for_text'),
            ]}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FileUpload
            files={files}
            acceptedFiles={[process.env.REACT_APP_PRODUCTS_FILES_SUPPORTED]}
            filesLimit={process.env.REACT_APP_PRODUCT_UPLOAD_FILE_LIMIT_5}
            showPreviewsInDropzone={true}
            fieldLabel={I18n.t('product.add_product.form_field_media_label')}
            dropzoneLabel={I18n.t(
              'product.add_product.form_field_dropzone_label'
            )}
            updateFileList={(selectedFiles, updateError) =>
              onChangeFile(selectedFiles, updateError)
            }
            removeFileFromSelection={(index) => onRemoveFile(index)}
          />

          {product.uploadedFiles && product.uploadedFiles.length > 0 ? (
            <>
              <Typography variant='h6' className='dropzone-label margin-top-10'>
                {I18n.t('product.add_product.form_field_uploaded_media_label')}
              </Typography>
              {product.uploadedFiles.map((file, index) => (
                <Grid container spacing={3} key={index}>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <div className='selected-file-container'>
                      <ListItem className={'file-list-item'}>
                        <File className={'selected-file'} />
                        <Typography variant='h6' className='selected-file-name'>
                          {file.fileName}
                        </Typography>
                        {/* <RemoveIcon className="remove-file-icon" color="primary" onClick={() => removeFileFromSelection(index)}/> */}
                      </ListItem>
                    </div>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Button
                      size='large'
                      variant='outlined'
                      color='primary'
                      fullWidth
                      onClick={() => onShowFilePreview(file)}
                    >
                      {I18n.t(
                        'product.product_details.product_field_media_preview_button_label'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            type='submit'
            color='secondary'
            variant='contained'
            className='invite-add-btn text-transform-capitalize'
          >
            {I18n.t('global.button_labels.save')}
          </Button>
          {onCancelAddProduct || onCancelUpdateProduct ? (
            <Button
              color='inherit'
              variant='text'
              className='cancel-btn text-transform-capitalize'
              onClick={
                onCancelAddProduct ? onCancelAddProduct : onCancelUpdateProduct
              }
            >
              {I18n.t('global.button_labels.cancel')}
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={openFileViewer && selectedFile}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent className='preview-dialog-form-content'>
          <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant='h3'
                className='product-category-dialog-title'
              >
                {I18n.t('product.product_details.product_field_preview_label')}
                <IconButton
                  aria-label='delete'
                  size='small'
                  className='close-preview-btn text-transform-capitalize'
                  onClick={() => onHideFilePreview()}
                >
                  <Icon>close_rounded</Icon>
                </IconButton>
              </Typography>
              {selectedFile && (
                <FileViewer
                  fileType={selectedFile.type}
                  filePath={selectedFile.url}
                  errorComponent={CustomErrorComponent}
                />
              )}
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </ValidatorForm>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getSignedUrl: async (data) => {
      try {
        return await dispatch(getSignedUrlRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
