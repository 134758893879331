import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    popperContainer: {
      zIndex: '100 !important',
      marginTop: '5px !important',
      boxShadow:
        '0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1) !important',
      width: '500px !important',
      borderRadius: '8px !important',
    },

    paperRoot: {
      padding: '24px',
      // backgroundColor: '#15202a',
      borderRadius: '8px',
      border: 'solid 0.5px #283745',
      marginTop: '0.5rem',
    },

    actionsGrid: {
      marginTop: '10px !important',
      textAlign: 'right !important',
    },

    applyButton: {
      marginLeft: '0.5rem !important',
    },
  };
});
