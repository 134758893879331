import React from 'react';
import { Avatar, Typography, Chip, Icon, Box } from '@mui/material';
import UtilHelper from '../../UtilHelper';
import './AvatarCell.css';

/**
 * function to render avatar cell
 * @param {*} props: props
 * @returns
 */
const AvatarCell = (props) => {
  const avatar = props.heading.split(' ').map((item, index) => {
    if (index < 2) return item.substr(0, 1);
    return '';
  });
  const avatarDetailsClass = props.subHeading
    ? 'avatar-details-top'
    : 'avatar-details-middle';

  return (
    <Box className={'avatar-cell-container'}>
      <Box className={'avatar-container'}>
        <Avatar
          src={props.image ? props.image : ''}
          className={`${props.isDisabled ? 'dangerBackground' : ''}`}
        >
          {avatar.join('')}
        </Avatar>
      </Box>
      <Box
        className={'avatar-container-name avatar-details ' + avatarDetailsClass}
      >
        <Box
          className={`custom-user-display-name-avatar ${
            props.isDisabled ? 'danger' : ''
          }`}
        >
          {props.onClick ? (
            <Typography
              variant='body2'
              onClick={() => props.onClick()}
              color='primary'
              className='App-link'
            >
              {props.heading.length > 20
                ? UtilHelper.ellipsisRender(props.heading, 20)
                : props.heading}
              {props.hasOwnProperty('favourite') ? (
                <Icon
                  className={
                    props.favourite
                      ? 'favourite-color-red'
                      : 'favourite-color-defalt'
                  }
                >
                  star_rounded
                </Icon>
              ) : (
                <></>
              )}
            </Typography>
          ) : (
            <Typography variant='body2'>
              {props.heading.length > 20
                ? UtilHelper.ellipsisRender(props.heading, 20)
                : props.heading}
              {props.hasOwnProperty('favourite') ? (
                <Icon
                  className={
                    props.favourite
                      ? 'favourite-color-red'
                      : 'favourite-color-defalt'
                  }
                >
                  star_rounded
                </Icon>
              ) : (
                <></>
              )}
            </Typography>
          )}
        </Box>
        {props.subHeading && (
          <Box className='custom-user-name-sub-heading'>
            <Typography variant='caption'>{props.subHeading}</Typography>
          </Box>
        )}
        {props.roles && props.roles.length > 0 && (
          <Box>
            {props.roles.map((role, index) => (
              <Chip
                key={index}
                label={role.name}
                size='small'
                variant='outlined'
                className='avatar-role'
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AvatarCell;
