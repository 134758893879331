/**
 * survey list data
 */
export const surveyListData = {
  count: 100,
  rows: [
    {
      id: '1',
      name: 'Survey 1',
      vendor: 'Vendor 1',
      startDate: '2021-10-13T06:07:54.144Z',
      endDate: '2021-10-13T06:07:54.144Z',
      responseCount: '125',
    },
    {
      id: '2',
      name: 'Survey 2',
      vendor: 'Vendor 2',
      startDate: '2021-10-13T06:07:54.144Z',
      endDate: '2021-10-13T06:07:54.144Z',
      responseCount: '1846',
    },
  ],
};

/**
 * category options
 */
export const categoryOptions = [
  {
    id: 1,
    name: 'Games',
  },
  {
    id: 2,
    name: 'Bar Equipments',
  },
  {
    id: 3,
    name: 'T-shirts',
  },
];

/**
 * vendor options
 */
export const vendorOptions = [
  {
    id: 1,
    name: 'Vendor 1',
  },
  {
    id: 2,
    name: 'Vendor 2',
  },
  {
    id: 3,
    name: 'Vendor 3',
  },
];

export const mediaUrlsDemo = [
  {
    id: '1',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=M+I&background=random',
  },
  {
    id: '2',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=T+I&background=random',
  },
  {
    id: '3',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=L+I&background=random',
  },
  {
    id: '4',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=G+I&background=random',
  },
];

export const questionListData = [
  {
    id: '1',
    title: 'How did you hear about our website?',
    type: 'single',
    option1: 'Television',
    option2: 'Newspaper',
    option3: 'Radio',
    option4: 'Internet',
    mediaUrl:
      'https://i.picsum.photos/id/1000/5626/3635.jpg?hmac=qWh065Fr_M8Oa3sNsdDL8ngWXv2Jb-EE49ZIn6c0P-g',
  },
  {
    id: '2',
    title: 'How would you prefer we cantact you?',
    type: 'multi',
    option1: 'Phone Call',
    option2: 'Text',
    option3: 'Email',
    option4: 'In Person',
    mediaUrl: '',
  },
  {
    id: '3',
    title: 'Whom would you like to evaluate?',
    type: 'multi',
    option1: 'Supervisor',
    option2: 'Coworker',
    option3: 'Subordinate',
    option4: '',
    mediaUrl:
      'https://i.picsum.photos/id/1002/4312/2868.jpg?hmac=5LlLE-NY9oMnmIQp7ms6IfdvSUQOzP_O3DPMWmyNxwo',
  },
  {
    id: '4',
    title:
      'How consistently does your supervisor reward employees for good work?',
    type: 'single',
    option1: 'Extremely consistently',
    option2: 'Not so consistantly',
    option3: '',
    option4: '',
    mediaUrl: '',
  },
];
