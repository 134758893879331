import { makeStyles } from '@mui/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => {
  return {
    hide: {
      display: 'none !important',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: '0 !important',
      whiteSpace: 'nowrap !important',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px) !important`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px) !important`,
      },
    },
    toolbar: {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'flex-start !important',
      ...theme.mixins.toolbar,
    },

    item: {
      display: 'flex !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
    button: {
      color: '#fff !important',
      padding: '10px 16px !important',
      justifyContent: 'flex-start !important',
      textTransform: 'none !important',
      letterSpacing: '0 !important',
      width: '100% !important',

      height: '41px !important',
      fontSize: '14px !important',
      fontWeight: '600 !important',
      borderRadius: '0px !important',
    },
    icon: {
      width: '16px !important',
      height: '24 !important',
      display: 'flex !important',
      alignItems: 'center !important',
      marginRight: `${theme.spacing(4)} !important`,
    },
    menuIcon: {
      color: '#fff !important',
    },
    active: {
      color: `${theme.palette.secondary.main} !important`,
      height: '41px !important',
      fontSize: '15px !important',
      fontWeight: 'bold !important',
      borderRight: `3px solid ${theme.palette.secondary.main} !important`,
      backgroundColor: 'rgba(208, 172, 116, 0.1) !important',
      '& $icon': {
        color: `${theme.palette.secondary.main} !important`,
      },
    },
    nested: {
      paddingLeft: `${theme.spacing(4)} !important`,
    },

    avatar: {
      width: `${theme.spacing(4)} !important`,
      height: `${theme.spacing(4)} !important`,
      cursor: 'pointer !important',
      textTransform: 'capitalize !important',
      float: 'left !important',
      backgroundColor: '#fff !important',
      color: '#bdbdbd !important',
    },
    name: {
      marginLeft: `${theme.spacing(1)} !important`,
      marginTop: `${theme.spacing(1.1)} !important`,
      textTransform: 'capitalize !important',
      float: 'left !important',
      height: '19px !important',
      fontSize: '14px !important',
      fontWeight: '600 !important',
      color: '#fff !important',
    },

    //regular css
    drawerFooter: {
      width: '100% !important',
      position: 'absolute !important',
      bottom: '20px !important',
    },

    userProfileContainer: {
      marginTop: '30px !important',
      padding: '10px 12px !important',
    },

    sideDrawerLogoImgDiv: {
      textAlign: 'center',
    },

    sideDrawerLogoImg: {
      width: '100px !important',
      backgroundColor: '#15202a',
      padding: '0px 10px !important',
      borderRadius: '5px',
      verticalAlign: 'middle !important',
    },

    iconSetRightPosition: {
      right: '10px !important',
      position: 'absolute',
      marginTop: '2px',
    },

    subMenuItem: {
      marginLeft: '15px !important',
    },

    faIconsSize: {
      fontSize: '22px !important',
    },
  };
});
