import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    nameText: {
      color: '#fff',
      fontWeight: 'bold',
    },
    commentText: {
      fontSize: '0.9rem !important',
    },

    replyText: {
      fontSize: '0.9rem !important',
    },

    countText: {
      fontSize: '0.88rem',
      marginLeft: '4px',
      marginTop: '0.1px',
    },
  };
});
