const en = {
  /**
   * error messages
   */
  error_messages: {
    field_required: 'This field is required',
    only_required: 'required',
    email_invalid_error: 'Please enter valid email',
    mobile_number_invalid_error: 'Please enter valid mobile number',
    blank_spaces_not_allowed: 'Blank spaces not allowed',
    digit_not_allowed: 'Digit is not allow',
    network_error: 'Something went wrong. Please try again.',
    invalid_credentials: 'Invalid credentials',
    error: 'Error',
    invalid_file_type: 'File type not supported',
    username_password_incorrect: 'User name or password is incorrect',
    registration_date_invalid_error: 'Please select registration date',

    maximum_allowed_characters_for_text_field:
      'Maximum 30 characters are allowed',
    maximum_allowed_characters_for_text_area:
      'Maximum 255 characters are allowed',
    maximum_200_allowed_characters_for_text:
      'Maximum 200 characters are allowed',
    maximum_500_allowed_characters_for_text:
      'Maximum 500 characters are allowed',
    maximum_300_allowed_characters_for_text:
      'Maximum 300 characters are allowed',
    maximum_120_allowed_characters_for_text:
      'Maximum 120 characters are allowed',
    maximum_100_allowed_characters_for_text:
      'Maximum 100 characters are allowed',
    maximum_50_allowed_characters_for_text: 'Maximum 50 characters are allowed',
    maximum_18_allowed_characters_for_text: 'Maximum 18 digits are allowed',

    maximum_allowed_float: 'Maximum allowed float is %{value}',

    only_digit_allow: 'Only digits are allowed',
    min_number_allow: 'Can not be less than or equal to 0',

    password_mismatch: 'Password mismatch',
    valid_password:
      'Password should have minimum 1 uppercase, 1 lowercase, 1 numeric, 1 special characters',
    int_float_only: 'Please enter valid int or float values only',
    invalid_date: 'Invalid date',
    past_date: 'Can not be a past date',
    invalid_time: 'Invalid time',
    past_time: 'Can not be a past time',
  },

  global: {
    app_name: 'SpiritPedia',
    no_activity_detected_title: 'No Activity Detected',
    no_activity_detected_message:
      'System has detected no activity in past 30 minutes. Please login again to continue.',
    session_expired_title: 'Session Expired',
    logout_user_message: 'Are you sure you want to logout?',
    logout_user_title: 'Logout',
    logout_button_label: 'Logout',
    cancel_button_label: 'Cancel',
    ok_button_label: 'Ok',
    back_button_label: 'Previous Step',
    next_button_label: 'Next',
    finish_button_label: 'Finish',
    message_modal_note_field_label: 'Note',

    button_labels: {
      save: 'Save',
      cancel: 'Cancel',
      ok: 'Ok',
      yes: 'Yes',
      no: 'No',
      apply: 'Apply',
      clear: 'Clear',
    },

    message_labels: {
      session_expired: 'Session expired',
      moving_spirit_pedia_forward: 'Moving spirit pedia forward.',
      learn_more: 'LEARN MORE',
      home: 'Home',
      blog: 'Blog',
      terms_of_service: 'Terms of Service',
      privacy_policy: 'Privacy Policy',
      copyright: 'Copyright',
      spirit_pedia_inc: 'Spirit Pedia, Inc',
    },
  },

  side_menu: {
    user_management: 'Users',
    vendor_management: 'Vendors',
    whiskey_tube: 'Whiskey Tube',
    products: 'Products',
    events: 'Events',
    surveys: 'Surveys',
    spirits: 'Spirits',
  },

  sign_in: {
    sign_in_label: 'Login',
    sign_in_form_username_label: 'Email ID',
    sign_in_form_password_label: 'Password',
    sign_in_button_lavel: 'Sign in',
    login_label: 'Login',
    forgot_password_label: 'Forgot password',
  },

  forgot_password: {
    forgot_password_label: 'Forgot password',
    forgot_password_form_username_label: 'Enter email ID',
    request_temporary_password: 'Continue',
    go_to_sign_in: 'Back to login',
    or_label: 'Or',
    reset_password_link_sent:
      'A password reset link has been sent to %{email}.',
    redirection_to_login_page:
      '%{message}. You will be redirected to the login page. Please use the one time password send to your email address to login into the application',
  },

  reset_password: {
    new_password_label: 'Enter password',
    re_enter_password_label: 'Enter password again',
    reset_password_button: 'Change password',
    reset_password_label: 'Reset password',
    go_to_sign_in: 'go to sign in!',
    or_label: 'Or',
    redirection_to_login_page:
      ' You will be redirected to the login page. Please use the new password to login into the application',
  },

  force_reset_password: {
    new_password_label: 'New Password',
    re_enter_password_label: 'Confirm Password',
    set_password_button: 'Set Password',
  },

  change_password: {
    current_password_form_label: 'Enter Current Password',
    new_password_form_label: 'Enter New Password',
    re_enter_new_password_form_label: 'Re-enter New Password',
    reset_password_button: 'Reset Password',
    cancel_button: 'Cancel',
  },

  user: {
    add_user: {
      form_title_add_user_label: 'Add User',
      form_title_update_user_label: 'Update User',

      form_field_name_label: 'Name',
      form_field_email_label: 'Email',
      form_field_phone_label: 'Phone',
      form_field_vendor_label: 'Vendor',
      form_field_role_label: 'Role',
      form_field_products_label: 'Products',

      form_field_is_industry_reps_label: 'Is Industry Reps?',
      form_add_button_label: 'Add',
      form_update_button_label: 'Update',
      form_cancel_button_label: 'Cancel',

      add_user_success_message: 'User invited successfully',
      update_user_success_message: 'User details updated successfully',
    },

    super_admin_table: {
      add_super_admin_button_label: 'Super Admin',

      form_heading_add_super_admin: 'Add Super Admin',
      form_heading_update_super_admin: 'Update Super Admin',

      form_field_name_label: 'Name',
      form_field_email_label: 'Email',
      form_field_phone_label: 'Phone',

      table_column_name_label: 'Name',
      table_column_email_label: 'Email',
      table_column_phone_label: 'Phone',
      table_column_joined_on_label: 'Joined On',
      table_column_action_label: 'Action',
      table_action_column_edit_tooltip_title: 'Edit',

      table_column_status_label: 'Invitation status',
      table_column_accepted_label: 'Accepted',
      table_column_pending_label: 'Pending',
      table_column_resend_invitation_tooltip_title: 'Resend Invitation',
      table_column_resend_invitation_dialog_title: 'Resend Invitation',

      table_action_column_deactivate_tooltip_title: 'Deactivate',
      table_action_column_active_tooltip_title: 'Activate',

      table_filter_search_by_name_email_phone_label: 'Name, Email and phone',
      filter_active_status_label: 'Active Status',
      filter_joined_date_label: 'Joined Date',

      invite_sent_label: 'Invite Sent',
      invited_on_label: 'Invited on',
    },

    vendor_admin_table: {
      add_vendor_admin_button_label: 'Vendor Admin',

      form_heading_add_vendor_admin: 'Add Vendor Admin',
      form_heading_update_vendor_admin: 'Update Vendor Admin',

      form_field_name_label: 'Name',
      form_field_email_label: 'Email',
      form_field_phone_label: 'Phone',

      table_column_name_label: 'Name',
      table_column_page_name_label: 'Account Name',
      table_column_email_label: 'Email',
      table_column_phone_label: 'Phone',
      table_column_joined_on_label: 'Joined On',
      table_column_action_label: 'Action',
      table_action_column_edit_tooltip_title: 'Edit',

      table_column_status_label: 'Invitation status',
      table_column_accepted_label: 'Accepted',
      table_column_pending_label: 'Pending',
      table_column_resend_invitation_tooltip_title: 'Resend Invitation',
      table_column_resend_invitation_dialog_title: 'Resend Invitation',

      table_action_column_deactivate_tooltip_title: 'Deactivate',
      table_action_column_active_tooltip_title: 'Activate',

      table_action_column_disabled_tooltip_title: 'Disable',
      table_action_column_enabled_tooltip_title: 'Enable',

      table_filter_user_type_label: 'User Type',
      add_user_button_label: 'Add User',
      table_column_number_of_products_label: 'Products',

      table_filter_search_by_name_email_phone_label: 'Name, Email and phone',
      filter_active_status_label: 'Active Status',
      filter_joined_date_label: 'Joined Date',

      invite_sent_label: 'Invite Sent',
      invited_on_label: 'Invited on',
    },

    /**
     * user management - end user module
     */
    end_user_table: {
      table_column_name_label: 'Name',
      table_column_email_label: 'Email',
      table_column_phone_label: 'Phone',
      table_column_posts_count: 'Posts',
      table_column_comments_count: 'Comments',
      table_column_products_purchased_count: 'Products Purchased',
      table_column_events_attended_count: 'Events Attended',
      table_column_survey_undertaken_count: 'Survey Undertaken',
      table_column_parties_planned_count: 'Parties Planned',

      table_column_joined_on_label: 'Joined On',
      table_column_last_active_label: 'Last Active',

      table_column_action_label: 'Action',
      table_action_column_edit_tooltip_title: 'Edit',

      table_column_status_label: 'Invitation status',
      table_column_accepted_label: 'Accepted',
      table_column_pending_label: 'Pending',
      table_column_resend_invitation_tooltip_title: 'Resend Invitation',
      table_column_resend_invitation_dialog_title: 'Resend Invitation',

      table_action_column_deactivate_tooltip_title: 'Deactivate',
      table_action_column_active_tooltip_title: 'Activate',

      table_filter_search_by_name_email_phone_label: 'Name, Email and phone',
      table_filter_active_status_label: 'Active Status',
      table_filter_joined_date_label: 'Joined Date',
      table_filter_last_active_label: 'Last Active',
      table_filter_post_label: 'Post',
      table_filter_comment_label: 'Comment',
      table_filter_product_purchased_label: 'Product Purchased',
      table_filter_event_attended_label: 'Event Attended',
      table_filter_survey_undertaken_label: 'Survey Undertaken',
      table_filter_party_planned_label: 'Party Planned',
    },

    end_user_card: {
      tooltip_activate: 'Activate',
      tooltip_deactivate: 'Deactivate',

      joined_on: 'Joined on',
      last_active_on: 'Last Active on',
    },
  },

  vendor: {
    add_vendor: {
      form_title_add_vendor_label: 'Add Vendor',
      form_title_update_vendor_label: 'Update Vendor',
      form_field_name_label: 'Name',
      form_field_title_label: 'Title',
      form_field_description_label: 'Description',

      form_add_button_label: 'Add',
      form_update_button_label: 'Update',
      form_cancel_button_label: 'Cancel',

      add_vendor_success_message: 'Vendor invited successfully',
      update_vendor_success_message: 'Vendor details updated successfully',
    },
    vendor_table: {
      add_vendor_button_label: 'Vendor',

      table_column_name_label: 'Name',
      table_column_number_of_merchandises: 'Merchandises',
      table_column_number_of_survey: 'Survey',
      table_column_number_of_events: 'Events',
      table_column_number_of_mentions: 'Mentions',
      table_column_activation_status_label: 'Active Status',

      table_column_date_of_page_creation_label: 'Joining Date',
      table_column_number_of_admins_label: 'Admins',
      table_column_number_of_irs_label: 'IRs',
      table_column_publication_status_label: 'Publication Status',
      table_column_published_status_label: 'Published',
      table_column_unpublished_label: 'UnPublished',
      table_column_enabled_status_label: 'Enabled',
      table_column_disabled_label: 'Disabled',
      table_column_number_of_product_label: 'Products',
      table_column_number_of_mentions_label: 'Mentions',
      table_action_column_view_tooltip_title: 'View',
      table_action_column_edit_tooltip_title: 'Edit',

      filter_active_status_label: 'Active Status',
      filter_merchandises_label: 'Merchandise',
      filter_survey_label: 'Survey',
      filter_events_label: 'Event',
      filter_mentions_label: 'Mention',
      table_filter_search_by_name: 'Name',
    },

    vendor_details: {
      tab_name_vendor_users: 'Users',
      tab_name_products: 'Products',
      tab_name_activity: 'Activity',

      vendor_publish_tooltip_title: 'Publish',
      vendor_published_tooltip_title: 'Published',
      vendor_unpublish_tooltip_title: 'UnPublish',

      vendor_enable_tooltip_title: 'Enable',
      vendor_disable_tooltip_title: 'Disable',

      vendor_edit_tooltip_title: 'Edit',
      vendor_back_tooltip_title: 'Back',
      vendor_actions_tooltip_title: 'Actions',

      table_filter_date_label: 'Filter by Date',

      vendor_activate_success_message: 'Vendor activated successfully',
      vendor_deactivate_success_message: 'Vendor deactivated successfully',

      vendor_publish_success_message: 'Vendor published successfully',
      vendor_unpublish_success_message: 'Vendor unpublished successfully',

      tab_products_label: 'Products',
      tab_survey_label: 'Survey',
      tab_events_label: 'Events',
    },
  },

  whiskey_tube: {
    add_video: {
      form_title_add_video_label: 'Add Video',
      form_title_update_video_label: 'Update Video',

      form_field_youtube_video_link_label: 'Youtube Video Link',
      form_field_title_label: 'Title',
      form_field_vendor_label: 'Vendor',
      form_field_Description_label: 'Description',
      form_field_tags_label: 'Tags',
    },

    whiskey_tube_list: {
      add_video_label: 'Video',
      comments_label: 'Comments',

      filter_creation_date_label: 'Creation Date',
      filter_tag_label: 'Tag',
      filter_view_label: 'View',
      filter_comment_label: 'Comment',
      filter_share_label: 'Share',
      filter_like_label: 'Like',
      filter_save_label: 'Save',
    },

    whiskey_tube_card: {
      vendor_label: 'Vendor',
      created_by_label: 'Created by',
      last_updated_by_label: 'Last Updated by',

      show_more_label: 'Show more',
      show_less_label: 'Show less',

      edit_tooltip: 'Edit',
      archive_tooltip: 'Archive',
      unarchive_tooltip: 'Unarchive',
    },
  },

  product: {
    add_product: {
      form_title_add_product_label: 'Add Product',
      form_title_update_product_label: 'Update Product',

      form_field_name_label: 'Name',
      form_field_cost_label: 'Cost',
      form_field_category_label: 'Category',
      form_field_vendor_label: 'Vendor',
      product_field_sizes_label: 'Sizes',
      product_field_is_available_label: 'Is Available',
      product_field_sizes_s_label: 'S',
      product_field_sizes_m_label: 'M',
      product_field_sizes_l_label: 'L',
      product_field_sizes_xl_label: 'XL',
      product_field_sizes_xxl_label: 'XXL',
      product_field_sizes_free_size_label: 'Free Size',
      product_field_is_available_yes_label: 'Yes',
      product_field_is_available_no_label: 'No',
      form_field_description_label: 'Description',
      form_field_media_label: 'Media',
      form_field_dropzone_label:
        'Upload Media (Image, Video, Document allowed)',

      form_field_uploaded_media_label: 'Uploaded Files',
      product_field_media_preview_button_label: 'Preview',
    },

    confirmation_dialog: {
      out_of_stock: 'Mark out of Stock',
      out_of_stock_message:
        'Are you sure you want to mark this product out of stock',
    },

    product_table: {
      add_product_button_label: 'Product',

      table_column_name_label: 'Name',
      table_column_category_label: 'Category',
      table_column_vendor_label: 'Associated Vendor',
      table_column_cost_label: 'Cost',
      table_column_items_sold_label: 'Items Sold',
      table_column_archive_status_label: 'Archive Status',
      table_column_active_status_label: 'Active Status',
      table_column_unarchived_status_label: 'Unarchived',
      table_column_archived_label: 'Archived',
      table_column_enabled_status_label: 'Enabled',
      table_column_disabled_label: 'Disabled',

      table_filter_product_category_label: 'Category',
      table_filter_product_vendor_label: 'Vendor',
      table_filter_product_archive_status_label: 'Archive Status',
      table_filter_product_active_status_label: 'Active Status',
      table_filter_search_by_name: 'Name',

      product_disabled_tooltip_title: 'Disable',
      product_enabled_tooltip_title: 'Enable',
    },

    product_details: {
      page_header_label: 'Product Details',

      product_field_name_label: 'Name',
      product_field_cost_label: 'Cost',
      product_field_category_label: 'Category',
      product_field_vendor_label: 'Vendor',
      product_field_sizes_label: 'Sizes',
      product_field_is_available_label: 'Is Available',
      product_field_sizes_s_label: 'S',
      product_field_sizes_m_label: 'M',
      product_field_sizes_l_label: 'L',
      product_field_sizes_xl_label: 'XL',
      product_field_sizes_xxl_label: 'XXL',
      product_field_sizes_free_size_label: 'Free Size',
      product_field_is_available_yes_label: 'Yes',
      product_field_is_available_no_label: 'No',
      product_field_description_label: 'Description',
      product_field_media_label: 'Media',

      event_edit_tooltip_title: 'Edit',
      event_enabled_tooltip_title: 'Enabled',
      event_disabled_tooltip_title: 'Disabled',
      event_archive_tooltip_title: 'Archive',
      event_unarchive_tooltip_title: 'Unarchive',
      event_out_of_stock_tooltip_title: 'Out of Stock',
    },
  },

  event: {
    event_form: {
      form_title_add_event_label: 'Add Event',
      form_title_update_event_label: 'Update Event',

      form_field_name_label: 'Name',
      form_field_vendor_label: 'Vendor',
      form_field_total_tickets_label: 'Total Tickets',
      form_field_cost_per_ticket_label: 'Cost Per Ticket',
      form_field_date_label: 'Date',
      form_field_start_time_label: 'Start Time',
      form_field_end_time_label: 'End Time',
      form_field_person_name_label: 'Person Name',
      form_field_person_phone_label: 'Person Phone',
      form_field_address_label: 'Address',
      form_field_about_event_label: 'About Event',
      form_field_terms_label: 'Terms & Conditions',
      form_field_upload_label: 'Media',
      form_field_dropzon_label: 'Upload Media (Image, Video, Document allowed)',
    },

    confirmation_dialog: {
      cancel: 'Cancel',
      cancel_message: 'Are you sure you want to cancel this event',
    },

    event_table: {
      add_event_button_label: 'Event',

      table_column_name_label: 'Name',
      table_column_address_label: 'Address',
      table_column_vendor_label: 'Vendor',
      table_column_date_label: 'Date',
      table_column_contact_label: 'Contact',
      table_column_tickets_label: 'Tickets',
      table_column_cost_label: 'Cost',
      table_column_sold_label: 'Sold',
      table_column_archive_status_label: 'Archive Status',
      table_column_active_status_label: 'Active Status',

      table_filter_vendor_label: 'Vendor',
      table_filter_date_label: 'Date',
      table_filter_ticket_label: 'Ticket',
      table_filter_cost_label: 'Cost',
      table_filter_sold_label: 'Sold',
    },

    event_details: {
      page_header_label: 'Event details',

      event_field_name_label: 'Name',
      event_field_vendor_label: 'Vendor',
      event_field_timing_label: 'Timing',
      event_field_contact_details_label: 'Contact Details',
      event_field_address_label: 'Address',
      event_field_about_event_label: 'About Event',
      event_field_terms_label: 'Terms & Conditions',
      event_field_media_label: 'Media',

      event_cancel_tooltip_title: 'Cancel',
      event_edit_tooltip_title: 'Edit',
    },
  },

  survey: {
    survey_form: {
      form_title_add_label: 'Add Survey',
      form_title_update_label: 'Update Survey',

      form_field_name_label: 'Name',
      form_field_vendor_label: 'Vendor',
      form_field_start_date_label: 'Start Date',
      form_field_end_date_label: 'End Date',
    },

    question_form: {
      form_field_title_label: 'Title',
      form_single_select_label: 'Single Select',
      form_multi_select_label: 'Multi Select',
      form_field_option_1_label: 'Option 1',
      form_field_option_2_label: 'Option 2',
      form_field_option_3_label: 'Option 3',
      form_field_option_4_label: 'Option 4',
      form_add_image_label: 'Add Image',
    },

    question_item: {
      edit_tooltip: 'Edit',
      delete_tooltip: 'Delete',
      single_select_label: 'Single Select',
      multi_select_label: 'Multi Select',
    },

    confirmation_dialog: {
      delete_survey: 'Delete Survey',
      delete_survey_message:
        ' If you go ahead with survey deletion, all data related to it will be discarded. Do you still want to continue? ',
      delete_question: 'Delete Question',
      delete_question_message:
        'Are you sure you want to delete the question, %{title}.',
    },

    survey_table: {
      add_survey_button_label: 'Survey',

      table_column_name_label: 'Name',
      table_column_vendor_label: 'Vendor',
      table_column_duration_label: 'Duration',
      table_column_responses_label: 'Responses',
      table_column_action_label: 'Actions',

      table_filter_search_by_name: 'Name',
      table_filter_vendor_label: 'Vendor',
      table_filter_response_label: 'Response',
      table_filter_duration_label: 'Duration',

      table_delete_tooltip: 'Delete',
    },

    survey_details: {
      page_header_label: 'Survey Details',

      field_name_label: 'Name',
      field_vendor_label: 'Vendor',
      field_duration_label: 'Duration',

      survey_edit_tooltip_title: 'Edit',
    },
  },

  spirit: {
    spirit_form: {
      form_title_add_label: 'Add Spirit',
      form_title_update_label: 'Update Spirit',

      form_field_name_label: 'Name',
      form_field_cost_label: 'Cost',
      form_field_recommended_weather_label:
        'Recommended weather in which you should have this',
      form_field_recommended_timing_label:
        'Recommended Timing of the day to have this drink',
      form_field_recommended_cuisines_label:
        'Recommended cuisines that go well with this drink',
      form_field_recommended_mood_label: 'Recommended for the mood',
      form_add_image_label: 'Add Image',
    },

    spirit_list: {
      add_spirit_label: 'Spirit',

      filter_name_label: 'Name',
      filter_cost_label: 'Cost',
    },

    spirit_card: {
      cost_label: 'Cost',

      show_more_label: 'Show more',
      show_less_label: 'Show less',

      edit_tooltip: 'Edit',
      disable_tooltip: 'Disable',
      enable_tooltip: 'Enable',

      recommended_weather_label:
        'Recommended weather in which you should have this',
      recommended_timing_label:
        'Recommended Timing of the day to have this drink',
      recommended_cuisines_label:
        'Recommended cuisines that go well with this drink',
      recommended_mood_label: 'Recommended for the mood',
    },
  },
};

export default en;
