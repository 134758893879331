import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  TableContainer,
  Box,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from '@mui/material';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import Constants, { USER_ROLES } from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { vendorAdminData } from '../../data';
import {
  CustomTable,
  HtmlTooltip,
  AvatarCell,
  MessageModal,
} from '../../../../utils/components';
import {
  InputFilter,
  RadioButtonFilter,
  DateRangeFilter,
} from '../../../../utils/components/Filter';
import { VendorAdminForm } from '../index';
import {
  getUsersRequest,
  activateDeactivateUserRequest,
  resendInvitationRequest,
} from '../../UsersApiActions';

/**
 * function to render vendor admin list
 * @param {*} getVendorAdminUsers: function to get vendor admin users
 * @param {*} activateDeactivateUser: function to activate deactivate user
 * @param {*} resendInvitation: function to resend invitation
 * @returns
 */
const VendorAdminList = ({
  getVendorAdminUsers,
  activateDeactivateUser,
  resendInvitation,
}) => {
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      roleName: USER_ROLES.VENDOR,
      searchFilter: '',
      enabled: '',
      joinedDate: null,
    },
  });
  const [vendorAdminList, setVendorAdminList] = useState(null);
  const [showVendorAdminForm, setShowVendorAdminForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const modalRef = useRef();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * function to get vendor admin list
   * @param {*} requestData: request data
   */
  const getVendorAdminList = async (requestData) => {
    /**
     * demo data for UI
     */
    setVendorAdminList(vendorAdminData);

    try {
      const data = getVendorAdminUsers(requestData);
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    getVendorAdminList(paginationParams);
    //eslint-disable-next-line
  }, [paginationParams]);

  /**
   * function to handle activate / deactivate a user
   * @param {*} userId: user id
   * @param {*} enabled: active status boolean
   * @param {*} name: user name
   */
  const onActivateDeactivate = (userId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} User`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        const data = {
          // note: note,
          enabled: !enabled,
        };
        activateDeactivateUser(userId, data);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to resend invitaion to a user
   * @param {*} userId: user id
   * @param {*} name: user name
   */
  const onResendInvitation = (userId, name) => {
    const title = I18n.t(
      'user.vendor_admin_table.table_column_resend_invitation_dialog_title'
    );
    const message = `Are you sure you want to resend invitation to ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        resendInvitation(userId);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * handle edit menu item click
   * @param {*} selectedUserData: selected user data
   */
  const handleEditMenuItemClick = (selectedUserData) => {
    setSelectedUser(selectedUserData);
    setShowVendorAdminForm(true);
  };

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to handle menu close
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  const columns = [
    {
      title: I18n.t('user.vendor_admin_table.table_column_name_label'),
      field: 'name',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ disabled, name, imageURL }) => {
        return (
          <AvatarCell isDisabled={disabled} heading={name} image={imageURL} />
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_page_name_label'),
      field: 'pageName',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ disabled, pageName, pageLogo }) => {
        return (
          <AvatarCell
            isDisabled={disabled}
            heading={pageName}
            image={pageLogo}
          />
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_email_label'),
      field: 'username',
      sorting: false,
      cellStyle: {
        width: '25%',
      },
      headerStyle: {
        width: '25%',
      },
      render: ({ email }) => {
        return (
          <Typography variant='body2'>
            {UtilHelper.ellipsisRender(email, 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_phone_label'),
      field: 'phone',
      sorting: false,
      cellStyle: {
        width: '17%',
      },
      headerStyle: {
        width: '17%',
      },
      render: ({ phone }) => {
        return <Typography variant='body2'>{phone}</Typography>;
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_status_label'),
      field: 'status',
      sorting: false,
      cellStyle: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
      render: ({ id, registrationComplete, name, enabled, createdAt }) => {
        if (registrationComplete) {
          return (
            <Typography variant='body2' className='status-success'>
              {I18n.t('user.vendor_admin_table.table_column_accepted_label')}
            </Typography>
          );
        } else {
          if (enabled) {
            return (
              <>
                <span className='status-danger'>
                  {I18n.t('user.vendor_admin_table.table_column_pending_label')}
                </span>
                <HtmlTooltip
                  arrow
                  placement='top'
                  interactive
                  title={
                    <Box>
                      <Typography variant='body1'>
                        {I18n.t('user.vendor_admin_table.invite_sent_label')}
                      </Typography>
                      <Typography variant='caption'>
                        <b>
                          {I18n.t('user.vendor_admin_table.invited_on_label')}{' '}
                        </b>
                        {moment(createdAt).format('dddd, MMM Do, YYYY')}
                      </Typography>
                    </Box>
                  }
                >
                  <IconButton
                    size='small'
                    color='inherit'
                    onClick={() => onResendInvitation(id, name)}
                  >
                    <Icon>repeat</Icon>
                  </IconButton>
                </HtmlTooltip>
              </>
            );
          } else {
            return <></>;
          }
        }
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_joined_on_label'),
      field: 'createdAt',
      cellStyle: {
        width: '13%',
      },
      headerStyle: {
        width: '13%',
      },
      render: ({ createdAt }) => {
        return (
          <Typography variant='body2'>
            {moment(createdAt).format('DD MMM YYYY')}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_action_label'),
      field: '-',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '5%',
      filtering: false,
      render: (props) => {
        return (
          <>
            <IconButton
              color='inherit'
              aria-label='option'
              aria-controls={`customized-menu-${props.id}`}
              aria-haspopup='true'
              onClick={(event) => {
                menuRef.current = props.id;
                handleMenuClick(event);
              }}
              className='option-menu'
            >
              <Icon fontSize='small'>more_vert_rounded</Icon>
            </IconButton>
            <Menu
              id={`customized-menu-${props.id}`}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              keepMounted
              open={menuRef.current === props.id}
              onClose={() => {
                handleMenuClose();
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleEditMenuItemClick(props);
                }}
              >
                <ListItemIcon>
                  <Icon fontSize='small'>edit_rounded</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={I18n.t(
                    'user.vendor_admin_table.table_action_column_edit_tooltip_title'
                  )}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onActivateDeactivate(props.id, props.enabled, props.name);
                }}
              >
                <ListItemIcon>
                  {props.enabled ? (
                    <Icon fontSize='small'>lock_rounded</Icon>
                  ) : (
                    <Icon fontSize='small'>lock_open_rounded</Icon>
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    props.enabled
                      ? I18n.t(
                          'user.vendor_admin_table.table_action_column_deactivate_tooltip_title'
                        )
                      : I18n.t(
                          'user.vendor_admin_table.table_action_column_active_tooltip_title'
                        )
                  }
                />
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeFilter = (elementName, selectedvalue) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = selectedvalue;
    setPaginationParams(data);
  };

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box p={2} mt={-1}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-end'
        >
          <Box display='flex' alignItems='flex-end'>
            <Box width='220px' mr={1}>
              <InputFilter
                filterName=''
                placeholder={I18n.t(
                  'user.vendor_admin_table.table_filter_search_by_name_email_phone_label'
                )}
                onChangeFilter={(value) =>
                  handleChangeFilter('searchFilter', value)
                }
              />
            </Box>

            <Box mr={1}>
              <RadioButtonFilter
                arrayList={Constants.ACTIVE_STATUS_OPTIONS_FOR_FILTER}
                filterName={I18n.t(
                  'user.vendor_admin_table.filter_active_status_label'
                )}
                title={I18n.t(
                  'user.vendor_admin_table.filter_active_status_label'
                )}
                value={paginationParams.searchData.enabled}
                onChangeFilter={(value) => handleChangeFilter('enabled', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Box>

            <Box>
              <DateRangeFilter
                filterName={I18n.t(
                  'user.vendor_admin_table.filter_joined_date_label'
                )}
                title={I18n.t(
                  'user.vendor_admin_table.filter_joined_date_label'
                )}
                value={paginationParams.searchData.joinedDate}
                onChangeFilter={(value) =>
                  handleChangeFilter('joinedDate', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Box>
          </Box>

          <Box>
            <Button
              variant='contained'
              startIcon={<Icon>add_circle</Icon>}
              color='secondary'
              onClick={() => setShowVendorAdminForm(true)}
            >
              {I18n.t('user.vendor_admin_table.add_vendor_admin_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableContainer>
      {renderTableTitle()}

      <CustomTable
        columns={columns}
        data={vendorAdminList?.rows}
        hidePaging={false}
        hideBorderShadow={true}
        rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
        rowsPerPage={paginationParams.rowsPerPage}
        count={vendorAdminList?.count}
        page={paginationParams.page}
        onPageChange={(page) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = page;
          setPaginationParams(pagination);
        }}
        onRowsPerPageChange={(rowPerPage) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = 1;
          pagination.rowPerPage = rowPerPage;
          setPaginationParams(pagination);
        }}
        // columns={columns}
        // data={fileList ? fileList.csv_list : []}
        // pages={10}
        // pages={Math.ceil(allUsers.count / paginationParams.rowsPerPage)}
        // pages={fileList ? fileList.max_page_count : 0}
        // pageSize={10}
        // pageSize={paginationParams.rowPerPage}
        // totalCount={fileList ? fileList.max_record_count : 0}
        // page={paginationParams.page}
      />

      {showVendorAdminForm && (
        <VendorAdminForm
          open={showVendorAdminForm}
          setOpen={setShowVendorAdminForm}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}

      <MessageModal ref={modalRef} />
    </TableContainer>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorAdminUsers: async (data) => {
      try {
        return await dispatch(getUsersRequest(data));
      } catch (error) {
        throw error;
      }
    },

    activateDeactivateUser: async (userId, data) => {
      try {
        return await dispatch(activateDeactivateUserRequest(userId, data));
      } catch (error) {
        throw error;
      }
    },

    resendInvitation: async (userId) => {
      try {
        return await dispatch(resendInvitationRequest(userId));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAdminList);
