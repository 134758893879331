import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Grid,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Icon,
  Typography,
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Constants, { ONLY_BLANK_SPACES } from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { I18n } from 'react-redux-i18n';
import ImageUploading from 'react-images-uploading';
import { HtmlTooltip } from '../../../../utils/components';
import { useStyles } from './styles';

/**
 * function to render question form
 * @param {*} handleCloseQuestionForm: function to handle question form close
 * @param {*} selectedQuestion: selected question
 * @returns
 */
const QuestionForm = ({ handleCloseQuestionForm, selectedQuestion }) => {
  const classes = useStyles();
  const [question, setQuestion] = useState({
    title: '',
    type: 'single',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
  });
  const [images, setImages] = useState([]);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (selectedQuestion) {
      setQuestion({
        title: selectedQuestion?.title,
        type: selectedQuestion?.type,
        option1: selectedQuestion?.option1,
        option2: selectedQuestion?.option2,
        option3: selectedQuestion?.option3,
        option4: selectedQuestion?.option4,
      });
    }
  }, [selectedQuestion]);

  /**
   * function to handle input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, question);
    data[event.target.name] = event.target.value;
    setQuestion(data);
  };

  /**
   * function to handle radio input change
   * @param {*} name: name
   * @param {*} event: event
   */
  const handleRadioInput = (name, event) => {
    const data = Object.assign({}, question);
    data[name] = event.target.value;
    setQuestion(data);
  };

  /**
   * function to handle form submit
   */
  const onSubmit = () => {
    const data = {
      ...question,
    };

    const trimmedData = UtilHelper.trimObject(data);
    console.log(trimmedData);

    // submitFormData(UtilHelper.trimObject(data));
    // console.log(data);
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {};

  return (
    <Paper component={Box} p={3}>
      <Box>
        <ValidatorForm onSubmit={onSubmit} noValidate onError={onFormError}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t('survey.question_form.form_field_title_label')}
                onChange={handleChangeInput}
                helperText={`${question.title.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300}`}
                name='title'
                value={question.title}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_300_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl>
                <Box display='flex' alignItems='center'>
                  <Box>
                    <FormLabel classes={{ root: classes.formLabelRoot }}>
                      Type of Question
                    </FormLabel>
                  </Box>
                  <Box ml={0.5}>
                    <HtmlTooltip
                      arrow
                      placement='right'
                      interactive
                      title={
                        <Box m={1} width='100%'>
                          <Box mb={1}>
                            <Typography variant='caption'>
                              <span className={classes.tooltipMain}>
                                Single Select:
                              </span>{' '}
                              User will be able to choose only one option while
                              answering the question.
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant='caption'>
                              <span className={classes.tooltipMain}>
                                Multi Select:
                              </span>{' '}
                              User will be able to choose multiple options while
                              answering the question.
                            </Typography>
                          </Box>
                        </Box>
                      }
                    >
                      <Icon fontSize='small'>info</Icon>
                    </HtmlTooltip>
                  </Box>
                </Box>
                <RadioGroup
                  row
                  value={question.type}
                  onChange={(event) => handleRadioInput('type', event)}
                >
                  <FormControlLabel
                    value='single'
                    control={<Radio color='secondary' />}
                    label={I18n.t(
                      'survey.question_form.form_single_select_label'
                    )}
                  />
                  <FormControlLabel
                    value='multi'
                    control={<Radio color='secondary' />}
                    label={I18n.t(
                      'survey.question_form.form_multi_select_label'
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t('survey.question_form.form_field_option_1_label')}
                onChange={handleChangeInput}
                name='option1'
                value={question.option1}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant='standard'
                fullWidth
                required
                size='small'
                label={I18n.t('survey.question_form.form_field_option_2_label')}
                onChange={handleChangeInput}
                name='option2'
                value={question.option2}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant='standard'
                fullWidth
                size='small'
                label={I18n.t('survey.question_form.form_field_option_3_label')}
                onChange={handleChangeInput}
                name='option3'
                value={question.option3}
                validators={[
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant='standard'
                fullWidth
                size='small'
                label={I18n.t('survey.question_form.form_field_option_4_label')}
                onChange={handleChangeInput}
                name='option4'
                value={question.option4}
                validators={[
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                    Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                {images.length === 0 && (
                  <Box>
                    <ImageUploading
                      value={images}
                      onChange={(imageFiles) => setImages(imageFiles)}
                      maxNumber={1}
                      dataURLKey='data_url'
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <>
                          <Button
                            color='secondary'
                            variant='outlined'
                            onClick={onImageUpload}
                          >
                            {I18n.t(
                              'survey.question_form.form_add_image_label'
                            )}
                          </Button>
                        </>
                      )}
                    </ImageUploading>
                  </Box>
                )}

                {images.length > 0 && (
                  <Box>
                    <Chip
                      color='secondary'
                      variant='outlined'
                      label={images[0].file.name}
                      onDelete={() => setImages([])}
                    />
                  </Box>
                )}

                <Box display='flex' alignItems='center'>
                  <Box>
                    <Button
                      color='inherit'
                      variant='text'
                      onClick={handleCloseQuestionForm}
                    >
                      {I18n.t('global.button_labels.cancel')}
                    </Button>
                  </Box>
                  <Box ml={1}>
                    <Button type='submit' color='secondary' variant='contained'>
                      {I18n.t('global.button_labels.save')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>
    </Paper>
  );
};

export default QuestionForm;
