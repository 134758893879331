import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  Button,
  InputAdornment,
  Typography,
  Grid,
  Icon,
  Box,
} from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { logo } from '../../../../assets';
import { MessageModal, AppLoader } from '../../../../utils/components';
import { I18n } from 'react-redux-i18n';
import {
  ONLY_BLANK_SPACES,
  VALIDATE_PASSWORD,
  IS_PASSWORD_MATCH,
  ROUTE_ACTIONS,
} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import { resetPasswordRequest } from '../../AuthenticationApiActions';
import './ResetPassword.css';

/**
 * function to render reset password
 * @param {*} history: history object
 * @param {*} resetPassword: function to handle reset password
 * @returns
 */
const ResetPassword = ({ history, resetPassword }) => {
  const match = useRouteMatch();
  const [user, setUser] = useState({ password: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    document.title = I18n.t('global.app_name');
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );
    ValidatorForm.addValidationRule(
      VALIDATE_PASSWORD,
      UtilHelper.validatePassword
    );
    ValidatorForm.addValidationRule(IS_PASSWORD_MATCH, (value) => {
      return UtilHelper.isPasswordMatch(user.password, user.confirmPassword);
    });

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
      ValidatorForm.removeValidationRule(VALIDATE_PASSWORD);
      ValidatorForm.removeValidationRule(IS_PASSWORD_MATCH);
    };
  }, [user]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   */
  const handleChange = (event) => {
    const data = Object.assign({}, user);
    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    const { userId, token } = match.params;

    try {
      const res = await resetPassword(user.password, userId, token);

      //DONE: Can we use variable props of I18n to merge two strings
      modalRef.current.setState({
        showModal: true,
        title: I18n.t('reset_password.reset_password_label'),
        message: I18n.t('reset_password.redirection_to_login_page', {
          message: res.message,
        }),
        showOkButton: true,
        handleOk: navigateToHome,
        okText: I18n.t('global.button_labels.ok'),
      });
    } catch (error) {}
  };

  /**
   * function to handle navigate to home
   */
  const navigateToHome = () => {
    window.location = ROUTE_ACTIONS.HOME;
  };

  return (
    <Box>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        className='reset-password-form-outer-div'
      >
        <Grid item className='reset-password-form-inner-div'>
          <ValidatorForm noValidate onSubmit={handleSubmit}>
            <Box className='application-logo-img-div'>
              <img className='application-logo-img' src={logo} alt='logo' />
            </Box>
            <Typography
              variant='h5'
              className='margin-top-48 reset-password-title'
            >
              {I18n.t('reset_password.reset_password_label')}
            </Typography>
            <TextValidator
              variant='standard'
              margin='dense'
              required
              autoFocus
              name='password'
              className='margin-top-32 reset-passwords-field'
              label={I18n.t('reset_password.new_password_label')}
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={user.password}
              fullWidth
              validators={['required', VALIDATE_PASSWORD]}
              errorMessages={[
                I18n.t('error_messages.field_required'),
                I18n.t('error_messages.valid_password'),
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    className='input-adornment'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <Icon>visibility</Icon>
                    ) : (
                      <Icon>visibility_off</Icon>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextValidator
              variant='standard'
              margin='dense'
              required
              name='confirmPassword'
              className='margin-top-32 reset-passwords-field'
              label={I18n.t('reset_password.re_enter_password_label')}
              type={'password'}
              onChange={handleChange}
              value={user.confirmPassword}
              fullWidth
              validators={[IS_PASSWORD_MATCH, 'required']}
              errorMessages={[
                I18n.t('error_messages.password_mismatch'),
                I18n.t('error_messages.field_required'),
              ]}
            />

            <Button
              fullWidth
              variant='contained'
              type='submit'
              color='secondary'
              className='reset-password-form-button'
            >
              {I18n.t('reset_password.reset_password_button')}
            </Button>

            <Typography
              variant='button'
              component='div'
              className='text-align-center sign-in-text'
              onClick={() => history.push('/signin')}
            >
              {I18n.t('reset_password.go_to_sign_in')}
            </Typography>
          </ValidatorForm>
        </Grid>
      </Grid>
      <MessageModal ref={modalRef} />
      <AppLoader />
    </Box>
  );
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: async (password, userId, token) => {
      try {
        return await dispatch(resetPasswordRequest(password, userId, token));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
