import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from './utils/components';
import AppRoutes from './routes';
import './App.css';

/**
 * function to render as root component of the application
 * @returns
 */
const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
