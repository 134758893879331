import React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './MobileNumber.css';

/**
 * function to render mobile input
 * @param {*} phone: phone value
 * @param {*} updateMobileNumber: function to update mobile number
 * @param {*} customLabel: custom label
 * @returns
 */
const MobileNumber = React.memo(
  ({ phone, updateMobileNumber, customLabel }) => {
    return (
      <>
        <ReactPhoneInput
          defaultCountry='us'
          country='us'
          varient=''
          // onlyCountries={['in']}
          enableSearchField={true}
          disableAreaCodes={true}
          countryCodeEditable={false}
          value={phone}
          specialLabel={customLabel ? customLabel : 'Phone *'}
          onChange={(phone, data) => {
            if (phone) {
              let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
              updateMobileNumber(phoneNumber, isValidPhoneNumber(phoneNumber));
            }
          }}
        />
      </>
    );
  }
);

export default MobileNumber;
