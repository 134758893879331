/**
 * product list data
 */
export const productListData = {
  count: 100,
  rows: [
    {
      id: '1',
      profileImgUrl:
        'https://ui-avatars.com/api/?name=Vendor+1&background=random',

      name: 'Event 1',
      address: 'add 1',
      vendor: 'Vendor 1',
      cost: '25',
      itemsSold: '20',
      archiveStatus: true,
      enabled: true,
      merchandisesCount: 20,
      surveyCount: 30,
      eventsCount: 40,
      mentionsCount: 38,
      startTime: '2021-10-13T06:07:54.144Z',
      endTime: '2021-10-13T06:07:54.144Z',
      contactName: 'Mark Andri',
      contactPhone: '+19845374654',
      tickets: '985',
      sold: '21',

      overview: 'overview',
    },
    {
      id: '2',
      profileImgUrl:
        'https://ui-avatars.com/api/?name=Vendor+1&background=random',

      name: 'Event 2',
      address: 'add 2',
      vendor: 'Vendor 2',
      cost: '13',
      itemsSold: '102',
      archiveStatus: false,
      enabled: false,
      merchandisesCount: 20,
      surveyCount: 30,
      eventsCount: 40,
      mentionsCount: 38,
      startTime: '2021-10-13T06:07:54.144Z',
      endTime: '2021-10-13T06:07:54.144Z',
      contactName: 'Jhon Doe',
      contactPhone: '+19845374345',
      tickets: '1445',
      sold: '5',

      overview: 'overview',
    },
  ],
};

/**
 * category options
 */
export const categoryOptions = [
  {
    id: 1,
    name: 'Games',
  },
  {
    id: 2,
    name: 'Bar Equipments',
  },
  {
    id: 3,
    name: 'T-shirts',
  },
];

/**
 * vendor options
 */
export const vendorOptions = [
  {
    id: 1,
    name: 'Vendor 1',
  },
  {
    id: 2,
    name: 'Vendor 2',
  },
  {
    id: 3,
    name: 'Vendor 3',
  },
];

/**
 * media urls data
 */
export const mediaUrlsDemo = [
  {
    id: '1',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=M+I&background=random',
  },
  {
    id: '2',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=T+I&background=random',
  },
  {
    id: '3',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=L+I&background=random',
  },
  {
    id: '4',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=G+I&background=random',
  },
];

/**
 * html data
 */
export const htmlResAbout = `<p><span>My Video</span></p><p></p><p><span><em>Check out the video</em></span></p><p><strong>😀 Very important one</strong></p><p><span>Thank you!</span></p>`;
export const htmlResTerms = `<p><span>Check it out</span></p><p><span><em>latest one</em></span></p>`;

/**
 * media urls
 */
export const mediaUrls = [
  {
    id: '1',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=M+I&background=random',
  },
  {
    id: '2',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=T+I&background=random',
  },
  {
    id: '3',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=L+I&background=random',
  },
  {
    id: '4',
    type: 'png',
    url: 'https://ui-avatars.com/api/?name=G+I&background=random',
  },
];
